import { render, staticRenderFns } from "./VideoHelp.vue?vue&type=template&id=2bd8c533&scoped=true&"
import script from "./VideoHelp.vue?vue&type=script&lang=js&"
export * from "./VideoHelp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd8c533",
  null
  
)

export default component.exports