<template>
  <div>
    <form
      v-if="preloader"
      @submit.stop.prevent
      @submit="send"
    >
      <b-row class="justify-content-between">
        <b-col md="4">
          <div class="default_box default_form">
            <div class="group">
              <input
                v-model="form.name"
                type="text"
                required
              >
              <span class="bar" />
              <label>Название города</label>
              <small>Например Комсомольск-на-Амуре</small>
            </div>
            <div class="group">
              <input
                v-model="form.name_en"
                type="text"
                required
              >
              <span class="bar" />
              <label>Название на английском языке</label>
              <small>Например komsomolskonamur</small>
            </div>
            <div class="group">
              <input
                v-model="form.federal_subject"
                type="text"
                required
              >
              <span class="bar" />
              <label>Субъект</label>
              <small>Например Хабаровский край</small>
            </div>
            <div class="group">
              <input
                v-model="form.country_name"
                type="text"
                required
              >
              <span class="bar" />
              <label>Страна</label>
              <small>Например Россия</small>
            </div>
            <div class="group">
              <input
                v-model="form.time_zone"
                type="text"
                required
              >
              <span class="bar" />
              <label>Временная зона</label>
              <small>Например Asia/Vladivostok</small>
            </div>
            <div class="group">
              <input
                v-model="form.x"
                type="number"
                min="0"
                step="any"
                required
              >
              <span class="bar" />
              <label>Широта</label>
              <small>Например 50.4016</small>
            </div>
            <div class="group">
              <input
                v-model="form.y"
                type="number"
                min="0"
                step="any"
                required
              >
              <span class="bar" />
              <label>Долгота</label>
              <small>Например 36.9518</small>
            </div>
            <div class="group">
              <input
                v-model="form.alpha_2"
                type="text"
                required
              >
              <span class="bar" />
              <label>ALPHA-2 Код</label>
              <small>Например RU</small>
            </div>
            <div class="group">
              <input
                v-model="form.country_code"
                type="number"
                min="0"
              >
              <span class="bar" />
              <label>Код страны</label>
              <small>Например 7</small>
            </div>
          </div>
        </b-col>
        <b-col md="4">
          <div class="default_box default_form">
            <div class="group form_inline">
              <span class="inline_label">Включен</span>
              <input
                v-model="form.active"
                type="checkbox"
                class="switch"
              >
            </div>
            <div class="group form_inline">
              <span class="inline_label">Баннерная реклама</span>
              <input
                v-model="form.celat_active"
                type="checkbox"
                class="switch"
              >
            </div>
            <div class="group form_inline">
              <span class="inline_label">В городе есть партнёр?</span>
              <input
                v-model="form.has_partner"
                type="checkbox"
                class="switch"
              >
            </div>
            <div class="group">
              <input
                v-model="form.date_end"
                type="date"
                min="0"
                value=""
              >
              <span class="bar" />
              <label>Доступ оплачен до:</label>
              <small v-html="`Например ${$moment().add(6, 'month').calendar()}`" />
            </div>
            <button
              class="btn_primary"
              type="submit"
            >
              Сохранить
            </button>
          </div>
        </b-col>
        <b-col md="4">
          <div class="default_box info_form_box">
            <label class="default_label">Полезные ссылки</label>
            <a
              href="https://ru.wikipedia.org/wiki/%D0%A1%D1%83%D0%B1%D1%8A%D0%B5%D0%BA%D1%82%D1%8B_%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B9%D1%81%D0%BA%D0%BE%D0%B9_%D0%A4%D0%B5%D0%B4%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8"
              target="_blank"
            >Субъекты России (Википедия)</a>
            <a
              href="https://ru.wikipedia.org/wiki/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA_%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D0%BE%D0%B2_%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8"
              target="_blank"
            >Список городов России (Википедия)</a>
            <a
              href="https://www.timeserver.ru/"
              target="_blank"
            >Время в городе (timeserver.ru)</a>
            <a
              href="https://www.php.net/manual/ru/timezones.php"
              target="_blank"
            >Временные зоны</a>
            <a
              href="https://www.iban.com/country-codes"
              target="_blank"
            >Список ALPHA-2 кодов</a>
            <a
              href="https://en.wikipedia.org/wiki/List_of_country_calling_codes"
              target="_blank"
            >Список кодов стран (Википедия)</a>
            <a
              href="https://yandex.ru/maps/"
              target="_blank"
            >Яндекс карты</a>
            <a
              href="https://www.google.com/maps"
              target="_blank"
            >Google карты</a>
          </div>
        </b-col>
      </b-row>
    </form>
    <Preloader v-else />
  </div>
</template>
<script>
export default {
    name: "AdminEditCity",
    components: {},
    meta: {
        name: "Ред"
    },
    data() {
        return {
            preloader: false,
            form: {
                name_en: null,
                name: null,
                active: null,
                x: 0,
                y: 0,
                country_name: "Россия",
                alpha_2: "RU",
                country_code: 7,
                federal_subject: null,
                time_zone: null,
                celat_active: true,
                has_partner: true,
                date_end: null
            }
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + "city/" + this.$route.params.city_name;
            this.$http.get(url).then((response) => {
                this.form = {
                    name_en: response.data.name_en,
                    name: response.data.name,
                    active: response.data.active,
                    x: response.data.x,
                    y: response.data.y,
                    country_name: response.data.country_name,
                    alpha_2: response.data.alpha_2,
                    country_code: response.data.country_code,
                    federal_subject: response.data.federal_subject,
                    time_zone: response.data.time_zone,
                    celat_active: response.data.celat_active,
                    has_partner: response.data.has_partner,
                    date_end: response.data.date_end ? this.$moment(response.data.date_end).format("YYYY-MM-DD") : null,
                }
            }).catch(() => {
                this.$toast.error("Такого городе не существует");
                this.$router.push({ name: "AdminCityList" });                
            }).finally(() => {
                this.preloader = true;
            });

        },
        send() {
            this.form.date_end = this.form.date_end && this.form.date_end !== "" ? this.form.date_end : this.$moment().add(1, "year").format("YYYY-MM-DD");
            this.preloader = false;
            let url = this.$config.api_management + "city/" + this.$route.params.city_name;
            this.$http.put(url, this.form).then((response) => {
                this.$toast.default(`Город ${response.data.name} успешно изменён`);
                this.$router.push({ name: "AdminCityList" });
            }).finally(() => {
                sessionStorage.clear();
                this.preloader = true;
            });

        },
    }
}
</script>
<style scoped>
.info_form_box {
    text-align: left;
}

.info_form_box a {
    text-align: left;
    color: #3c81df !important;
    cursor: pointer;
    text-decoration: none;
    transition: .2s;
    display: block;
}

.info_form_box a:not(:last-child) {
    margin-bottom: 10px;
}

.btn_primary {
    width: 100%;
}
</style>