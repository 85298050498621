<template>
    <div v-if="value && data[value]">
        <div class="info_icon" id="link-button" href="#" title="Нажмите для просмотра подсказки"></div>
        <b-popover target="link-button" id="popover_info_container" container="popover_info" triggers="hover focus" placement="left">
            <div id="popover_info">
                <div class="popover_title" v-if="data[value].title">
                    {{ data[value].title }}
                </div>
                <div class="popover_description_box" v-if="data[value].description && data[value].description.length">
                    <p v-for="(item,index) in data[value].description" :key="index" v-html="$htmContext(item)"></p>
                    <img v-if="data[value].image" :src="data[value].image" alt="vk_video" class="img-fluid">
                </div>
            </div>
        </b-popover>
    </div>
</template>
<script>
export default {
    name: "inputInfoPopover",
    props: {
        value: {
            type: String,
        },
        info: {
            type: Object
        }
    },
    data() {
        return {
            data: {
                vk: {
                    title: 'Ссылка на видео ВКонтакте',
                    image: '/image/info/vk_video.jpg',
                    description: [
                        'Чтобы добавить ссылку на видео ВКонтакте выполните следующие действия:',
                        '• откройте видео ВКонтакте',
                        '• нажмите кнопку "Поделиться"',
                        '• откройте вкладку "Экспортировать"',
                        '• выберите максимальный размер видео',
                        '• скопируйте и вставьте в это поле содержимое из поля "Код для вставки"',
                        '\n',
                        'Видео должно иметь публичный доступ'
                    ]
                }
            }
        }
    },
    components: {}
}
</script>
<style scoped>
img {
    margin: 20px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>