<template>
    <div id="app">
        <div v-if="$user" id="wrapper" class="wrapper">
            <div id="main-panel" class="main-panel">
                <TopMenu v-once />
                <LeftMenu v-once />
                <div class="content_box">
                    <router-view />
                </div>
            </div>
        </div>
        <div v-else>
            <router-view />
        </div>
        <button class="scroll_button noselect" @click="$scrollToTop">
            наверх
        </button>
    </div>
</template>
<script>
import TopMenu from "@/components/Menu/TopMenu";
import LeftMenu from "@/components/Menu/LeftMenu";
export default {
    name: "App",
    components: {
        TopMenu,
        LeftMenu
    },
    created() {
        window.addEventListener("scroll", this.onScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.onScroll);

    },
    methods: {
        onScroll() {
            if (window.scrollY > 300) {
                document.querySelector(".scroll_button").style.display = "block";
            } else if (window.scrollY <= 300) {
                document.querySelector(".scroll_button").style.display = "none";
            }
        }
    },
}
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.scroll_button {
    display: none;
    position: fixed;
    color: rgba(var(--main-text-color), 1);
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    padding: 10px 30px;
    background: rgba(var(--main-color), 1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transition: .2s;
    z-index: 1;
}

.scroll_button:hover {
    background: rgba(var(--main-color-transition), 1);
    padding-bottom: 30px;
    transition: .2s;
}

.main-panel {
    position: relative;
    z-index: 1;
}

.main_after:after {
    content: '';
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 1;
}
</style>