<template>
    <nav class="navbar navbar-expand-lg navbar-transparent">
        <div class="container-fluid">
            <div class="navbar-wrapper">
                <div v-if="$route.meta && $route.meta.name" class="navbar-brand" >{{ $route.meta.name }}</div>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" @click="openMenu">
                <span class="navbar-toggler-icon icon-bar" />
            </button>
            <div class="collapse navbar-collapse justify-content-end">
                <ul class="navbar-nav">
                    <li class="nav-item" />
                    <li class="nav-item">
                        <div class="select">
                            <select v-if="$cityList" class="" name="select_list" @change="onChange">
                                <option v-if="!$city" disabled selected value="">
                                    Выберите город
                                </option>
                                <option v-for="(city, index) in $cityList" :id="'o_id_'+index" :key="index" :value="index" :selected="$city && $city.index && $city.index === index">
                                    {{ city.name }}
                                </option>
                            </select>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    name: "TopMenu",
    components: {},
    data() {
        return {}
    },
    mounted() {},
    methods: {
        onChange(event) {
            this.$city = this.$cityList[event.target.value];
        },
        openMenu() {
            document.getElementById("sidebar").style.right = "300px";
            document.documentElement.style.overflowY = "hidden";
        }
    },

}
</script>
<style scoped>
.nav {
    padding: 0 15px;
}

.navbar {
    box-shadow: 0 0 8px rgb(0, 0, 0, .5);
    position: sticky;
    top: 0;
    z-index: 500;
}

.navbar:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    /*filter: opacity(.2);*/
    background: #fff;
    background-image: url('/image/decor/inspiration-geometry.png') !important;
    /*background-position: bottom;*/
    background-repeat: center;
    /*background-size: contain;*/

}

.navbar-brand {
    background: rgba(var(--main-color), 1);
    color: rgba(var(--main-text-color), 1);
    border-radius: 12px;
    padding: 5px 15px;
}

.navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(32, 86, 131, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    outline: none;
}

.navbar .navbar-toggler {
    color: transparent !important;
    border: none !important;
}

.navbar .navbar-toggler:focus {
    outline: none !important;
    border: none !important;
}
</style>