<template>
    <div>
        <form v-if="preloader" @submit.stop.prevent @submit="send">
            <b-row class="justify-content-between">
                <b-col xl="8">
                    <div class="default_box">
                        <div class="default_form mb-3">
                            <div class="group">
                                <input v-model="form.name" type="text" placeholder=" " required>
                                <span class="bar" />
                                <label>Заголовок афиши</label>
                            </div>
                            <div class="group">
                                <textarea v-model="form.description" cols="30" rows="10" placeholder="Описание афиши" required></textarea>
                                <span class="bar" />
                            </div>
                        </div>
                    </div>
                    <div class="default_box">
                        <div class="info_form_box">
                            <label class="default_label">Контактная информация</label>
                            <div class="default_form">
                                <div class="group">
                                    <input v-model="form.address" type="text" placeholder=" ">
                                    <span class="bar" />
                                    <label>Адрес</label>
                                </div>
                                <div class="group">
                                    <input v-model="form.telephone" type="text" placeholder=" ">
                                    <span class="bar" />
                                    <label>Телефон</label>
                                </div>
                                <div class="group">
                                    <input v-model="form.url" type="url" placeholder=" " oninput="setCustomValidity('')" oninvalid="setCustomValidity('Пожалуйста введите корректный URL')">
                                    <span class="bar" />
                                    <label>Ссылка</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col xl="4">
                    <div class="default_box info_form_box ">
                        <label class="default_label">Опции афиши</label>
                        <div class="default_form">
                            <div class="group form_inline">
                                <span class="inline_label">Опубликована</span>
                                <input v-model="form.published" type="checkbox" class="switch">
                            </div>
                            <div class="group form_inline">
                                <span class="inline_label">Закрепить</span>
                                <input v-model="form.pin_poster" type="checkbox" class="switch" :true-value="Date.now()" :false-value="0">
                            </div>
                            <div class="group form_inline">
                                <span class="inline_label">Раздел</span>
                                <select v-if="filters && filters.length" id="" v-model="form.category_id" name="" required>
                                    <option disabled selected :value="null">
                                        Выберите категорию
                                    </option>
                                    <option v-for="cat in filters" :key="cat.id" :value="cat.id" v-html="cat.title" />
                                </select>
                            </div>
                            <div class="group">
                                <input v-model="form.price" type="text" placeholder=" ">
                                <span class="bar" />
                                <label>Цена</label>
                            </div>
                            <div class="group">
                                <input v-model="form.org_id" type="number" placeholder=" ">
                                <span class="bar" />
                                <label>ID организации в справочнике</label>
                            </div>
                            <div class="group">
                                <input v-model="form.start_at" type="datetime-local" placeholder=" ">
                                <span class="bar" />
                                <label>Дата начала</label>
                            </div>
                            <div class="group">
                                <input v-model="form.end_at" type="datetime-local" placeholder=" ">
                                <span class="bar" />
                                <label>Дата окончания</label>
                            </div>
                            <div class="group">
                                <input v-model="form.url_video" type="url" placeholder=" " pattern="https://youtu.be/.*" oninput="setCustomValidity('')" oninvalid="setCustomValidity('Пожалуйста введите URL в формате https://youtu.be/*******')">
                                <span class="bar" />
                                <label>Ссылка на видео Youtube</label>
                            </div>
                            <div class="group">
                                <input v-model="form.vk_video_url" type="text" placeholder=" " data-info>
                                <span class="bar" />
                                <inputInfoPopover value="vk" />
                                <label>Ссылка на видео ВКонтакте</label>
                            </div>
                        </div>
                        <button v-if="$checkUser('poster', 'create')" class="btn_primary" type="submit">
                            Создать афишу
                        </button>
                    </div>
                </b-col>
                <b-col xl="12">
                    <Filemanager ref="Filemanager" :images.sync="images" leng="1" />
                </b-col>
            </b-row>
        </form>
        <Preloader v-else />
    </div>
</template>
<script>
import Filemanager from "@/components/App/Filemanager/Filemanager";
import inputInfoPopover from "@/components/components/default/inputInfoPopover";
export default {
    name: "PosterAdd",
    components: {
        Filemanager,
        inputInfoPopover
    },
    data() {
        return {
            preloader: true,
            filters: null,
            images: [],
            form: {
                name: null,
                description: null,
                address: null,
                image: null,
                category_id: null,
                org_id: null,
                price: null,
                telephone: null,
                published: true,
                url: null,
                url_video: null,
                vk_video_url: null,
                url_descrition: null,
                pin_main: 0,
                pin_poster: 0,
                pin_filter: 0,
                start_at: null,
                end_at: null

            },
        }
    },
    mounted() {
        this.api_filters();
    },
    methods: {
        api_filters() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/filters";
            this.$http.get(url, {
                params: {
                    per_page: 200,
                }
            }).then((response) => {
                this.filters = response.data.data.filter(elem => {
                    if (elem.category_id === 1) {
                        return elem;
                    }
                });
            }).finally(() => {
                this.preloader = true;
            });
        },
        send() {
            if (this.images && this.images.length) {
                this.form.image = this.$config.api_filemanager + this.images[0];
            } else {
                this.$toast.error("Вы не выбрали изображение для афиши");
                return
            }

            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/poster";
            this.$http.post(url, this.form).then((response) => {
                if (response.data && response.data.id) {
                    this.$indexNow(this.$config.site_info.links.poster, response.data.id);
                }
                this.$toast.default("Афиша успешно создана");
                this.$router.push({ name: "PosterList" });
            }).finally(() => {
                this.preloader = true;
            });

        },
    }
}
</script>
<style scoped>
.btn_primary {
    margin-top: 20px;
}
</style>