<template>
    <div>
        <div class="roots_box">
            <div class="roots_container">
                <router-link v-for="(item, index) in links" :key="index" :to="{ name: item.name }" class="roots_link">
                    {{ item.label }}
                </router-link>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: "ModerationRoot",
    components: {},
    data() {
        return {
            preloader: false,
            links: {
                comments: {
                    name: 'ModerationComments',
                    label: 'Комментарии'
                },
                ads: {
                    name: 'ModerationAds',
                    label: 'Объявления'
                },
                ribbon: {
                    name: 'ModerationRibbon',
                    label: 'Городская лента'
                },

            }
        }
    },
    mounted() {

    },
    methods: {}
}
</script>
<style scoped>
.roots_box {
    background: white;
    display: inline-block;
    border-radius: 12px;
    margin-bottom: 20px;
}

.roots_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.roots_link {
    padding: 10px 15px;
    display: inline-block;
    border-radius: 12px;
    transition: .3s;
    color: #2c3e50;
}


.router-link-active {
    background: rgba(var(--main-color), 1.0);
    color: #fff;
    transition: .3s;
}

@media (min-width: 576px) {

    .roots_link:not(:last-child) {
        margin-right: 20px;
    }
}
</style>