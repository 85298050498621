<template>
    <div>
        <div v-if="preloader">
            <div class="header_button_box">
                <div v-if="$checkUser('adsCategory', 'create')" class="header_button_item">
                    <router-link :to="{name: 'AdsCategoryAdd'}">
                        <button class="header_button">
                            <div class="header_icons icon_add" /><span>Создать категорию</span>
                        </button>
                    </router-link>
                </div>
            </div>
            <div v-if="list && list.length">
                <div class="search_box">
                    <input v-model="inputSearch" type="text" placeholder="Поиск" class="default_input_search">
                </div>
                <Item v-for="item in filters" :key="item.id" :item="item" :parent="true" :index="item.id" @api_status="api_status" @del="del" />
            </div>
            <div v-if="inputSearch && inputSearch.length && list && list.length && !filters.length" class="default_box">
                Результатов не найдено
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import Item from './components/item.vue';
export default {
    name: 'AdsCategoryList',
    components: {
        Item
    },
    data() {
        return {
            preloader: false,
            main_list: null,
            list: null,
            inputSearch: null,
            disabled_status: false
        }
    },
    computed: {
        filters() {
            if (this.inputSearch && this.list && this.list.length) {
                return Object.values(this.list).filter(item => {
                    if (item.name.toLowerCase().includes(this.inputSearch.toLowerCase())) {
                        return item;
                    }
                });
            }
            console.log(this.getUnflatten(this.list));
            return this.getUnflatten(this.list)
        },

    },
    mounted() {
        this.api();
    },
    methods: {
        getUnflatten(arr, parentid) {
            let output = []
            for (const obj of arr) {
                obj.parent_id = obj.parent_id == 0 || obj.parent_id == null ? null : obj.parent_id;
                if (obj.parent_id == parentid) {
                    var children = this.getUnflatten(arr, obj.id)

                    if (children.length) {
                        obj.category = children
                    }
                    output.push(obj)
                }
            }
            return output

        },
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + '/ads/category';
            this.$http.get(url).then((response) => {
                this.list = response.data.sort((a, b) => {
                    return a.sort - b.sort;
                });
            }).finally(() => {
                this.preloader = true;
            });
        },
        api_status(array, index) {
            let indexInArray = this.list.findIndex(x => x.id === index);

            array.active = array.active == false ? true : false;
            let data = {
                active: array.active
            }
            this.disabled_status = true;
            let url = this.$config.api_management + this.$city.index + '/ads/category/' + array.id;
            this.$http.put(url, data).then((response) => {
                this.list. [indexInArray].active = response.data.active;
                this.$toast.default('Статус категории успешно изменён');
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        del(id, name) {
            if (confirm('Вы уверены, что хотите удалить эту категорию?\n\n"' + name + '"\n\nЭто действие нельзя отменить!')) {
                let url = this.$config.api_management + this.$city.index + '/ads/category/' + id;
                this.$http.delete(url).then((response) => {
                    if (response && response.status === 204) {
                        this.api();
                        this.$toast.default('Категория "' + name + '", успешно удалена');
                    } else {
                        this.$toast.error('Произошла ошибка при удалении');
                    }
                })
            }
        },
    },
}
</script>
<style scoped>
.search_box {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 12px;
}

.default_input_search {
    width: 100%;
}
</style>