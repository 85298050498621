<template>
    <div>
        <form v-if="preloader" @submit.stop.prevent @submit="send">
            <b-row class="justify-content-between">
                <b-col xl="6">
                    <div class="default_box default_form">
                        <div class="group">
                            <input v-model="form.name" type="text" placeholder=" " required>
                            <span class="bar" />
                            <label>Название категории</label>
                        </div>
                        <div class="form_inline mb-3">
                            <span class="inline_label">Опубликовано</span>
                            <input id="s2" v-model="form.active" type="checkbox" class="switch" :true-value="true" :false-value="false">
                        </div>
                        <button class="btn_primary" type="submit">
                            Создать категорию
                        </button>
                    </div>
                </b-col>
                <b-col xl="6">
                    <div class="default_box">
                        <label class="default_label">Родительская категория</label>
                        <AdsCategory />
                    </div>
                </b-col>
                <b-col xl="12">
                    <Filemanager ref="Filemanager" :images.sync="images" leng="1" />
                </b-col>
            </b-row>
        </form>
        <Preloader v-else />
    </div>
</template>
<script>
import Filemanager from "@/components/App/Filemanager/Filemanager";
import AdsCategory from "./components/AdsCategory";
export default {
    name: "AdsCategoryAdd",
    components: {
        Filemanager,
        AdsCategory
    },
    data() {
        return {
            preloader: true,
            images: [],
            category_list: null,
            form: {
                parent_id: null,
                name: null,
                icon: null,
                sort: 0,
                active: true
            }
        }
    },
    mounted() {},
    methods: {
        async send() {
            this.preloader = false;

            if (this.images && this.images.length) {
                this.form.icon = this.$config.api_filemanager + this.images[0]
            }

            /*if (!this.form.parent_id) {
                this.form.parent_id = 0
            }*/

            let form = this.form;

            let url = this.$config.api_management + this.$city.index + "/ads/category";

            this.$http.post(url, form).then(() => {

                this.$toast.default("Категория успешно создана");
                this.$router.push({ name: "AdsCategoryList" });

            }).finally(() => {
                this.preloader = true;
            });
        },
    }
}
</script>
<style scoped>
</style>