<template>
    <div class="filemanager_images_box">
        <div :style="'background-image: url(' + url + ');'" class="filemanager_image_preview" />
        <div class="filemanager_images_preview">
            <div class="filemanager_images_preview_button" @click="$parent.$data.index = num">
                Предпросмотр
            </div>
        </div>
        <a target="_blank" :href="url" title="Скачать изображение">
            <div class="filemanager_icon_download" />
        </a>
        <input v-if="celat && $parent.$data.orientation_image == 'horizontal'" v-model="$parent.$parent.$data.form.horizontal" type="checkbox" class="files_checkbox" :true-value="$config.api_filemanager + onlyPhat(url)" :disabled="$parent.$parent.$data.form.horizontal && $parent.$parent.$data.form.horizontal !== $config.api_filemanager + onlyPhat(url) || !$checkUser('filemanager', 'delete')">
        <input v-else-if="celat && $parent.$data.orientation_image == 'square'" v-model="$parent.$parent.$data.form.square" type="checkbox" class="files_checkbox" :true-value="$config.api_filemanager + onlyPhat(url)" :disabled="$parent.$parent.$data.form.square && $parent.$parent.$data.form.square !== $config.api_filemanager + onlyPhat(url) || !$checkUser('filemanager', 'delete')">
        <input v-else v-model="$parent.selectImage" type="checkbox" class="files_checkbox" :value="onlyPhat(url)" :disabled="leng && $parent.selectImage && $parent.selectImage.length == leng && !$parent.selectImage.includes(onlyPhat(url)) || !$checkUser('filemanager', 'delete')">
    </div>
</template>
<script>
export default {
    name: "File",
    props: {
        url: String,
        num: Number,
        celat: Boolean,
        leng: {
            type: [Number, String],
            coerce: str => parseInt(str)
        }
    },
    data() {
        return {

        }
    },
    methods: {
        onlyPhat(file) {
            return decodeURI((new URL(file).pathname));
        }
    },
}
</script>
<style scoped>
@media screen and (max-width: 576px) {
    .filemanager_images_box {
        width: calc(100% / 2 - (15px * 1 / 2));
    }
}

@media (min-width: 576px) {
    .filemanager_images_box {
        width: calc(100% / 3 - (15px * 2 / 3));
    }

}

@media (min-width: 768px) {
    .filemanager_images_box {
        width: calc(100% / 4 - (15px * 3 / 4));
    }

}

@media (min-width: 992px) {
    .filemanager_images_box {
        width: calc(100% / 5 - (15px * 4 / 5));
    }

}

@media (min-width: 1200px) {
    .filemanager_images_box {
        width: calc(100% / 5 - (15px * 4 / 5));
    }

}

@media (min-width: 1450px) {
    .filemanager_images_box {
        width: calc(100% / 8 - (15px * 7 / 8));
    }

}

.filemanager_images_box {
    padding: 10px;
    transition: all ease 0.2s;
    position: relative;
}

.filemanager_image_preview {
    position: relative;
    padding-top: 100%;
    background-color: #f5f5f5;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    transition: all ease 0.2s;
    image-rendering: crisp-edges;
}


.filemanager_images_preview {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 0.2s;

}

.filemanager_images_preview:hover .filemanager_images_preview_button {
    opacity: 1;
    cursor: pointer;

}

.filemanager_images_preview_button {
    color: #fff;
    border-radius: 6px;
    border: 1px solid #fff;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: all ease 0.2s;
    user-select: none;
}

.files_checkbox {
    position: absolute;
    top: 20px;
    right: 20px;
}

.filemanager_icon_download {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 25px;
    height: 25px;
    background-color: #fff;
    display: block;
    transition: .2s;
    mask-image: url('/image/icons/filemanager/direct-download.svg');
    z-index: 2;
}

.filemanager_icon_download:hover {
    background-color: rgba(var(--main-color), 1);
    transition: .2s;

}
</style>