<template>
    <div>
        <div v-if="preloader">
            <div class="header_button_box">
                <div class="header_button_item">
                    <router-link :to="{name: 'AdminCreateCity'}">
                        <button v-b-modal.modal class="header_button">
                            <div class="header_icons icon_add" /><span>Создать город</span>
                        </button>
                    </router-link>
                </div>
            </div>
            <div v-if="list && list.length" class="default_box table-responsive">
                <input v-model="inputSearch" type="text" placeholder="Поиск" class="default_input_search">
                <table v-if="filters && filters.length" class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="text-start ">
                                <span class="table_header_link">
                                    <div>#</div>
                                </span>
                            </th>
                            <th scope="col" class="text-start " @click="sort('name')">
                                <span class="table_header_link" title="Сортировать по городу">
                                    <div>Город</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-start" @click="sort('country_name')">
                                <span class="table_header_link" title="Сортировать по стране">
                                    <div>Страна</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-start" @click="sort('federal_subject')">
                                <span class="table_header_link" title="Сортировать по Федеральному субъекты">
                                    <div>Субъект</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-start" @click="sort('active')">
                                <span class="table_header_link" title="Сортировать по активным городам">
                                    <div>Статус</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-start" @click="sort('date_end')">
                                <span class="table_header_link" title="Сортировать по оплаченному периоду">
                                    <div>Оплачен до:</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-end">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in filters" :id="'item_' + index" :key="item.index">
                            <td scope="row" class="text-start" v-html="index + 1" />
                            <td scope="row" class="text-start">
                                <a v-if="item.active" :href="$config.site_info.url + item.index" target="_blank" class="link" :title="`Открыть сайт Окей город в городе - ${item.name}`" v-html="item.name" />
                                <span v-else v-html="item.name" />
                            </td>
                            <td scope="row" class="text-start" v-html="item.country_name" />
                            <td scope="row" class="text-start" v-html="item.federal_subject" />
                            <td scope="row" class="text-start">
                                <span v-if="item.active" class="alert_success">Активный</span>
                                <span v-else class="alert_danger">Выключен</span>
                            </td>
                            <td scope="row" class="text-start" v-html="item.date_end ? $moment(item.date_end).format('DD.MM.YYYY') : ''" />
                            <td scope="col" class="text-end">
                                    <div class="table_button_box" :title="`Редактировать город ${item.name}`">
                                        <div class="table_button_item">
                                            <router-link :to="{name: 'AdminEditCity', params: {city_name: item.index}}">
                                            <button class="table_button table_icon_edit">
                                                <div class="table_icons icon_pen" />
                                            </button>
                                            </router-link>
                                        </div>
                                        <div class="table_button_item" :title="`Удалить город ${item.name}`">
                                            <button type="button" class="table_button table_icon_delete" @click="deleteCity(item.name_en, item.name, index)">
                                                <div class="table_icons icon_trash" />
                                            </button>
                                        </div>
                                    </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
export default {
    name: "AdminCityList",
    components: {},
    data() {
        return {
            inputSearch: null,
            preloader: false,
            list: null,
            sortArray: true,
            only_active: false
        }
    },
    computed: {
        filters() {
            if (this.inputSearch && this.inputSearch.length >= 3) {
                return Object.values(this.list).filter(item => {
                    if (item.name.toLowerCase().includes(this.inputSearch.toLowerCase())) {
                        return item;
                    }
                });
            } else if (this.only_active) {
                return Object.values(this.list).filter(item => {
                    if (item.active) {
                        return item;
                    }
                });
            }
            return this.list
        },

    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + "city";
            this.$http.get(url).then((response) => {
                this.list = response.data;
                this.sort();
            }).finally(() => {
                this.preloader = true;
            });
        },
        sort(data = "name") {
            this.list = Object.values(this.list).sort((a, b) => {
                if (this.sortArray) {
                    if (this.$assignValue(a[data]) < this.$assignValue(b[data])) {
                        return -1;
                    }
                    if (this.$assignValue(a[data]) > this.$assignValue(b[data])) {
                        return 1;
                    }
                } else {
                    if (this.$assignValue(b[data]) < this.$assignValue(a[data])) {
                        return -1;
                    }
                    if (this.$assignValue(b[data]) > this.$assignValue(a[data])) {
                        return 1;
                    }
                }
                return 0;
            });

            this.sortArray = !this.sortArray;
        },
        async deleteCity(id, name, index) {
            if (confirm("Вы уверены, что хотите удалить этот город?\n\n\"" + name + "\"\n\nЭто действие нельзя отменить!")) {
                let url = this.$config.api_management + "city/" + id;

                try {
                    await this.$http.delete(url);
                    this.list.splice(index, 1);
                    this.$toast.default("Город \"" + name + "\", успешно удалён");
                } catch (error) {
                    this.$toast.error("Произошла ошибка при удалении");
                } finally {
                    sessionStorage.clear();
                }
            }
        }
    },
}
</script>
<style scoped>
</style>