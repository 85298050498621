<template>
  <div>
    <div v-if="preloader">
      <form
        @submit.stop.prevent
        @submit="send"
      >
        <b-row class="justify-content-between">
          <b-col xl="6">
            <div class="default_box default_form">
              <div class="group">
                <input
                  v-model="form.title"
                  type="text"
                  placeholder=" "
                  required
                >
                <span class="bar" />
                <label>Название</label>
              </div>
              <div class="group form_inline">
                <span class="inline_label">Опубликовано</span>
                <input
                  id="s2"
                  v-model="form.published"
                  type="checkbox"
                  class="switch"
                >
              </div>
              <button
                class="btn_primary"
                type="submit"
              >
                Сохранить раздел
              </button>
            </div>
          </b-col>
        </b-row>
      </form>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
export default {
    name: "NewsCategoryAdd",
    components: {},
    data() {
        return {
            preloader: false,
            form: {
                title: null,
                published: true,
                image: null,
            }
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/news/category/" + this.$route.params.id;
            this.$http.get(url).then((response) => {
                    if (!response.data) {
                        this.$router.push({ name: "NewsCategoryList" });
                        this.$toast.error("Такого раздела не существует");
                    }

                    this.form = {
                        title: response.data.title,
                        published: response.data.published,
                        image: response.data.image,
                    }
                })
                .catch(() => {
                    this.$router.push({ name: "NewsCategoryList" });
                })
                .finally(() => {
                    this.preloader = true;
                });

        },
        send() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/news/category/" + this.$route.params.id;
            this.$http.put(url, this.form).then(() => {
                this.$toast.default("Раздел успешно изменён");
                this.$router.push({ name: "NewsCategoryList" });
            }).finally(() => {
                this.preloader = true;
            });

        },
    }
}
</script>
<style scoped>
</style>