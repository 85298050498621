<template>
  <div>
    <div class="default_box info_form_box">
      <label class="default_label">Важная информация</label>
      <button
        v-b-toggle.collapse-help
        class="userful_link_item"
        type="button"
      >
        <img
          src="/image/icons/usefulLinks/question.svg"
          alt=""
          class="userful_link_img img-fluid"
        >
        <span>Что-то не работает в новой панели администратора</span>
      </button>
      <b-collapse
        id="collapse-help"
        class="mt-2"
      >
        <p>
          <b>Без паники!</b><br><br>
          Давайте попробуем вместе решить Вашу проблему.<br>
          В данный момент Мы проделываем огромную работу по разработке мобильных приложений, сайта, API и панели администратора.<br>
          Большинство проблем связаны из за ежедневных обновлений сайта. Вот небольшая инструкция с которой следует начать:<br><br>
          <b>• Попробуйте обновить страницу (нажмите кнопку F5)</b><br><small>Да, да. Мы не шутим. Это простое действие может Вам помочь, из за особенностей языка программирования на котором написан сайт Вам может показаться, что он работает даже при отсутствии интернета на Вашем компьютере.</small><br><br>
          <b>• Удалите историю браузера за последний час, а так же очистите КЭШ сайта (нажмите сочетание клавиш Ctrl + F5)</b><br><small><b>Кэш</b> или <b>кеш</b> - промежуточный буфер с быстрым доступом к нему, содержащий информацию, которая может быть запрошена с наибольшей вероятностью. Доступ к данным в кэше осуществляется быстрее, чем выборка исходных данных из более медленной памяти или удалённого источника, однако её объём существенно ограничен по сравнению с хранилищем исходных данных.</small><br><br>
          <b>• Откройте сайт в режиме инкогнито</b><br>
          - нажмите на эту ссылку <span
            class="info_link"
            @click="$copyText(get_link())"
            v-html="get_link()"
          />, чтобы скопировать в буфер обмена;<br>
          - нажмите сочетание клавиш "Ctrl" + "N";<br>
          - В открывшемся окне вставьте ссылку в адресную строку сочетанием клавиш "Ctrl" + "V" и нажмите "Enter"<br><small>В режиме инкогнито не сохраняются пароли, cookies и другие данные, что позволит запустить "Чистый сайт", как будто Вы его ни разу не посещали.</small><br><br>
          <b>• Если ничего не помогает, попробуйте очистить локальное хранилище в ручную</b><br>
          <button
            class="btn_primary"
            @click="clearStorage"
          >
            Очистить хранилище
          </button><br><br><br>
          <b>• Если Ваша проблема не решилась перечисленными Выше способами</b><br><br>
          Напишите в личные сообщения нашей группы ВКонтакте <a
            href="https://vk.com/goroda.mobi"
            target="_blank"
          >https://vk.com/goroda.mobi</a>
        </p>
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
    name: "HelpInfo",
    components: {},
    data() {
        return {

        }
    },
    mounted() {},
    methods: {
        get_link() {
            let url = new URL(window.location.href);
            return decodeURI(url.protocol + "//" + url.hostname);
        },
        clearStorage() {
            sessionStorage.clear();
            localStorage.clear();
            this.$user = null;
            window.location.reload();
        }
    }
}
</script>
<style scoped>
.userful_link_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
    border-radius: 12px;
    transition: .2s;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
}

.userful_link_item:hover {
    background: rgba(var(--main-color), .1);
    transition: .2s;
}

.userful_link_img {
    max-height: 25px;
    margin-right: 10px;
}

#collapse-1 {
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 10px;
}

.info_link {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    background: rgba(var(--main-color), 1);
    color: rgba(var(--main-text-color), 1);
}

.btn_primary {
    margin: 20px auto 0 0 !important;

}
</style>