<template>
  <div>
    <div
      v-if="$parent.$data.form.sites"
      class="default_form"
    >
      <div
        v-for="(item,index) in $parent.$data.form.sites"
        :key="index"
        class="form_inline"
      >
        <div class="group">
          <input
            v-model="item.url"
            type="url"
            placeholder=" "
          >
          <span class="bar" />
          <label>Ссылка</label>
        </div>
        <button
          v-if="index === 0"
          type="button"
          class="table_button table_icon_edit"
          :disabled="$parent.$data.form.sites.length >= 7"
          @click="addLink"
        >
          <div class="table_icons icon_add" />
        </button>
        <button
          v-else
          type="button"
          class="table_button table_icon_delete"
          @click="$parent.$data.form.sites.splice(index,1)"
        >
          <div class="table_icons icon_trash" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: "Sites",
    components: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        addLink() {
            this.$parent.$data.form.sites.push({
                tag: null,
                url: null
            })
        },
    }
}
</script>
<style scoped>
.table_button {
    border-radius: 80px;
}
.form_inline {
    align-items: center;
    padding: 0;
}

.group {
    flex: 1;
}

.group:not(:last-child) {
    margin-right: 20px;
}
</style>