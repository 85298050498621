import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue'
import axios from 'axios';
import config from './config';
import config_dev from "./config_dev";

Vue.prototype.$http = axios;

if (process.env.VUE_APP_ENV === 'development') {
    Vue.prototype.$config = config_dev;
} else {
    Vue.prototype.$config = config;
}

Vue.config.productionTip = false;

import routes from './routes';
Vue.use(VueRouter);

// vue-the-mask
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

// Vue Notifications
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast, {
    duration: 10000
});

//vue-tinybox
import Tinybox from "vue-tinybox";
Vue.component('Tinybox', Tinybox);
//END vue-tinybox

//timezone  https://momentjs.com/timezone/
import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Moscow');
moment.locale('ru');
Vue.prototype.$moment = moment;
//End timezone

// BootstrapVue
import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)

import "vue-wysiwyg/dist/vueWysiwyg.css";
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
    hideModules: {
        headings: true,
        code: true,
        image: true,
    },
});


import VuePannellum from 'vue-pannellum'

Vue.component('VPannellum', VuePannellum)

// END BootstrapVue

import MediumEditor from 'vuejs-medium-editor';

Vue.component('medium-editor', MediumEditor);

import 'medium-editor/dist/css/medium-editor.css';
import 'vuejs-medium-editor/src/themes/default.css';


import "@/assets/style.css";


import '@/globalFunctions';

import VueRouteMiddleware from 'vue-route-middleware';

const router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    mode: 'history',
    base: __dirname,
    routes
});


router.beforeEach(VueRouteMiddleware());

import routeBefore from "@/route/middleware/auth";

routeBefore.beforeResolve(router)

Vue.component('Preloader', () => import('@/components/components/Preloader'));


new Vue({
    router,
    render: h => h(App),
}).$mount('#app');