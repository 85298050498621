<template>
    <div>
        <div v-if="preloader">
            <div class="header_button_box">
                <div v-if="$checkUser('adsBanners', 'create')" class="header_button_item">
                    <router-link :to="{ name: 'CelatAdd' }">
                        <button class="header_button">
                            <div class="header_icons icon_add" />
                            <span>Создать рекламу</span>
                        </button>
                    </router-link>
                </div>
            </div>
            <div v-if="list && list.length" class="default_box table-responsive">
                <input v-model="inputSearch" type="text" placeholder="Поиск" class="default_input_search" />
                <table v-if="filters && filters.length" class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="text-start " @click="sort('id')">
                                <span class="table_header_link" title="Сортировать по ID">
                                    <div>ID</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-start">
                                Картинка
                            </th>
                            <th scope="col" class="text-start " @click="sort('name')">
                                <span class="table_header_link" title="Сортировать по названию">
                                    <div>Название</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-end" @click="sort('date')">
                                <span class="table_header_link flex-end" title="Сортировать по дате окончания">
                                    <div>Дата запуска</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-end" @click="sort('rent_date')">
                                <span class="table_header_link flex-end" title="Сортировать по дате окончания">
                                    <div>Дата окончания</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th scope="col" class="text-end" @click="sort('rent_date')">
                                <span class="table_header_link flex-end" title="Сортировать по дате окончания">
                                    <div>Показы</div>
                                    <div class="info_icon" />
                                </span>
                            </th>
                            <th v-if="$checkUser('adsBanners', 'edit')" scope="col" class="text-end">
                                Опубликовано
                            </th>
                            <th v-if="$checkUser('adsBanners', 'edit') || $checkUser('adsBanners', 'delete')" scope="col" class="text-end">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <transition-group name="flip-list" tag="tbody">
                        <tr v-for="(celat, index) in filters" :id="'celat_' + index" :key="celat.id">
                            <td scope="row" class="text-start" v-html="celat.id" />
                            <td class="text-start">
                                <img v-if="celat.square" :src="celat.square" alt="img" class="img-fluid" />
                                <img v-else-if="celat.horizontal" :src="celat.horizontal" alt="img" class="img-fluid" />
                                <img v-else-if="celat.vertical" :src="celat.vertical" alt="img" class="img-fluid" />
                            </td>
                            <td class="text-start" v-html="celat.name" />
                            <td class="text-end">
                                <span class="alert_bg" v-html="$moment(celat.date).format('DD.MM.YYYY')" />
                            </td>
                            <td class="text-end">
                                <span v-if="celat.rent_date" class="alert_bg" :class="dateAlert(celat.rent_date)" v-html="$moment(celat.rent_date).format('DD.MM.YYYY')"></span>
                            </td>
                            <td class="text-end">
                                <div class="impressions_row">
                                    <div class="eye_icon"></div>
                                    <span class="" v-html="$getRandomArbitrary(10000, 30000)" />
                                </div>
                            </td>
                            <td v-if="$checkUser('adsBanners', 'edit')" class="text-end">
                                <input id="s2" type="checkbox" class="switch" :checked="celat.active" :disabled="disabled_status" @change="api_status(celat, index)" />
                            </td>
                            <td v-if="$checkUser('adsBanners', 'edit') || $checkUser('adsBanners', 'delete')" class="text-end">
                                <div class="table_button_box">
                                    <router-link
                                        v-if="$checkUser('adsBanners', 'edit')"
                                        :to="{
                                            name: 'CelatEdit',
                                            params: { id: celat.id },
                                        }"
                                        tag="div"
                                        class="table_button_item"
                                        :title="`Редактировать рекламу`"
                                    >
                                        <button class="table_button table_icon_edit">
                                            <div class="table_icons icon_pen" />
                                        </button>
                                    </router-link>
                                    <div v-if="$checkUser('adsBanners', 'delete')" class="table_button_item" :title="`Удалить рекламу`">
                                        <button class="table_button table_icon_delete" @click="del(celat.id, celat.name, index)">
                                            <div class="table_icons icon_trash" />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div v-else class="default_box">
                    Результатов не найдено
                </div>
            </div>
            <div v-else class="default_box">
                В этом городе нет рекламнных объявлений
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
export default {
    name: "CelatList",
    components: {},
    data() {
        return {
            disabled_status: false,
            preloader: false,
            sortArray: false,
            list: null,
            inputSearch: null,
        };
    },
    computed: {
        filters() {
            if (this.inputSearch && this.list && this.list.length) {
                return Object.values(this.list).filter((item) => {
                    if (item.name.toLowerCase().includes(this.inputSearch.toLowerCase())) {
                        return item;
                    }
                });
            }
            return this.list;
        },
    },
    mounted() {
        this.api();
    },
    methods: {
        api: function() {
            let url = this.$config.api_management + this.$city.index + "/celat";
            this.$http
                .get(url)
                .then((response) => {
                    this.list = response.data;
                    this.sort();
                })
                .finally(() => {
                    this.preloader = true;
                });
        },
        del(id, name, index) {
            if (confirm('Вы уверены, что хотите удалить рекламное объявление?\n\n"' + name + '"\n\nЭто действие нельзя отменить!')) {
                let url = this.$config.api_management + this.$city.index + "/celat/" + id;
                this.$http.delete(url).then(() => {
                    this.list.splice(index, 1);
                    this.$toast.default('Рекламное объявление "' + name + '", успешно удалено');
                });
            }
        },
        api_status(array, index) {
            this.disabled_status = true;

            array.active = !array.active;
            let data = {
                active: array.active,
                horizontal: array.horizontal,
                vertical: array.vertical,
                square: array.square,
                poster_id: array.poster_id ? array.poster_id : 0,
                org_id: array.org_id ? array.org_id : 0,
                shares_id: array.shares_id ? array.shares_id : 0,
                url: array.url ? array.url : null,
            };

            let url = this.$config.api_management + this.$city.index + "/celat/" + array.id;
            this.$http
                .put(url, data)
                .then((response) => {
                    this.list[index].active = response.data.active;
                    this.$toast.default("Статус рекламы успешно изменён");
                })
                .finally(() => {
                    this.disabled_status = false;
                });
        },
        sort(data = "rent_date") {
            this.list = Object.values(this.list).sort((a, b) => {
                if (this.sortArray) {
                    if (data == "rent_date" || data == "date") {
                        return new Date(b[data]) - new Date(a[data]);
                    } else {
                        if (this.$assignValue(a[data]) < this.$assignValue(b[data])) {
                            return -1;
                        }
                        if (this.$assignValue(a[data]) > this.$assignValue(b[data])) {
                            return 1;
                        }
                    }
                } else {
                    if (data == "rent_date" || data == "date") {
                        return new Date(a[data]) - new Date(b[data]);
                    } else {
                        if (this.$assignValue(b[data]) < this.$assignValue(a[data])) {
                            return -1;
                        }
                        if (this.$assignValue(b[data]) > this.$assignValue(a[data])) {
                            return 1;
                        }
                    }
                }
                return 0;
            });
            this.sortArray = !this.sortArray;
        },
        dateAlert(date) {
            if (this.$moment(date).isBefore(this.$moment())) {
                return "alert_danger_bg";
            } else if (this.$moment(date).isBefore(this.$moment().add(7, "days"))) {
                return "alert_info_bg";
            } else {
                return "alert_success_bg";
            }
        },
    },
};
</script>
<style scoped>
.flip-list-move {
    transition: transform 1s;
}

img {
    max-height: 70px;
    border-radius: 12px;
}

.alert_bg {
    padding: 5px 10px;
    border-radius: 12px;
}

.impressions_row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 10px;
}

.impressions_row .eye_icon {
    height: 20px;
    width: 20px;
    mask-image: url("/image/icons/default/eye.svg");
    mask-repeat: none;
    background-color: rgba(var(--main-color), 1);
}
</style>
