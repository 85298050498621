<template>
    <div>
        <div v-if="preloader">
            <div class="header_button_box">
                <div v-if="$checkUser('buttons', 'create')" class="header_button_item">
                    <router-link :to="{name: 'ButtonsAdd'}">
                        <button class="header_button">
                            <div class="header_icons icon_add" /><span>Создать кнопку</span>
                        </button>
                    </router-link>
                </div>
            </div>
            <div v-if="list && list.length" class="default_box table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="text-start ">
                                #
                            </th>
                            <th scope="col" class="text-start ">ID
                                <!-- <span class="table_header_link" title="Сортировать по ID">
                                    <div>ID</div>
                                    <div class="info_icon" />
                                </span> -->
                            </th>
                            <th scope="col" class="text-start ">Заголовок
                                <!-- <span class="table_header_link" title="Сортировать по названию">
                                    <div>Заголовок</div>
                                    <div class="info_icon" />
                                </span> -->
                            </th>
                            <th v-if="$checkUser('buttons', 'edit')" scope="col" class="text-end ">Опубликовано
                                <!-- <span class="table_header_link flex-end" title="Сортировать по статусу">
                                    <div>Опубликовано</div>
                                    <div class="info_icon" />
                                </span> -->
                            </th>
                            <th v-if="$checkUser('buttons', 'edit')" scope="col" class="text-end ">Сортировка
                                <!-- <span class="table_header_link flex-end" title="Сортировать по порядку">
                                    <div>Сортировка</div>
                                    <div class="info_icon" />
                                </span> -->
                            </th>
                            <th v-if="$checkUser('buttons', 'edit') || $checkUser('buttons', 'delete')" scope="col" class="text-end">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <draggable v-model="list" tag="tbody" handle=".sort_icon" @change="updateListSortOrder">
                        <!-- <transition-group name="flip-list" tag="tbody"> -->
                        <ButtonItem v-for="(item,index) in list" :key="item.id" :item="item" :index="index" />
                        <!-- </transition-group> -->
                    </draggable>
                </table>
                <div v-if="list && last_page && page < last_page">
                    <div v-if="button_preloader" class="news_button_block">
                        <button class="btn_primary" @click="api_next">
                            Показать ещё
                        </button>
                    </div>
                    <Preloader v-else />
                </div>
            </div>
            <div v-else class="default_box">
                В этом городе нет кнопок
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import draggable from 'vuedraggable'

import ButtonItem from "./components/ButtonItem";
export default {
    name: "ButtonsList",
    components: {
        ButtonItem,
        draggable
    },
    data() {
        return {
            disabled_status: false,
            preloader: false,
            button_preloader: true,
            list: null,
            page: 1,
            last_page: null,
            sortArray: true,
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/buttons";
            this.$http.get(url, {
                params: {
                    per_page: 150,
                }
            }).then((response) => {
                this.list = this.makeList(response.data.data);
                this.last_page = response.data.last_page;
            }).finally(() => {
                this.preloader = true;
            });
        },
        api_next() {
            this.button_preloader = false;
            let url = this.$config.api_management + this.$city.index + "/buttons";
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page,
                    per_page: 50,
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.data);
            }).finally(() => {
                this.button_preloader = true;
            })
        },
        api_status(array, index) {
            array.published = !array.published;
            let data = {
                published: array.published
            }
            this.disabled_status = true;
            let url = this.$config.api_management + this.$city.index + "/buttons/" + array.id;
            this.$http.put(url, data).then((response) => {
                this.list[index].published = response.data.published;
                this.$toast.default("Статус кнопки успешно изменён");
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        del(id, name, index) {
            if (confirm("Вы уверены, что хотите удалить эту кнопку?\n\n\"" + name + "\"\n\nЭто действие нельзя отменить!")) {
                let url = this.$config.api_management + this.$city.index + "/buttons/" + id;
                this.$http.delete(url).then((response) => {
                    if (response && response.status === 204) {
                        this.list.splice(index, 1);
                        this.$toast.default("Кнопка \"" + name + "\", успешно удалена");
                    } else {
                        this.$toast.error("Произошла ошибка при удалении");
                    }
                })
            }
        },
        sortCategory(id, value, index) {
            if (!id) { return this.$toast.error("Не выбран ID"); }
            if (!value) { return this.$toast.error("Не указано значение сортировки"); }

            this.disabled_status = true;
            let data = {
                order: value
            }

            let url = this.$config.api_management + this.$city.index + "/buttons/" + id;
            this.$http.put(url, data).then((response) => {
                this.list[index].order = response.data.order;
                this.list = this.list.sort((a, b) => {
                    return a.order - b.order;
                });
                this.$toast.default("Позиция успешно изменена");
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        makeList(array) {
            return array.sort(function(a, b) {
                return a.order - b.order;
            })
        },
        sortList(index, item) {
            // this.list.splice(1, 0, item).splice(index, 1);
            if (item.order - 1 >= this.list.length) {
                var k = item.order - 1 - this.list.length + 1;
                while (k--) {
                    this.list.push(undefined);
                }
            }
            this.list.splice(item.order - 1, 0, this.list.splice(index, 1)[0]);


            let newList = [...this.list].map((item, index) => {
                let newSort = index + 1;

                item.hasChanged = item.order !== newSort;
                if (item.hasChanged) {
                    item.order = newSort;
                }
                return item;
            });
            
            this.sortCat(newList);
            this.list = newList;
        },
        updateListSortOrder({ moved }) {
            let newList = [...this.list].map((item, index) => {
                let newSort = index + 1;

                item.hasChanged = item.order !== newSort;
                if (item.hasChanged) {
                    item.order = newSort;
                }
                return item;
            });

            this.sortCat(newList);
            this.list = newList;
        },
        sortCat(array) {
            this.disabled_status = true;
            let data = [...this.list].map((item) => {
                return {
                    id: item.id,
                    order: item.order
                }
            })
            let url = this.$config.api_management + this.$city.index + "/buttons/sort";
            this.$http.patch(url, data).then((response) => {
                this.$toast.default("Позиция успешно изменена");
            }).finally(() => {
                this.disabled_status = false;
            })
        },
    },
}
</script>
<style scoped>
.flip-list-move {
    transition: transform 1s;
}

.btn_primary {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 15px;
}
</style>