<template>
  <div>
    <form
      v-if="preloader"
      @submit.stop.prevent
      @submit="send"
    >
      <b-row class="justify-content-between">
        <b-col xl="6">
          <div class="default_box default_form">
            <div class="group">
              <input
                v-model="form.name"
                type="text"
                placeholder=" "
                required
              >
              <span class="bar" />
              <label>Название категории</label>
            </div>
            <div class="form_inline mb-3">
              <span class="inline_label">Опубликовано</span>
              <input
                id="s2"
                v-model="form.active"
                type="checkbox"
                class="switch"
                :true-value="true"
                :false-value="false"
              >
            </div>
            <button
              v-if="$checkUser('organisationCategory', 'edit')"
              class="btn_primary"
              type="submit"
            >
              Сохранить категорию
            </button>
          </div>
        </b-col>
        <b-col xl="6">
          <div class="default_box">
            <label class="default_label">Ключевые слова</label>
            <keywords />
          </div>
        </b-col>
        <b-col xl="12">
          <div class="default_box">
            <label class="default_label">Родительская категория</label>
            <CategoryParent />
          </div>
        </b-col>
        <b-col xl="12">
          <Filemanager
            ref="Filemanager"
            :images.sync="images"
            leng="1"
          />
        </b-col>
      </b-row>
    </form>
    <Preloader v-else />
  </div>
</template>
<script>
import Filemanager from "@/components/App/Filemanager/Filemanager";
import keywords from "./components/keywords";
import CategoryParent from "./components/CategoryParent";
export default {
    name: "OrganisationCategoryAdd",
    components: {
        Filemanager,
        keywords,
        CategoryParent
    },
    data() {
        return {
            preloader: false,
            images: [],
            category_list: null,
            form: {
                name: null,
                parent_id: null,
                icon: null,
                sort: 0,
                rek: null,
                rek_type: 0,
                url: null,
                telephone: null,
                organizations_id: true,
                active: true,
                description: null,
                rubricator: null,
                yandex: null,
                keywords: []
            }
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/OrganizationsCategory/" + this.$route.params.id;

            this.$http.get(url).then((response) => {
                this.form = {
                    name: response.data.name,
                    parent_id: response.data.parent_id ? response.data.parent_id : null,
                    icon: response.data.icon,
                    sort: response.data.sort,
                    rek: response.data.rek,
                    rek_type: response.data.rek_type ? response.data.rek_type : 0,
                    url: response.data.url,
                    telephone: response.data.telephone,
                    organizations_id: response.data.organizations_id,
                    active: response.data.active,
                    description: response.data.description,
                    rubricator: response.data.rubricator,
                    yandex: response.data.yandex,
                    keywords: response.data.keywords,                 
                }

                if (response.data.icon) {
                    this.images.push(this.$onlyPhat(response.data.icon));
                }
            }).catch(() => {
                this.$router.push({ name: "OrganisationCategoryList" });
                this.$toast.error("Ошибка");                
            }).finally(() => {
                this.preloader = true;
            });

        },
        async send() {
            this.preloader = false;

            if (this.images && this.images.length) {
                this.form.icon = this.$config.api_filemanager + this.images[0]
            }

            if (!this.form.parent_id) {
                this.form.parent_id = 0
            }

            let form = this.form;

            let url = this.$config.api_management + this.$city.index + "/OrganizationsCategory/" + this.$route.params.id;


            form.keywords.forEach((item, key) => {
                if (!item.name) {
                    form.keywords.splice(key, 1)
                }
            })

            this.$http.put(url, form).then(() => {

                this.$toast.default("Категория успешно изменена");
                this.api();

            }).finally(() => {
                this.preloader = true;
            });
        },
    }
}
</script>
<style scoped>
</style>