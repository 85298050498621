import { render, staticRenderFns } from "./OrganisationCategoryEdit.vue?vue&type=template&id=ad4ddba2&scoped=true&"
import script from "./OrganisationCategoryEdit.vue?vue&type=script&lang=js&"
export * from "./OrganisationCategoryEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad4ddba2",
  null
  
)

export default component.exports