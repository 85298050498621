<template>
  <div>
    <div v-if="preloader">
      <form
        v-if="list && list.length"
        @submit.stop.prevent
      >
        <b-row class="justify-content-between">
          <b-col xl="6">
            <div class="default_box default_form">
              <div
                v-for="item in list"
                :key="item.id"
                class="group form_inline"
              >
                <div class="input_box">
                  <input
                    v-model="item.value"
                    type="text"
                    placeholder=" "
                  >
                  <span class="bar" />
                  <label v-html="item.desc">Заголовок</label>
                </div>
                <button
                  v-if="$checkUser('settings', 'edit')"
                  class="btn_primary"
                  type="button"
                  @click="send(item)"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </b-col>
          <b-col xl="4" />
        </b-row>
      </form>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
export default {
    name: "SettingsIndex",
    components: {},
    data() {
        return {
            preloader: false,
            list: null,
            form: {
                id: 1,
                name: null,
                desc: null,
                link: null,
            },
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/settings";
            this.$http.get(url).then((response) => {
                this.list = response.data.data;
            }).finally(() => {
                this.preloader = true;
            });
        },
        send(item) {
            let data = {
                value: item.value
            }
            if(!data.value) {
                this.$toast.error(`В "${item.desc}" не указано значение`);
                return
            }
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/settings/" + item.id;
            this.$http.put(url, data).then(() => {
                this.api();
                this.$toast.default("Настройки успешно изменены");
            }).finally(() => {
                this.preloader = true;
            });
        },
    }
}
</script>
<style scoped>
.btn_primary {
    display: inline-block;
    margin: 0;
}

.input_box {
    flex: 1;
    padding-right: 10px;
}
input {
    width: 100%;
}
</style>