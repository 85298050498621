import { render, staticRenderFns } from "./mainLinks.vue?vue&type=template&id=2645374f&scoped=true&"
import script from "./mainLinks.vue?vue&type=script&lang=js&"
export * from "./mainLinks.vue?vue&type=script&lang=js&"
import style0 from "./mainLinks.vue?vue&type=style&index=0&id=2645374f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2645374f",
  null
  
)

export default component.exports