<template>
  <div>
    <b-row class="justify-content-between">
      <b-col md="4">
        <div class="default_box default_form">
          <form
            v-if="preloader"
            @submit.stop.prevent
            @submit="send"
          >
            <div class="group">
              <input
                v-model="form.name"
                type="text"
                placeholder=" "
                required
              >
              <span class="bar" />
              <label>Название города</label>
              <small>Например Комсомольск-на-Амуре</small>
            </div>
            <div class="group">
              <input
                v-model="form.name_en"
                type="text"
                pattern="^[A-Za-z\s]+$"
                placeholder=" "
                required
              >
              <span class="bar" />
              <label>Название на английском языке</label>
              <small>Например komsomolskonamur</small>
            </div>
            <div class="group">
              <input
                v-model="form.federal_subject"
                type="text"
                placeholder=" "
                required
              >
              <span class="bar" />
              <label>Субъект</label>
              <small>Например Хабаровский край</small>
            </div>
            <div class="group">
              <input
                v-model="form.country_name"
                type="text"
                placeholder=" "
                required
              >
              <span class="bar" />
              <label>Страна</label>
              <small>Например Россия</small>
            </div>
            <div class="group">
              <input
                v-model="form.time_zone"
                type="text"
                placeholder=" "
                required
              >
              <span class="bar" />
              <label>Временная зона</label>
              <small>Например Asia/Vladivostok</small>
            </div>
            <div class="group">
              <input
                v-model="form.x"
                type="number"
                min="0"
                step="any"
                required
              >
              <span class="bar" />
              <label>Широта</label>
              <small>Например 50.4016</small>
            </div>
            <div class="group">
              <input
                v-model="form.y"
                type="number"
                min="0"
                step="any"
                required
              >
              <span class="bar" />
              <label>Долгота</label>
              <small>Например 36.9518</small>
            </div>
            <div class="group">
              <input
                v-model="form.alpha_2"
                type="text"
                pattern="^[A-Za-z\s]+$"
                placeholder=" "
                required
              >
              <span class="bar" />
              <label>ALPHA-2 Код</label>
              <small>Например RU</small>
            </div>
            <div class="group">
              <input
                v-model="form.country_code"
                type="number"
                min="0"
                pattern="[0-9]{,3}"
                required
              >
              <span class="bar" />
              <label>Код страны</label>
              <small>Например 7</small>
            </div>
            <div class="group form_inline">
              <span class="inline_label">Баннерная реклама</span>
              <input
                v-model="form.celat_active"
                type="checkbox"
                class="switch"
              >
            </div>
            <button
              class="btn_primary"
              type="submit"
            >
              Создать город
            </button>
          </form>
          <Preloader v-else />
        </div>
      </b-col>
      <b-col md="4">
        <div class="default_box info_form_box">
          <label class="default_label">Полезные ссылки</label>
          <a
            href="https://ru.wikipedia.org/wiki/%D0%A1%D1%83%D0%B1%D1%8A%D0%B5%D0%BA%D1%82%D1%8B_%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B9%D1%81%D0%BA%D0%BE%D0%B9_%D0%A4%D0%B5%D0%B4%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8"
            target="_blank"
          >Субъекты России (Википедия)</a>
          <a
            href="https://ru.wikipedia.org/wiki/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA_%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D0%BE%D0%B2_%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8"
            target="_blank"
          >Список городов России (Википедия)</a>
          <a
            href="https://www.timeserver.ru/"
            target="_blank"
          >Время в городе (timeserver.ru)</a>
          <a
            href="https://www.php.net/manual/ru/timezones.php"
            target="_blank"
          >Временные зоны</a>
          <a
            href="https://www.iban.com/country-codes"
            target="_blank"
          >Список ALPHA-2 кодов</a>
          <a
            href="https://en.wikipedia.org/wiki/List_of_country_calling_codes"
            target="_blank"
          >Список кодов стран (Википедия)</a>
          <a
            href="https://yandex.ru/maps/"
            target="_blank"
          >Яндекс карты</a>
          <a
            href="https://www.google.com/maps"
            target="_blank"
          >Google карты</a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
    name: "AdminCreateCity",
    components: {},
    data() {
        return {
            preloader: true,
            form: {
                name_en: null,
                name: null,
                x: 0,
                y: 0,
                active: true,                
                country_name: "Россия",
                alpha_2: "RU",
                country_code: 7,
                federal_subject: null,
                time_zone: null,
                celat_active: true,
            }
        }
    },
    methods: {
        send() {
            this.preloader = false;
            let url = this.$config.api_management + "city";
            this.$http.post(url, this.form).then((response) => {
                this.$toast.default(`Город ${response.data.name} успешно создан`);
                this.$router.push({ name: "AdminCityList" });
            }).finally(() => {
                sessionStorage.clear();
                this.preloader = true;
            });

        },
    },
}
</script>
<style scoped>
.btn_primary {
    width: 100%;
}
</style>