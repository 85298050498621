/* eslint-disable no-console */
// ========
import Vue from "vue";

let forGlob = new Vue();

function makeList(city) {
    if (forGlob.$user.roles.city_all || forGlob.$user.roles.super_admin) {
        return city;
    } else if (forGlob.$user.roles.city_list.length > 0) {
        return Object.keys(city)
            .filter(key => forGlob.$user.roles.city_list.includes(key))
            .reduce((obj, key) => {
                obj[key] = city[key];
                return obj;
            }, {});
    }
}

async function getCityList() {
    let city_list, cityListName = "city_list";
    if (sessionStorage.getItem(cityListName)) {
        city_list = JSON.parse(sessionStorage.getItem(cityListName));
    } else {
        let response = await Vue.prototype.$http.get(Vue.prototype.$config.api_url + "City.getList");
        city_list = makeList(response.data.city);

        sessionStorage.setItem(cityListName, JSON.stringify(city_list));
    }

    forGlob.$cityList = city_list;

    return city_list;
}

const beforeResolve = (router) => {
    router.beforeResolve(async (to, from, next) => {
        if (forGlob.$user) {
            const listCity = await getCityList();

            switch (to.name) {
                case null:
                    if (forGlob.$city) {
                        next({ name: "CityHome", params: { city: forGlob.$city.index } });
                    } else {
                        next({ name: "Home" });
                    }
                    break;
                case "CityHome":
                    if (typeof to.params.city === "undefined") {
                        if (forGlob.$city) {
                            next({ name: "CityHome", params: { city: forGlob.$city.index } });
                        } else {
                            next({ name: "Home" });
                        }
                    }
                    break;
                case "Login":
                    next({ name: "Home" });
                    break;

            }
            /* Check: has city in City list */
            if (typeof to.params.city !== "undefined") {
                if (Object.keys(listCity).length > 0 && !listCity[to.params.city]) {
                    next({ name: "Home" });
                } else if (!forGlob.$city || forGlob.$city.index !== to.params.city) {
                    forGlob.$city = listCity[to.params.city];
                }
            }

            next();

        } else if (to.name !== "Login" && to.name !== "ResetPassword") {
            next({ name: "Login" });
        }

        next();
    });
};

export default { beforeResolve };