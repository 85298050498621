<template>
    <div id="sidebar" class="sidebar" data-color="purple" data-background-color="white">
        <div v-if="$config.site_info.logo" class="leftMenu_logo" @click="closeMenu">
            <Logo />
        </div>
        <div class="leftMenu_link_box">
            <div v-if="$city">
                <router-link :to="{name: 'CityHome', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_home" />
                        <span>Главная</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('news', 'view')" :to="{name: 'NewsList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_news" />
                        <span>Новости</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('organisation', 'view')" :to="{name: 'OrganisationList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_org" />
                        <span>Организации</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('poster', 'view')" :to="{name: 'PosterList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_poster" />
                        <span>Афиша</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('shares', 'view')" :to="{name: 'ShareList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_discount" />
                        <span>Акции</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('moderation', 'view')" :to="{name: 'Moderation', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_blacklist" />
                        <span>Модерация</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('adsBanners', 'view')" :to="{name: 'CelatList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_megaphone" />
                        <span>Баннерная реклама</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('buttons', 'view')" :to="{name: 'ButtonsList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_switch" />
                        <span>Кнопки</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('filters', 'view')" :to="{name: 'FiltersList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_filter" />
                        <span>Фильтры</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('adsCategory', 'view')" :to="{name: 'AdsCategoryList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_ads" />
                        <span>Объявления</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('administrators', 'view')" :to="{name: 'AdministratorsList', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_user" />
                        <span>Администраторы</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('advertising', 'view')" :to="{name: 'Advertising', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_logotype" />
                        <span>Реклама</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('help', 'view')" :to="{name: 'Help', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_help" />
                        <span>Помощь</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('filemanager', 'view')" :to="{name: 'Filemanager', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_filemanager" />
                        <span>Файлы</span>
                    </div>
                </router-link>
                <router-link v-if="$checkUser('settings', 'view')" :to="{name: 'Settings', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_settings" />
                        <span>Настройки</span>
                    </div>
                </router-link>
            </div>
            <button type="button" class="leftMenu_link_item ext_btn" @click="logout">
                <div class="icons_menu icon_exit" />
                <span>Выйти</span>
            </button>
            <div v-if="$user && $user.roles && $user.roles.super_admin">
                <div class="admin_box">
                    Администратор
                </div>
                <router-link :to="{name: 'AdminUserList'}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_customer" />
                        <span>Пользователи</span>
                    </div>
                </router-link>
                <router-link :to="{name: 'AdminCityList'}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_city" />
                        <span>Города</span>
                    </div>
                </router-link>
                <router-link :to="{name: 'AdminTools'}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_gear" />
                        <span>Инструменты</span>
                    </div>
                </router-link>
                <router-link v-if="$city" :to="{name: 'ParserIndex', params: { city: $city.index }}">
                    <div class="leftMenu_link_item" @click="closeMenu">
                        <div class="icons_menu icon_gear" />
                        <span>Парсер</span>
                    </div>
                </router-link>
            </div>
            <div class="select_list">
                <select v-if="$cityList" name="select_list" @change="onChange">
                    <option v-if="!$city" disabled selected value="">
                        Выберите город
                    </option>
                    <option v-for="(city, index) in $cityList" :id="'o_id_'+index" :key="index" :value="index" :selected="$city && $city.index && $city.index === index">
                        {{ city.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="leftMenu_footer_developer">
            <span>© Все права защищены / All rights reserved</span>
            <span>Разработано компанией <strong v-html="$config.site_info.name" /></span>
            <span v-if="$moment().format('YYYY') === '2021'" class="leftMenu_footer_date" v-html="$moment().format('YYYY г.')" />
            <span v-else class="leftMenu_footer_date" v-html="'2021 г. - ' + $moment().format('YYYY г.')" />
        </div>
    </div>
</template>
<script>
import Logo from "@/components/components/Logo";
export default {
    name: "TopMenu",
    components: {
        Logo,
    },
    methods: {
        onChange(event) {
            this.$city = this.$cityList[event.target.value];
            this.closeMenu();
        },
        logout() {
            if (confirm("Вы действительно хотите выйти из аккаунта?")) {
                this.$user = null;
                this.$router.push({ name: "Login" });
                sessionStorage.clear();
                localStorage.clear();
                this.$toast.default("До скорых встреч =)");
                this.closeMenu();
            }
        },
        closeMenu() {
            document.getElementById("sidebar").style.right = 0;
            document.documentElement.style.overflowY = "auto";
        }
    },
}
</script>
<style scoped>
.nav {
    padding: 0 15px;
}

.sidebar {
    overflow: hidden;
    padding-bottom: 100px;
    z-index: 501;
}

.leftMenu_link_box {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 93px;
}

.leftMenu_link_box .router-link-exact-active .leftMenu_link_item {
    background: rgba(var(--main-color), 1);
    color: rgba(var(--main-text-color), 1);
    border-radius: 24px;
    transition: .2s;
}

.leftMenu_link_box .router-link-exact-active .leftMenu_link_item .icons_menu {
    background-color: rgba(var(--main-text-color), 1);
    transition: .2s;
}

.leftMenu_link_item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    text-align: left;
    border-radius: 6px;
    transition: .2s;
}

.leftMenu_link_item:hover {
    background: rgba(var(--main-color), 1);
    color: rgba(var(--main-text-color), 1);
    transition: .2s;
    cursor: pointer;
    border-radius: 24px;
}

.leftMenu_link_item:hover .icons_menu {
    background-color: rgba(var(--main-text-color), 1);
}

.leftMenu_link_box a:not(:last-child) .leftMenu_link_item {
    margin-bottom: 10px;
}

.icons_menu {
    width: 20px;
    height: 20px;
    background-color: #2c3e50;
    display: block;
    margin-right: 10px;
}

.icon_home {
    mask-image: url('/image/icons/menu/home.svg');
}

.icon_customer {
    mask-image: url('/image/icons/menu/customer.svg');
}

.icon_dollar {
    mask-image: url('/image/icons/menu/dollar.svg');
}

.icon_favourite {
    mask-image: url('/image/icons/menu/favourite.svg');
}

.icon_settings {
    mask-image: url('/image/icons/menu/settings.svg');
}

.icon_user {
    mask-image: url('/image/icons/menu/user.svg');
}

.icon_wallet {
    mask-image: url('/image/icons/menu/wallet.svg');
}

.icon_exit {
    mask-image: url('/image/icons/menu/exit.svg');
}

.icon_roles {
    mask-image: url('/image/icons/menu/secure.svg');
}

.icon_city {
    mask-image: url('/image/icons/menu/city.svg');
}

.icon_news {
    mask-image: url('/image/icons/menu/news.svg');
}

.icon_filemanager {
    mask-image: url('/image/icons/menu/image.svg');
}

.icon_megaphone {
    mask-image: url('/image/icons/menu/megaphone.svg');
}

.icon_poster {
    mask-image: url('/image/icons/menu/poster.svg');
}

.icon_discount {
    mask-image: url('/image/icons/menu/discount.svg');
}

.icon_logotype {
    mask-image: url('/image/icons/menu/logotype.svg');
}

.icon_help {
    mask-image: url('/image/icons/menu/help-web-button.svg');
}

.icon_switch {
    mask-image: url('/image/icons/menu/switch.svg');
}

.icon_filter {
    mask-image: url('/image/icons/menu/filter.svg');
}

.icon_ads {
    mask-image: url('/image/icons/menu/ad.svg');
}

.icon_org {
    mask-image: url('/image/icons/menu/business.svg');
}

.icon_gear {
    mask-image: url('/image/icons/menu/gear.svg');
}

.icon_blacklist {
    mask-image: url('/image/icons/menu/blacklist.svg');
}

.icon_user {
    mask-image: url('/image/icons/menu/user.svg');
}

.icon_radio {
    mask-image: url('/image/icons/menu/radio.svg');
}

.leftMenu_footer_developer {
    font-size: 12px;
    color: #888;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 10px;
    text-align: right;
    background: #fff;
    z-index: 501;
}

.leftMenu_footer_developer span {
    display: block;
}

.leftMenu_footer_date {}

.admin_box {
    display: block;
    text-align: left;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 20px;
    border-top: 2px solid rgba(0, 0, 0, .1);
}

.ext_btn {
    margin-top: 10px;
}

.select_list {
    margin-top: 10px;
    padding: 15px 0;
    border-top: 2px solid rgba(0, 0, 0, .1);
    padding-bottom: 93px;
}

@media (min-width: 991px) {
    .select_list {
        display: none;
    }
}
</style>