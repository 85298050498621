<template>
  <div
    v-if="user"
    class="city_list"
  >
    <div
      class="user_name"
      v-html="`${user.f_name} ${user.name}`"
    />
    <div class="permission_header">
      <div class="city_item">
        <span v-html="`Супер админ`" />
        <input
          id="s2"
          v-model="form.data.super_admin"
          type="checkbox"
          class="switch"
          :checked="user.roles && user.roles.super_admin ? 'checked' : false"
        >
      </div>
      <div class="city_item">
        <span v-html="`Администратор`" />
        <input
          id="s2"
          v-model="form.data.admin"
          type="checkbox"
          class="switch"
          :checked="user.roles && user.roles.admin ? 'checked' : false"
        >
      </div>
      <div class="city_item">
        <span v-html="`Доступ ко всем городам`" />
        <input
          id="s2"
          v-model="form.data.city_all"
          type="checkbox"
          class="switch"
          :checked="user.roles && user.roles.city_all"
          :true-value="true"
          :false-value="false"
        >
      </div>
    </div>
    <div
      v-for="city in $cityList"
      :key="city.index"
      class="city_item"
    >
      <span v-html="city.name" />
      <input
        v-model="form.data.city_list"
        :value="city.index"
        type="checkbox"
        class="switch"
        :checked="form.data.city_list.includes(city.index) ? 'checked' : ''"
      >
    </div>
    <button
      class="btn_primary"
      type="button"
      @click="updateRole"
    >
      Обновить
    </button>
  </div>
</template>
<script>
export default {
    name: "PermissionEdit",
    components: {},
    props: {
        user: {
            type: Object
        }
    },
    data() {
        return {
            form: {
                id: this.user.id,
                data: {
                    super_admin: this.user.roles.super_admin ? this.user.roles.super_admin : false,
                    admin: this.user.roles.admin ? this.user.roles.admin : false,
                    city_list: this.user.roles.city_list,
                    city_all: this.user.roles.city_all ? this.user.roles.city_all : false,
                }
            }
        }
    },
    mounted() {
    },
    methods: {
        updateRole() {
            this.$emit("updateRole", this.form);
        },
    }
}
</script>
<style scoped>
.city_list {
    overflow-x: auto;
}

.city_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.city_item:not(:last-child) {
    margin-bottom: 10px;
}

.user_name {
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.permission_header {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.btn_primary {
    width: 100%;
    margin-top: 30px;
}
</style>