<template>
    <div class="user_box">
        <img :src="user.photo" alt="" onerror="this.src = '/image/icons/default/user.png'" class="img-fluid">
        <span class="user_name" v-html="`${user.name} ${user.f_name}`" />
        <div v-if="user.ban" class="blacklist_ban_box">
            <label class="default_label">Пользователь заблокирован</label>
            <div v-if="user.ban.ban_time" class="ban_time" v-html="$moment(user.ban.ban_time).format('до DD MMMM YYYY - HH:mm')" />
            <div v-else class="ban_time">
                Навсегда
            </div>
            <button v-if="$checkUser('moderation', 'edit')" type="button" class="btn_success" @click="del(user.id)">
                Снять блокировку
            </button>
        </div>
        <label v-if="!user.ban && $checkUser('moderation', 'edit')" class="default_label">Заблокировать пользователя</label>
        <div v-if="!user.ban && $checkUser('moderation', 'edit')" class="blacklist_buttons_box">
            <div v-for="item in times" :key="item.day" class="blacklist_button_item">
                <button type="button" class="btn_black" @click="ban(user.id, item.day * 24 * 60)" v-html="item.name" />
            </div>
            <div class="blacklist_button_forrever">
                <button type="button" class="btn_black" @click="ban(user.id, null)">
                    Навсегда
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ModerationUser",
    components: {},
    props: {
        user: {
            type: Object
        }
    },
    data() {
        return {
            preloader: false,
            times: [{
                    name: "1 день",
                    day: 1
                },
                {
                    name: "3 дня",
                    day: 3
                },
                {
                    name: "7 дней",
                    day: 7
                },
                {
                    name: "14 дней",
                    day: 14
                },
                {
                    name: "1 месяц",
                    day: 30
                },
                {
                    name: "3 месяца",
                    day: 90
                },
                {
                    name: "Полгода",
                    day: 180
                },
                {
                    name: "1 год",
                    day: 365
                }
            ]
        }
    },
    mounted() {

    },
    methods: {
        ban(id, time) {
            this.$emit("change", {
                id: id,
                ban_time: time
            })
        },
        del(id) {
            this.$emit("del", {
                id: id,
            })
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .blacklist_button_item {
        width: 100%;
        padding: 5px;
    }

}

@media (min-width: 768px) {
    .blacklist_button_item {
        padding: 5px;
        width: 25%;
    }
}

.user_box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user_name {
    font-weight: 400;
}

img {
    max-height: 200px;
    border-radius: 50%;
    margin: 0 auto 20px;
}

.blacklist_buttons_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.blacklist_button_forrever {
    padding: 5px;
    width: 100%;
}

.blacklist_buttons_box button {
    border-radius: 6px;
    width: 100%;
}

.default_label {
    margin-top: 40px;
}

.ban_time {
    text-align: center;
    background: rgba(var(--main-black-color), 1.0);
    color: #fff;
    padding: 5px 20px;
    border-radius: 80px;
}

.blacklist_ban_box {
    text-align: center;
}

.blacklist_ban_box small:hover {
    text-decoration: underline;
    cursor: pointer;
    padding: 10px 0 0 !important;
}

.btn_success {
    width: 100%;
    margin-top: 10px;
}
</style>