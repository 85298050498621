<template>
    <div>
        <div class="default_form">
            <div v-for="(item,index) in $parent.$data.form.addresses" :key="index" class="form_inline">
                <div class="group">
                    <input v-model="item.address" type="text" placeholder=" " required>
                    <span class="bar" />
                    <label>Адрес</label>
                </div>
                <div class="group">
                    <input v-model="item.working_days" type="text" placeholder=" " required>
                    <span class="bar" />
                    <label>График работы</label>
                </div>
                <Map :index="index" :x="item.latitude" :y="item.longitude" class="map_icon" />
                <div class="group">
                    <!-- <the-mask v-model.number="item.latitude" mask="##.######" :masked="true" required /> -->
                    <input v-model.number="item.latitude" type="text" required>
                    <!-- <input type="number" v-model="item.latitude" placeholder=" "> -->
                    <span class="bar" />
                    <label @focus="checkBoofer()">Широта</label>
                </div>
                <div class="group">
                    <!-- <the-mask v-model.number="item.longitude" @paste="onPaste" :mask="['##.######', '###.######']" :masked="true" required /> -->
                    <input v-model.number="item.longitude" type="text"  required>
                    <!-- <input type="number" v-model="item.longitude" placeholder=" "> -->
                    <span class="bar" />
                    <label>Долгота</label>
                </div>
                <button v-if="index === 0" type="button" class="table_button table_icon_edit" @click="addAddress">
                    <div class="table_icons icon_add" />
                </button>
                <button v-else type="button" class="table_button table_icon_delete" @click="$parent.$data.form.addresses.splice(index,1)">
                    <div class="table_icons icon_trash" />
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Map from "./map";
export default {
    name: "OrgAddress",
    components: {
        Map
    },
    data() {
        return {
            preloader: true,
        }
    },
    computed: {
        filter: function(value) {
            return value.toFixed(6);
        }
    },
    mounted() {

    },
    methods: {
        onPaste(evt) {
            console.log('on paste', evt)
        },
        addAddress() {
            this.$parent.$data.form.addresses.push({
                address: "",
                working_days: "",
                latitude: "",
                longitude: ""
            })
        },
        checkBoofer() {}
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .form_inline {
        display: block;
    }

    .map_icon {
        margin: 0 auto 20px;
    }
}

.btn_primary {
    width: 100%;
    margin-top: 20px;
}

.table_button {
    border-radius: 80px;
}

.form_inline {
    align-items: center;
    padding: 0;
}


.group {
    flex: 1;
}

.group:not(:last-child) {
    margin-right: 20px;
}
</style>