import { render, staticRenderFns } from "./createFolder.vue?vue&type=template&id=e78f85f2&scoped=true&"
import script from "./createFolder.vue?vue&type=script&lang=js&"
export * from "./createFolder.vue?vue&type=script&lang=js&"
import style0 from "./createFolder.vue?vue&type=style&index=0&id=e78f85f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e78f85f2",
  null
  
)

export default component.exports