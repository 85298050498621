<template>
  <div class="main_logo">
    Окей Город
  </div>
</template>
<script>

export default {
    name: "Logo",
    components: {
    }
}
</script>
<style scoped>
.main_logo {
    background: rgba(var(--main-color), 1);
    color: rgba(var(--main-text-color), 1);
    padding: 5px 15px;
    font-size: 30px;
    font-weight: bold;
    border-radius: 12px;
    user-select: none;
    text-align: center;
}

</style>