<template>
  <div class="test">
    <!-- <div>
            iphone 6s
            <v-pannellum src="./image/1.jpg" class="v-pannellum" :showFullscreen="true" />
        </div>
        <div>
            iphone 6s
            <v-pannellum src="./image/3.jpg" class="v-pannellum" :showFullscreen="true" />
        </div>
        <div>
            Canon EOS 250D авторежим (склейка фото)
            <v-pannellum src="./image/4.jpg" class="v-pannellum" :showFullscreen="true" />
        </div>
        <div>
            Samsung M30s
            <v-pannellum src="./image/6.jpg" class="v-pannellum" :showFullscreen="true" />
        </div> -->
  </div>
</template>
<script>
export default {
    name: "Home",
    components: {},
    data() {
        return {

        }
    },
    methods: {},
}
</script>
<style scoped>
.test {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.test div {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.v-pannellum {
    height: 200px;
    width: 100%;
    margin-bottom: 50px;
    border-radius: 12px;
}
</style>