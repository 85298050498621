<template>
  <div>
    <div class="city_select">
      <b><span v-html="`Выберите города для доступа ${user.name} ${user.f_name}`" /></b>
      <treeselect
        v-model="form.city_list"
        :multiple="true"
        :options="Object.values($cityList)"
        :always-open="false"
        :clearable="true"
        :no-children-text="``"
        :no-results-text="`Результатов не найдено`"
        :auto-select-descendants="true"
        :auto-deselect-descendants="true"
        :flat="true"
        :open-direction="`bottom`"
        :placeholder="`Выберите город`"
        :normalizer="normalizer"
        style="margin-top: 20px"
      />
    </div>
    <div
      class="user_name"
      :class="user && form.city_list.length ? 'activeButton' : 'disabledbutton'"
    >
      <span>Назначьте права для </span><b><span v-html="`${user.name} ${user.f_name}`" /></b>
      <small>Выберите нужные права в таблице для {{ user.name }} {{ user.f_name }}. При выборе прав будьте внимательны. Неправильно выданные права могут привести к потере конфиденциальной информации! Администрация портала не несёт ответственности за последствия.</small>
      <small style="margin-top: 40px;">Вы можете нажать на название прав или название категории, чтобы выбрать или отменить все права по линии</small>
    </div>
    <div
      class="table-responsive"
      :class="user && form.city_list.length ? 'activeButton' : 'disabledbutton'"
    >
      <table class="table table-bordered">
        <thead>
          <tr style="border-top:none;">
            <th
              scope="col"
              style="border-top:none; border-left: none;"
            />
            <th
              scope="col"
              style="border: 1px solid rgba(0, 0, 0, 0.1);"
              class="head_title text-center"
              title="Выбрать все"
              @click="setAll('view')"
            >
              Просмотр
            </th>
            <th
              scope="col"
              style="border: 1px solid rgba(0, 0, 0, 0.1);"
              class="head_title text-center"
              title="Выбрать все"
              @click="setAll('create')"
            >
              Создание
            </th>
            <th
              scope="col"
              style="border: 1px solid rgba(0, 0, 0, 0.1);"
              class="head_title text-center"
              title="Выбрать все"
              @click="setAll('edit')"
            >
              Редактирование
            </th>
            <th
              scope="col"
              style="border: 1px solid rgba(0, 0, 0, 0.1);"
              class="head_title text-center"
              title="Выбрать все"
              @click="setAll('delete')"
            >
              Удаление
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item,index) in form.management.allow"
            :key="index"
          >
            <td
              class="text-start head_title"
              @click="setAllItem(index)"
            >
              {{ item.name }}
            </td>
            <td class="text-center">
              <input
                v-if="item.permission.hasOwnProperty('view')"
                id="s2"
                v-model="item.permission.view"
                type="checkbox"
                class="checkbox"
                :true-value="true"
                :false-value="false"
              >
            </td>
            <td class="text-center">
              <input
                v-if="item.permission.hasOwnProperty('create')"
                id="s2"
                v-model="item.permission.create"
                type="checkbox"
                class="checkbox"
                :true-value="true"
                :false-value="false"
              >
            </td>
            <td class="text-center">
              <input
                v-if="item.permission.hasOwnProperty('edit')"
                id="s2"
                v-model="item.permission.edit"
                type="checkbox"
                class="checkbox"
                :true-value="true"
                :false-value="false"
              >
            </td>
            <td class="text-center">
              <input
                v-if="item.permission.hasOwnProperty('delete')"
                id="s2"
                v-model="item.permission.delete"
                type="checkbox"
                class="checkbox"
                :true-value="true"
                :false-value="false"
              >
            </td>
          </tr>
        </tbody>
      </table>
      <button
        v-if="preloader"
        type="button"
        class="btn_primary"
        @click="setPermissions"
      >
        Обновить права
      </button>
      <Preloader v-else />
    </div>
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
export default {
    name: "PermissionForm",
    components: {
        Treeselect
    },
    props: {
        user: {
            type: Object
        }
    },
    data() {
        return {
            preloader: true,
            sort: null,
            form: {
                id: this.user.id ? this.user.id : null,
                city_list: this.user.roles && this.user.roles.city_list ? this.user.roles.city_list : [],
                management: {
                    allow: [{
                            name: "Новости",
                            index: "news",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Афиша",
                            index: "poster",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Категории справочника",
                            index: "organisationCategory",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Организации",
                            index: "organisation",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Кнопки",
                            index: "buttons",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Категории объявлений",
                            index: "adsCategory",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Рекламные банеры",
                            index: "adsBanners",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Раздел помощь",
                            index: "help",
                            permission: {
                                view: false,
                                edit: false,
                            },
                        },
                        {
                            name: "Раздел реклама",
                            index: "advertising",
                            permission: {
                                view: false,
                                edit: false,
                            },
                        },
                        {
                            name: "Фильтры",
                            index: "filters",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Акции",
                            index: "shares",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Файлы",
                            index: "filemanager",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Модерация",
                            index: "moderation",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Категории новостей",
                            index: "newsCategory",
                            permission: {
                                view: false,
                                create: false,
                                edit: false,
                                delete: false,
                            },
                        },
                        {
                            name: "Настройки",
                            index: "settings",
                            permission: {
                                view: false,
                                edit: false,
                            },
                        },
                    ],
                    deny: [{}]
                }
            },
        }
    },
    computed: {},
    created() {

        return this.form.management.allow.sort((a, b) => {
            if (a.name < b.name)
                {return -1}
            if (a.name > b.name)
                {return 1}
            return 0
        })
    },
    mounted() {
        if(this.user) {
            this.setPerm();
        }
    },
    methods: {
        setAll(value) {
            this.form.management.allow.forEach(item => {
                if (Object.prototype.hasOwnProperty.call(item.permission, value)) {
                    item.permission[value] = !item.permission[value]
                }
            })
        },
        setAllItem(data) {
            Object.keys(this.form.management.allow[data].permission).forEach(function(key) {
                this.form.management.allow[data].permission[key] = !this.form.management.allow[data].permission[key];
            }.bind(this));
        },
        setPermissions() {
            this.preloader = false;
            this.form.id = this.user.id;
            let url = this.$config.api_management + "user/manager";
            this.$http.post(url, this.form).then(() => {
                this.$bvModal.hide("permissions_modal");
                this.$toast.default("Права пользователя успешно обновлены");
            }).catch((error) => {
                if (error.data && error.data.message) {
                    this.$toast.error(error.data.message);
                } else {
                    this.$toast.error("Ошибка");
                }

            }).finally(() => {
                this.api_status();
                this.preloader = true;
            })

        },
        api_status() {
            this.$parent.$emit("api_status")
        },
        normalizer(node) {
            return {
                id: node.index,
                label: node.name,
            }
        },
        setPerm() {
            if(this.user && this.user.roles && this.user.roles.management && this.user.roles.management.allow) {

                this.user.roles.management.allow.forEach(elem => {
                    let index = this.form.management.allow.findIndex(e => {
                        return e.index === elem.index;
                    })

                    if(index >= 0) {
                        let item = this.form.management.allow[index].permission;

                        Object.prototype.hasOwnProperty.call(item, ("view")) ? item.view = elem.permission.view : null;
                        Object.prototype.hasOwnProperty.call(item, ("create")) ? item.create = elem.permission.create : null;
                        Object.prototype.hasOwnProperty.call(item, ("edit")) ? item.edit = elem.permission.edit : null;
                        Object.prototype.hasOwnProperty.call(item, ("delete")) ? item.delete = elem.permission.delete : null;

                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.city_select {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 20px 0 15px;
    margin-top: 20px;

}

.user_name {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
    padding: 20px 0 15px;
    margin-top: 20px;
}

small {
    display: block;
    color: #999;
    font-size: 12px;
    width: 100%;
}

.head_title {
    transition: .2s;
    user-select: none;
}

.head_title:hover {
    cursor: pointer;
    transition: .2s;
    background: #eee;
}

.btn_primary {
    width: 100%;
    margin: 40px 0 0;
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
    filter: blur(2px);
    transition: all .5s;
}

.activeButton {
    transition: all .5s;

}
</style>