<template>
    <div>
        <b-row>
            <b-col xl="12">
                <fastLink />
            </b-col>
            <b-col xl="4">
                <UsefulLinks />
                <YandexTools />
            </b-col>
            <b-col xl="8">
                <!-- <pre>{{$user}}</pre> -->
                <div v-if="$user && $user.roles && $user.roles.super_admin && $user.managerToken" class="default_box info_form_box">
                    <label class="default_label">Ваш токен доступа к API (Видят только супер админы)</label>
                    <div class="default_form">
                        <input type="text" :value="$user.managerToken" readonly @click="$copyText($event.target.value)">
                    </div>
                </div>
                <HelpInfo />
                <UsefulTools />
                <mainLinks />
            </b-col>
            <b-col xl="4" />
        </b-row>
    </div>
</template>
<script>
import fastLink from "@/components/components/fastLink";
import UsefulLinks from "@/components/components/UsefulLinks";
import UsefulTools from "@/components/components/UsefulTools";
import HelpInfo from "@/components/components/HelpInfo";
import mainLinks from "@/components/components/mainLinks";
import YandexTools from "@/components/components/yandex";
export default {
    name: "CityHome",
    components: {
        fastLink,
        UsefulLinks,
        UsefulTools,
        HelpInfo,
        mainLinks,
        YandexTools
    },
    data() {
        return {}
    },
    methods: {},
}
</script>
<style scoped>
</style>