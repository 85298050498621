import { render, staticRenderFns } from "./AdsCategoryEdit.vue?vue&type=template&id=0ea69c38&scoped=true&"
import script from "./AdsCategoryEdit.vue?vue&type=script&lang=js&"
export * from "./AdsCategoryEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea69c38",
  null
  
)

export default component.exports