// middleware
const superAdmin = () => import ('./route/middleware/superAdmin');

// Аутентификация
const Login = () => import ('@/components/App/Authentication/Login');
const ResetPassword = () => import ('@/components/App/Authentication/ResetPassword');

// Главная
const Home = () => import ('@/components/App/Home/Home');
const CityHome = () => import ('@/components/App/Home/CityHome');

// Новости
const NewsList = () => import ('@/components/App/News/NewsList');
const NewsCreate = () => import ('@/components/App/News/NewsCreate');
const NewsEdit = () => import ('@/components/App/News/NewsEdit');

// Категории организаций
const OrganisationCategoryList = () => import ('@/components/App/Organisation/OrganisationCategory/OrganisationCategoryList');
const OrganisationCategoryAdd = () => import ('@/components/App/Organisation/OrganisationCategory/OrganisationCategoryAdd');
const OrganisationCategoryEdit = () => import ('@/components/App/Organisation/OrganisationCategory/OrganisationCategoryEdit');

// Сортировка организаций в категории
const OrganisationSortList = () => import ('@/components/App/Organisation/OrganisationSort/OrganisationSortList');

// Организации
const OrganisationList = () => import ('@/components/App/Organisation/OrganisationList');
const OrganisationAdd = () => import ('@/components/App/Organisation/OrganisationAdd');
const OrganisationEdit = () => import ('@/components/App/Organisation/OrganisationEdit');

// Разделы новостей
const NewsCategoryList = () => import ('@/components/App/News/NewsCategory/NewsCategoryList');
const NewsCategoryAdd = () => import ('@/components/App/News/NewsCategory/NewsCategoryAdd');
const NewsCategoryEdit = () => import ('@/components/App/News/NewsCategory/NewsCategoryEdit');

// Афиша
const PosterList = () => import ('@/components/App/Poster/PosterList');
const PosterAdd = () => import ('@/components/App/Poster/PosterAdd');
const PosterEdit = () => import ('@/components/App/Poster/PosterEdit');

// Акции
const ShareList = () => import ('@/components/App/Share/ShareList');
const ShareAdd = () => import ('@/components/App/Share/ShareAdd');
const ShareEdit = () => import ('@/components/App/Share/ShareEdit');

// Баннерная реклама
const CelatList = () => import ('@/components/App/Celat/CelatList');
const CelatAdd = () => import ('@/components/App/Celat/CelatAdd');
const CelatEdit = () => import ('@/components/App/Celat/CelatEdit');

// Кнопки
const ButtonsList = () => import ('@/components/App/Buttons/ButtonsList');
const ButtonsAdd = () => import ('@/components/App/Buttons/ButtonsAdd');
const ButtonsEdit = () => import ('@/components/App/Buttons/ButtonsEdit');

// Фильтры
const FiltersList = () => import ('@/components/App/Filters/FiltersList');
const FiltersAdd = () => import ('@/components/App/Filters/FiltersAdd');
const FiltersEdit = () => import ('@/components/App/Filters/FiltersEdit');

// Объявления
const AdsCategoryList = () => import ('@/components/App/AdsCategory/AdsCategoryList');
const AdsCategoryAdd = () => import ('@/components/App/AdsCategory/AdsCategoryAdd');
const AdsCategoryEdit = () => import ('@/components/App/AdsCategory/AdsCategoryEdit');

// Модерация
const Moderation = () => import ('@/components/App/Moderation/ModerationRoot');
const ModerationComments = () => import ('@/components/App/Moderation/Moderation');
const ModerationAds = () => import ('@/components/App/Moderation/ModerationAds');
const ModerationRibbon = () => import ('@/components/App/Moderation/ModerationRibbon');

// Реклама
const Advertising = () => import ('@/components/App/Advertising/Advertising');

// Администраторы
const AdministratorsList = () => import ('@/components/App/Users/AdministratorsList');

// Помощь
const Help = () => import ('@/components/App/Help/Help');

// Файловый менеджер
const Filemanager = () => import ('@/components/App/Filemanager/Filemanager');

// Модерация
const Settings = () => import ('@/components/App/Settings/SettingsIndex');

// Administrator

// Пользователи
const AdminUserList = () => import ('@/components/Admin/Users/AdminUserList');

// Города
const AdminCityList = () => import ('@/components/Admin/City/AdminCityList');
const AdminCreateCity = () => import ('@/components/Admin/City/AdminCreateCity');
const AdminEditCity = () => import ('@/components/Admin/City/AdminEditCity');

// Инструменты
const AdminTools = () => import ('@/components/Admin/Tools/Index');

// Парсер организаций
const ParserIndex = () => import ('@/components/Admin/OrganisationParser/ParserIndex');


const routes = [
    { path: '/', name: 'Home', component: Home, meta: { name: 'Главная' } },
    { path: '/login', name: 'Login', component: Login, meta: { name: 'Авторизация' } },
    { path: '/password/reset', name: 'ResetPassword', component: ResetPassword, meta: { name: 'Восстановление пароля' } },

    // City
    { path: '/:city', name: 'CityHome', component: CityHome, meta: { name: 'Панель управления' } },

    // Новости
    { path: '/:city/news', name: 'NewsList', component: NewsList, meta: { name: 'Новости' } },
    { path: '/:city/news/create', name: 'NewsCreate', component: NewsCreate, meta: { name: 'Создать новость' } },
    { path: '/:city/news/edit/:id', name: 'NewsEdit', component: NewsEdit, meta: { name: 'Редактировать новость' } },

    // Организации
    { path: '/:city/company', name: 'OrganisationList', component: OrganisationList, meta: { name: 'Организации' } },
    { path: '/:city/company/create', name: 'OrganisationAdd', component: OrganisationAdd, meta: { name: 'Создать организацию' } },
    { path: '/:city/company/edit/:id', name: 'OrganisationEdit', component: OrganisationEdit, meta: { name: 'Редактировать организацию' } },

    // Категории организаций
    { path: '/:city/company/category/:id?', name: 'OrganisationCategoryList', component: OrganisationCategoryList, meta: { name: 'Категории справочника' } },
    { path: '/:city/company/category/create', name: 'OrganisationCategoryAdd', component: OrganisationCategoryAdd, meta: { name: 'Создать категорию' } },
    { path: '/:city/company/category/edit/:id', name: 'OrganisationCategoryEdit', component: OrganisationCategoryEdit, meta: { name: 'Редактировать категорию' } },

    // Категории организаций
    { 
        path: '/:city/company/category/:id/sort', 
        name: 'OrganisationSortList', 
        component: OrganisationSortList, 
        meta: { name: 'Сортировка организаций' }
    },

    // Разделы новостей
    { path: '/:city/news/category', name: 'NewsCategoryList', component: NewsCategoryList, meta: { name: 'Разделы новостей' } },
    { path: '/:city/news/category/create', name: 'NewsCategoryAdd', component: NewsCategoryAdd, meta: { name: 'Создать раздел новостей' } },
    { path: '/:city/news/category/edit/:id', name: 'NewsCategoryEdit', component: NewsCategoryEdit, meta: { name: 'Редактировать раздел новостей' } },

    // Афиша
    { path: '/:city/poster', name: 'PosterList', component: PosterList, meta: { name: 'Афиша' } },
    { path: '/:city/poster/create', name: 'PosterAdd', component: PosterAdd, meta: { name: 'Создать афишу' } },
    { path: '/:city/poster/edit/:id', name: 'PosterEdit', component: PosterEdit, meta: { name: 'Редактировать афишу' } },

    // Акции
    { path: '/:city/share', name: 'ShareList', component: ShareList, meta: { name: 'Акции' } },
    { path: '/:city/share/create', name: 'ShareAdd', component: ShareAdd, meta: { name: 'Создать акцию' } },
    { path: '/:city/share/edit/:id', name: 'ShareEdit', component: ShareEdit, meta: { name: 'Редактировать акцию' } },

    // Баннерная реклама
    { path: '/:city/celat', name: 'CelatList', component: CelatList, meta: { name: 'Баннерная реклама' } },
    { path: '/:city/celat/create', name: 'CelatAdd', component: CelatAdd, meta: { name: 'Создать рекламу' } },
    { path: '/:city/celat/edit/:id', name: 'CelatEdit', component: CelatEdit, meta: { name: 'Редактировать рекламу' } },

    // Кнопки
    { path: '/:city/buttons', name: 'ButtonsList', component: ButtonsList, meta: { name: 'Кнопки' } },
    { path: '/:city/buttons/create', name: 'ButtonsAdd', component: ButtonsAdd, meta: { name: 'Создать кнопку' } },
    { path: '/:city/buttons/edit/:id', name: 'ButtonsEdit', component: ButtonsEdit, meta: { name: 'Редактировать кнопку' } },

    // Фильтры
    { path: '/:city/filters', name: 'FiltersList', component: FiltersList, meta: { name: 'Фильтры' } },
    { path: '/:city/filters/create', name: 'FiltersAdd', component: FiltersAdd, meta: { name: 'Создать фильтр' } },
    { path: '/:city/filters/edit/:id', name: 'FiltersEdit', component: FiltersEdit, meta: { name: 'Редактировать фильтр' } },

    // Фильтры
    { path: '/:city/ads/category', name: 'AdsCategoryList', component: AdsCategoryList, meta: { name: 'Категории объявлений' } },
    { path: '/:city/ads/category/create', name: 'AdsCategoryAdd', component: AdsCategoryAdd, meta: { name: 'Создать категорию объявлений' } },
    { path: '/:city/ads/category/edit/:id', name: 'AdsCategoryEdit', component: AdsCategoryEdit, meta: { name: 'Редактировать категорию объявлений' } },

    // Фильтры
    { 
        path: '/:city/moderation', 
        component: Moderation, 
        meta: { name: 'Модерация' },
        children: [
            {
                path: '',
                name: 'Moderation', 
                redirect: { name: 'ModerationComments' },
            },
            {
                path: 'comments',
                name: 'ModerationComments',
                component: ModerationComments,
                meta: { name: 'Комментарии' }
            },
            {
                path: 'ads',
                name: 'ModerationAds',
                component: ModerationAds,
                meta: { name: 'Объявления' }
            },
            {
                path: 'ribbon',
                name: 'ModerationRibbon',
                component: ModerationRibbon,
                meta: { name: 'Городская лента' }
            },
        ],
    },

    // Реклама
    { path: '/:city/advertising', name: 'Advertising', component: Advertising, meta: { name: 'Реклама' } },

    // Реклама
    { path: '/:city/users', name: 'AdministratorsList', component: AdministratorsList, meta: { name: 'Администраторы' } },

    // Помощь
    { path: '/:city/help', name: 'Help', component: Help, meta: { name: 'Помощь' } },

    // Настройки
    { path: '/:city/settings', name: 'Settings', component: Settings, meta: { name: 'Настройки' } },

    { path: '/:city/filemanager', name: 'Filemanager', component: Filemanager, meta: { name: 'Менеджер файлов' } },


    // Admin
    {
        path: '/admin/userlist',
        name: 'AdminUserList',
        component: AdminUserList,
        meta: { name: 'Пользователи' },
        middleware: [superAdmin]
    },


    {
        path: '/admin/citylist',
        name: 'AdminCityList',
        component: AdminCityList,
        meta: { name: 'Управление городами' },
        middleware: [superAdmin]
    },
    {
        path: '/admin/city/create',
        name: 'AdminCreateCity',
        component: AdminCreateCity,
        meta: { name: 'Создать город' },
        middleware: [superAdmin]
    },
    {
        path: '/admin/city/edit/:city_name',
        name: 'AdminEditCity',
        component: AdminEditCity,
        meta: {
            name: 'Редактировать город',
            middleware: [superAdmin]
        }
    },

    // Admin
    {
        path: '/admin/tools',
        name: 'AdminTools',
        component: AdminTools,
        meta: {
            name: 'Инструменты',
            middleware: [superAdmin]

        }
    },
    // Admin Parser
    {
        path: '/admin/:city/parser',
        name: 'ParserIndex',
        component: ParserIndex,
        meta: {
            name: 'Парсер организаций',
            middleware: [superAdmin]

        }
    },
    

    { path: '/*', redirect: { name: 'Home' } },
];


export default routes;