<template>
  <div>
    <div v-if="preloader">
      <form
        @submit.stop.prevent
        @submit="send"
      >
        <b-row class="justify-content-between">
          <b-col xl="6">
            <div class="default_box default_form">
              <div class="group">
                <input
                  v-model="form.title"
                  type="text"
                  placeholder=" "
                  required
                >
                <span class="bar" />
                <label>Название</label>
              </div>
              <div
                v-if="form.model"
                class="group form_inline"
              >
                <span class="inline_label">Цель</span>
                <select
                  id=""
                  v-model="form.model"
                  name=""
                  required
                  @change="clearType"
                >
                  <option
                    v-for="cat in meta_data"
                    :key="cat.model"
                    :value="cat.model"
                    v-html="cat.name"
                  />
                </select>
              </div>
              <div class="group">
                <input
                  v-model="form[meta_data[form.model].goal]"
                  :type="meta_data[form.model].input_type"
                  placeholder=" "
                  required
                >
                <span class="bar" />
                <label v-html="meta_data[form.model].placeholder" />
              </div>
              <div class="group form_inline">
                <span class="inline_label">Опубликовано</span>
                <input
                  id="s2"
                  v-model="form.published"
                  type="checkbox"
                  class="switch"
                >
              </div>
              <button
                v-if="$checkUser('buttons', 'edit')"
                class="btn_primary"
                type="submit"
              >
                Сохранить кнопку
              </button>
            </div>
          </b-col>
          <b-col xl="12">
            <Filemanager
              ref="Filemanager"
              :images.sync="images"
              leng="1"
            />
          </b-col>
        </b-row>
      </form>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
import Filemanager from "@/components/App/Filemanager/Filemanager";
export default {
    name: "ButtonsEdit",
    components: {
        Filemanager
    },
    data() {
        return {
            preloader: false,
            images: [],
            meta_data: {
                "Organizations": {
                    id: 1,
                    name: "Организации",
                    model: "Organizations",
                    goal: "section_id",
                    input_type: "number",
                    placeholder: "ID организации"
                },
                "Category": {
                    id: 1,
                    name: "Категория справочника",
                    model: "Category",
                    goal: "cat_id",
                    input_type: "number",
                    placeholder: "ID категории"
                },
                "Shares": {
                    id: 2,
                    name: "Акции",
                    model: "Shares",
                    goal: "section_id",
                    input_type: "number",
                    placeholder: "ID акции"

                },
                "Poster": {
                    id: 3,
                    name: "Афиша",
                    model: "Poster",
                    goal: "section_id",
                    input_type: "number",
                    placeholder: "ID афиши"
                },
                "Url": {
                    id: 4,
                    name: "Ссылка",
                    model: "Url",
                    goal: "url",
                    input_type: "url",
                    placeholder: "Ссылка"
                },
                "Tel": {
                    id: 5,
                    name: "Телефонный звонок",
                    model: "Tel",
                    goal: "telephone",
                    input_type: "text",
                    placeholder: "Телефон"
                }
            },
            form: {
                title: null,
                model: null,
                url: null,
                published: true,
                telephone: null,
                image: null,
                section: 1,
                section_id: null,
                cat_id: null,
                order: 0
            }
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/buttons/" + this.$route.params.id;
            this.$http.get(url).then((response) => {
                if (!response.data) {
                    this.$router.push({ name: "ButtonsList" });
                    this.$toast.error("Такой кнопки не существует");
                }
                this.form = {
                    title: response.data.title,
                    url: response.data.url,
                    published: response.data.published,
                    telephone: response.data.telephone,
                    section: response.data.section,
                    section_id: response.data.section_id,
                    cat_id: response.data.cat_id,
                    order: response.data.order,
                    model: response.data.type == "Organizations" && response.data.cat_id ? "Category" : response.data.type
                };

                if (response.data.image) {
                    this.images.push(this.$onlyPhat(response.data.image));
                }
            }).catch(() => {
                this.$router.push({ name: "ButtonsList" });
            }).finally(() => {
                this.preloader = true;
            });

        },
        send() {
            if (this.images && this.images.length) {
                this.form.image = this.$config.api_filemanager + this.images[0];
            } else {
                this.$toast.error("Вы не выбрали изображение для кнопки");
                return;
            }

            if (this.form.model === "Category") {
                this.form.model = null;
            }

            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/buttons/" + this.$route.params.id;
            this.$http.put(url, this.form).then(() => {
                this.$toast.default("Кнопка успешно создана");
                this.$router.push({ name: "ButtonsList" });
            }).finally(() => {
                this.preloader = true;
            });

        },
        clearType() {
            this.form.section = this.meta_data[this.form.model].id;
            this.form.model == "Organizations" || this.form.model == "Shares" || this.form.model == "Poster" ? this.form.section_id : this.form.section_id = null;
            this.form.model == "Category" ? this.form.cat_id : this.form.cat_id = null;
            this.form.model == "Url" ? this.form.url : this.form.url = null;
            this.form.model == "Tel" ? this.form.telephone : this.form.telephone = null;
        },
    }
}
</script>
<style scoped>
</style>