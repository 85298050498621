<template>
  <div
    class="list_box"
    :class="parent ? null : 'no_parent'"
  >
    <div class="list_item">
      <div class="list_item_title_box">
        <span
          class="parent_category"
          :style="item.active ? 'background: rgba(var(--main-color), 1.0)' : 'background:#b71c1c'"
          title="Скопировать ID категории"
          @click="$copyText(item.id)"
          v-html="'ID • ' + item.id"
        />
        <span
          class="parent_category_title"
          v-html="item.name"
        />
      </div>
      <div class="action_box">
        <input
          v-if="$checkUser('adsCategory', 'edit')"
          id="s2"
          type="checkbox"
          class="switch"
          :checked="item.active"
          :title="item.active ? 'Выключить видимость категории' : 'Включить видимость категории'"
          :true-value="true"
          :false-value="false"
          @change="api_status(item, index)"
        >
        <div class="table_button_box">
          <router-link
            v-if="$checkUser('adsCategory', 'edit')"
            :to="{name: 'AdsCategoryEdit', params: {id: item.id}}"
            tag="div"
            class="table_button_item"
            :title="`Редактировать категорию`"
          >
            <button class="table_button table_icon_edit">
              <div class="table_icons icon_pen" />
            </button>
          </router-link>
          <div
            v-if="$checkUser('adsCategory', 'delete')"
            class="table_button_item"
            :title="`Удалить категорию`"
          >
            <button
              class="table_button table_icon_delete"
              @click="$parent.del(item.id, item.name)"
            >
              <div class="table_icons icon_trash" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.category && item.category.length">
      <Item
        v-for="elem in item.category"
        :key="elem.id"
        :item="elem"
        :parent="false"
        :index="elem.id"
        @api_status="api_status"
        @del="del"
      />
    </div>
  </div>
</template>
<script>
import Item from "./item.vue";
export default {
    name: "Item",
    components: {
        Item
    },
    props: {
        item: {
            type: Object
        },
        index: {
            type: Number
        },
        parent:  {
            type: Boolean
        },
    },
    data() {
        return {
            disabled_status: false
        }
    },
    mounted() {
    },
    methods: {
        api_status(item, name, index) {
            this.$emit("api_status", item, name, index)
        },
        del(id, index) {
            this.$emit("del", id, index)
        }
    }
}
</script>
<style scoped>
.no_parent {
    margin-left: 5%;
}

.list_box {}

.list_item {
    background: #fff;
    border-radius: 6px;
    margin-bottom: 15px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list_item_title_box {
    display: flex;
    align-items: center;
}

.parent_category {
    padding: 3px 5px;
    border-radius: 4px;
    background: rgba(var(--main-color), 1.0);
    color: rgba(var(--main-text-color), 1.0);
    margin-right: 10px;
    cursor: pointer;
}

.action_box {
    display: flex;
    align-items: center;    
}

.action_box input {
    margin-right: 15px;
}

.parent_category_title {
    padding-right: 20px;
}
</style>