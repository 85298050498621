<template>
    <div>
        <form v-if="preloader" @submit.stop.prevent @submit="send">
            <b-row class="justify-content-between">
                <b-col sm="8">
                    <div class="default_box default_form">
                        <div class="group">
                            <input v-model.trim="form.data.title" type="text" placeholder=" " required>
                            <span class="bar" />
                            <label>Заголовок новости</label>
                        </div>
                        <wysiwyg v-model="generate_text" class="editor-container" />

                        <!-- <div class="group">
                            <textarea v-model.trim="form.data.text" cols="30" rows="10" placeholder="Текст новости" required></textarea>
                            <span class="bar" />
                        </div> -->
                    </div>
                </b-col>
                <b-col sm="4">
                    <div class="default_box info_form_box ">
                        <label class="default_label">Опции новости</label>
                        <div class="default_form">
                            <div class="group form_inline">
                                <span class="inline_label">Опубликована</span>
                                <input v-model="form.published" type="checkbox" class="switch">
                            </div>
                            <div class="group form_inline">
                                <span class="inline_label">Закрепить</span>
                                <input v-model="form.type" type="checkbox" class="switch" :true-value="2" :false-value="1">
                            </div>
                            <div class="group form_inline">
                                <span class="inline_label">Раздел</span>
                                <select v-if="category && category.length" id="" v-model="form.category_id" name="">
                                    <option :value="0">
                                        Новости
                                    </option>
                                    <option v-for="cat in category" :key="cat.id" :value="cat.id" v-html="cat.title">
                                        Второй раздел
                                    </option>
                                </select>
                                <select v-else id="" name="">
                                    <option>Новости</option>
                                </select>
                            </div>
                            <div class="group">
                                <input v-model.trim="form.source_url" type="text" placeholder=" ">
                                <span class="bar" />
                                <label>Источник</label>
                            </div>
                            <div class="group">
                                <input v-model.trim="form.video_url" type="text" placeholder=" " pattern="https://youtu.be/.*">
                                <span class="bar" />
                                <label>Ссылка на видео YouTube</label>
                            </div>
                            <div class="group">
                                <input v-model="form.vk_video_url" type="text" placeholder=" " data-info>
                                <span class="bar" />
                                <inputInfoPopover value="vk" />
                                <label>Ссылка на видео ВКонтакте</label>
                            </div>
                        </div>
                        <button class="btn_primary" type="submit">
                            Сохранить новость
                        </button>
                    </div>
                </b-col>
                <b-col sm="12">
                    <Filemanager ref="filemanager" :images.sync="images" leng="20" />
                </b-col>
            </b-row>
        </form>
        <Preloader v-else />
    </div>
</template>
<script>
import Filemanager from "@/components/App/Filemanager/Filemanager";
import inputInfoPopover from "@/components/components/default/inputInfoPopover";
export default {
    name: "NewsCreate",
    components: {
        Filemanager,
        inputInfoPopover,
    },
    data() {
        return {
            category: null,
            images: [],
            preloader: true,
            prefill_text: null,
            hidden: {
                heading: true,
            },
            form: {
                type: 1,
                category_id: 0,
                published: true,
                images: [],
                data: {
                    title: null,
                    text: null,
                    text_html: null
                },
                video_url: null,
                source_url: null,
                vk_video_url: null

            },
        }
    },
    mounted() {
        this.api();
        this.category_api();
    },
    methods: {
        onlyPhat(file) {
            return decodeURI((new URL(file).pathname));
        },
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/news/" + this.$route.params.id;
            this.$http.get(url).then((response) => {
                    if (!response.data) {
                        this.$router.push({ name: "NewsList" });
                        this.$toast.error("Такой новости не существует");
                    }

                    this.prefill_text = response.data.data.text_html ? response.data.data.text_html : response.data.data.text;
                    this.form = {
                        type: response.data.type,
                        category_id: response.data.category_id,
                        published: response.data.published,
                        data: {
                            title: response.data.data.title,
                            text: response.data.data.text,
                            text_html: response.data.data.text_html ? response.data.data.text_html : response.data.data.text
                        },
                        video_url: response.data.video_url,
                        source_url: response.data.source_url,
                        vk_video_url: response.data.vk_video_url

                    }
                    console.log(this.form);
                    if (response.data.images && response.data.images.length) {
                        this.images = response.data.images.map(elem => {
                            return this.onlyPhat(elem.image);
                        })
                    }
                })
                .catch(() => {
                    this.$router.push({ name: "NewsList" });
                })
                .finally(() => {
                    this.preloader = true;
                });

        },
        category_api() {
            let url = this.$config.api_management + this.$city.index + "/news/category";
            this.$http.get(url).then((response) => {
                this.category = response.data.filter(elem => {
                    if (elem.published) {
                        return elem;
                    }
                });
            })
        },
        async send() {

            if (this.images && this.images.length) {
                this.form.images = await this.images.map((elem, key) => {
                    let data = {
                        image: this.$config.api_filemanager + elem,
                        sort_order: key + 1
                    }
                    return data;
                })
            }

            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/news/" + this.$route.params.id;
            this.$http.put(url, this.form).then((response) => {
                if (response.data && response.data.id) {
                    this.$indexNow(this.$config.site_info.links.news, response.data.id);
                }
                this.$toast.default("Новость успешно изменена");
                this.api();

            }).finally(() => {
                this.preloader = true;
            });

        },
        stripHtmlTags(str) {
            str = str.replace(/<div\s*\/?>/gi, '\n');
            return str.replace(/<\/?[^>]+(>|$)/g, '');
        }
    },
    computed: {
        generate_text: {
            get() {
                return this.form.data.text_html;
            },
            set(newValue) {
                this.form.data.text = this.stripHtmlTags(newValue);
                this.form.data.text_html = newValue;
            }
        }
    }
}
</script>
<style scoped>
.btn_primary {
    width: 100%;
    margin-top: 20px;
}

.medium_editor {
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 0px;
}

.editor-container {
    text-align: left;
}

.editor-container ::v-deep #container {
    border-radius: 12px;
    overflow: hidden;
    text-align: inherit;
}

.editor-container ::v-deep table {
    min-height: 50px;
}

.editor-container ::v-deep table td {
    height: 30px;
}


.editor-container ::v-deep table tr td > * {
    min-height: 30px;
}

.editor-container ::v-deep table span {
    display: block;
}

</style>