<template>
    <div>
        <div v-if="preloader">
            <div v-if="selectImage && selectImage.length" class="default_box">
                <div class="img_review_label_box">
                    <span class="img_preview_label">Изображения</span>
                    <div class="info_icon" title="Вы можете сортировать изображения. Зажмите изображение и перетащите в нужном порядке" />
                </div>
                <draggable v-model="selectImage" @change="updateValue(selectImage)">
                    <transition-group name="flip-list" tag="div" class="img_preview_box">
                        <img v-for="(img,index) in selectImage" :key="img" :src="$config.api_filemanager + img" alt="" class="img_preview" @click="del_image(index)" onerror="this.onerror = null; this.src = '/image/noimage.png'">
                    </transition-group>
                </draggable>
            </div>
            <progress v-if="uploadProgressMax" :max="uploadProgressMax" class="w-100" :value.prop="uploadPercentage" />
            <div class="default_box" @dragover="over">
                <addFile ref="addFile" />
                <div class="filemanager_button_box">
                    <div v-if="celat" class="celat_orientation_box">
                        <div class="celat_orientation_item">
                            <input v-model="orientation_image" type="radio" class="orientation_radio_button" value="horizontal"><span>700 x 400px</span>
                        </div>
                        <div class="celat_orientation_item">
                            <input v-model="orientation_image" type="radio" class="orientation_radio_button" value="square"><span>400 x 400px</span>
                        </div>
                    </div>
                    <div>
                        <button v-if="$checkUser('filemanager', 'create')" class="filemanager_button filemanager_button_add_file" title="Добавить изображения" type="button">
                            <div class="filemanager_icon icon_add_image" />
                            <input type="file" class="filemanager_input_file" multiple accept="image/jpeg, image/png, image/gif" @change="$refs.addFile.addFile($event)">
                        </button>
                        <button v-if="$checkUser('filemanager', 'create')" v-b-modal.createFolder class="filemanager_button filemanager_button_primary" title="Создать директорию" type="button">
                            <div class="filemanager_icon icon_create_folder" />
                        </button>
                        <button v-if="selectImage && selectImage.length" class="filemanager_button filemanager_button_primary" title="Сбросить выделение" type="button" @click="selectImage = []">
                            <div class="filemanager_icon icon_rubber" />
                        </button>
                        <button v-if="$checkUser('filemanager', 'delete') && (selected_folders.length || selectImage.length)" class="filemanager_button filemanager_button_danger" title="Удалить" type="button" @click="destroy">
                            <div class="filemanager_icon icon_delete_folder" />
                        </button>
                    </div>
                    <div class="select">
                        <select v-model="sortBy" class="" @change="api()">
                            <option value="asc">
                                От новых к старым
                            </option>
                            <option value="desc">
                                От старых к новым
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="leng && selectImage.length == leng" class="limit_alert">
                    Вы можете выбрать не более {{ leng }} изображений
                </div>
                <div v-if="data">
                    <div v-if="directory_name.path !== '/' && data" class="filemanager_route_box">
                        <div class="filemanager_route_item">
                            <span class="filemanager_route_item_link" @click="openFolder('/', true)">Главная</span>
                        </div>
                        <div v-for="(link, index_link) in data.path.split('/')" :key="index_link" class="filemanager_route_item">
                            <span v-html="'&nbsp;/&nbsp;'" />
                            <span class="filemanager_route_item_link" @click="stringToPath(data.path, index_link)" v-html="link" />
                        </div>
                    </div>
                    <div v-if="data.dirs && data.dirs.length" class="filemanager_directory_box">
                        <div v-for="dir in data.dirs" :key="dir" class="filemanager_directory_item" :title="'Открыть директорию ' + dir" :style="dir === 'user_avatar' ? 'display:none' : null">
                            <div v-if="dir !== 'user_avatar'" class="icons_folder icon_folder" @click="openFolder(dir)" />
                            <div>
                                <input v-if="dir !== 'user_avatar' && dir !== 'lk_organisations'" v-model="selected_folders" type="checkbox" class="files_checkbox" :value="dir">
                                <span v-if="dir !== 'user_avatar'" v-html="dir" />
                            </div>
                        </div>
                    </div>
                    <div v-if="data.files && data.files.length" class="">
                        <div class="filemanager_files_box">
                            <imagePreview v-for="(file,num) in data.files.slice(0,limit_image)" :key="file + '_' + num" :url="file" :num="num" :leng="leng ? leng : null" :celat="celat ? true : false" />
                        </div>
                        <div v-if="data.files.length > limit_image">
                            <button class="btn_primary" type="button" @click="limit_image += 24">
                                Показать ещё
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <span class="no_image_info">В этой директории ещё нет картинок</span>
                        <img src="/image/fun/cat2.gif" alt="sad_cat" class="cat_image">
                        <button v-if="directory_name.path !== '/' && data" class="btn_primary" @click="openFolder('/', true)">
                            Вернуться на главную
                        </button>
                    </div>
                </div>
            </div>
            <Tinybox v-if="data.files && data.files.length" v-model="index" :images="data.files.slice(0, limit_image)" no-thumbs loop class="tinybox_main" />
            <createFolder />
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import draggable from "vuedraggable";
import imagePreview from "./components/file";
import createFolder from "./components/createFolder";
import addFile from "./components/addFile";
export default {
    name: "Filemanager",
    components: {
        imagePreview,
        createFolder,
        addFile,
        draggable
    },
    props: {
        images: Array,
        celat: Boolean,
        leng: {
            type: [Number, String],
            coerce: str => parseInt(str)
        }
    },
    data() {
        return {
            orientation_image: "horizontal",
            uploadProgressMax: 0,
            uploadPercentage: 0,
            index: null,
            preloader: false,
            image_path: null,
            directory_name: {
                tag: "new",
                path: "/"
            },
            sortBy: "asc",
            data: null,
            limit_image: 24,
            selected_folders: [],
            selected_images: [],
        }
    },
    computed: {
        selectImage: {
            get: function() {
                return this.selected_images;
            },
            set: function(newWal) {
                this.selected_images = newWal;
                this.updateValue(newWal)
            }
        }
    },
    mounted() {
        this.selected_folders = [];
        this.selectImage = [];
        this.api();
        if (this.images && this.images.length) {
            this.selectImage = this.images;
        }
    },
    methods: {
        api() {
            this.limit_image = 24;
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/media/showPath";
            let data = {
                "tag": this.directory_name.tag,
                "path": this.directory_name.path,
                "sortByDate": this.sortBy,
            };
            this.$http.post(url, data).then((response) => {
                this.image_path = "https://media.okaygorod.com/" + response.data.rootPath + "/";
                this.data = response.data;
                this.data.files = response.data.files.reverse().map((file, index) => {
                    if (this.directory_name.path === "/") {
                        return this.data.files[index] = encodeURI(("https://media.okaygorod.com/" + response.data.rootPath + "/".concat(file)).trim());
                    } else {
                        return this.data.files[index] = encodeURI(("https://media.okaygorod.com/" + response.data.rootPath + "/" + response.data.path + "/".concat(file)).trim());
                    }

                });
            }).finally(() => {
                this.preloader = true;
            });
        },
        openFolder(folder, home = null) {
            if (home) {
                this.directory_name.path = folder;
            } else {
                this.directory_name.path = this.directory_name.path !== "/" ? this.directory_name.path + "/" + folder : folder;

            }
            this.api();
        },
        stringToPath(string, index) {
            let array = string.split("/");
            array.splice(index + 1, array.length - index);
            array = array.join("/");
            this.directory_name.path = array;
            this.api();
        },
        async destroy() {
            await this.deleteFolders();
            await this.delete_files();
        },
        deleteFolders() {
            let url = this.$config.api_management + this.$city.index + "/media/destroyPath";
            let data = {
                "tag": this.directory_name.tag,
                "path": this.directory_name.path,
                "folders": this.selected_folders
            };
            if (this.selected_folders.length && confirm("Вы уверены, что хотите удалить эти директории? \n\n- " + this.selected_folders.join("\n\n- "))) {
                this.preloader = false;
                this.$http.post(url, data).then(() => {
                    this.$toast.default("Директория успешно удалена");
                }).finally(() => {
                    this.preloader = true;
                    this.api();
                });
            }
        },
        delete_files() {
            let url = this.$config.api_management + this.$city.index + "/media/path";
            let data = {
                "_method": "DELETE",
                "files": this.selectImage,
            };
            
            if (this.selectImage.length &&
                confirm("Вы уверены, что хотите удалить эти файлы? \n\n- " + this.selectImage.join("\n\n- "))) {
                this.preloader = false;

                this.$http.post(url, data).then(() => {
                    this.selectImage = [];
                    this.$toast.default("Файлы успешно удалены");

                }).finally(() => {
                    this.preloader = true;
                    this.api();
                });
            }
        },
        over() {
            document.getElementById("image_drop_box").style.display = "flex";
        },
        overClose() {
            document.getElementById("image_drop_box").style.display = "none";
        },
        updateValue(images) {
            this.$emit("update:images", images)
        },
        del_image(index) {
            this.selected_images.splice(index, 1);
        }
    }
}
</script>
<style scoped>
.flip-list-move {
    transition: transform 1s;
}

.img_preview_box img {
    transition: .2s;
}

.img_preview_box img:hover {
    cursor: pointer;
    transition: .2s;
    background: #b71c1c;
}

.icons_folder {
    width: 75px;
    height: 75px;
    background-color: rgba(var(--main-color), 1);
    display: block;
    transition: .2s;
}

.icons_folder:hover {
    cursor: pointer;
    background-color: rgba(var(--main-color-transition), 1);
    transition: .2s;
}

.icon_folder {
    mask-image: url('/image/icons/default/folder.svg');
}

.filemanager_directory_box {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.default_box {
    z-index: 1;
}

.default_box:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: #fff;
    background-image: url('/image/decor/inspiration-geometry.png') !important;
    background-repeat: center;
    border-radius: 12px;
}

.filemanager_directory_item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 10px 10px;
}

.filemanager_files_box {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    gap: 15px;
}

.filemanager_route_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 5px 10px;
}

.filemanager_route_item_link {
    font-size: 18px;
    cursor: pointer;
    border-bottom: 1px solid rgba(var(--main-color), 0);
    transition: .2s
}

.filemanager_route_item_link:hover {
    color: rgba(var(--main-color), 1);
    border-bottom: 1px solid rgba(var(--main-color), 1);
    transition: .2s
}

.btn_primary {
    padding: 10px 25px;
}

.files_checkbox {
    margin-right: 5px;
}

.filemanager_button_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.filemanager_button {
    outline: none;
    border: none;
    padding: 5px 15px;
}

.filemanager_button_box .filemanager_button:first-child {
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
}

.filemanager_button_box .filemanager_button:last-child {
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
}

.filemanager_button_primary {
    background: rgba(var(--main-color), 1);
    color: rgba(var(--main-text-color), 1);
}

.filemanager_button:hover {
    transition: .2s;
    background: rgba(var(--main-color-transition), 1);
}

.filemanager_button_danger {
    background: #a90329;
    color: #fff;
}

.filemanager_button_add_file {
    background: #28a745;
    color: #fff;
    position: relative;
}

.filemanager_icon {
    width: 25px;
    height: 25px;
    background-color: #fff;
    display: block;
    transition: .2s;
}

.icon_create_folder {
    mask-image: url('/image/icons/filemanager/add-folder.svg');
}

.icon_add_image {
    mask-image: url('/image/icons/filemanager/image.svg');
}

.icon_delete_folder {
    mask-image: url('/image/icons/filemanager/trash.svg');
}

.icon_rubber {
    mask-image: url('/image/icons/filemanager/rubber.svg');
}

.no_image_info {
    background: rgba(var(--main-color), 1);
    color: rgba(var(--main-text-color), 1);
    color: #fff;
    border-radius: 80px;
    padding: 10px 20px;
}

.cat_image {
    max-height: 200px;
    border-radius: 50%;
    display: block;
    margin: 50px auto;
    user-select: none;
}

.filemanager_input_file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.filemanager_input_file_box {
    position: relative;
}

.limit_alert {
    background: rgba(247, 71, 28, .2);
    font-weight: bold;
    border-radius: 12px;
    padding: 5px;
    margin: 5px 0;
}

.celat_orientation_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
}

.celat_orientation_box input {
    margin-right: 10px;
}

.celat_orientation_item {
    margin-right: 20px;
}
</style>