<template>
    <div>
        <div class="default_box info_form_box">
            <label class="default_label">Ссылки на полезные разделы сайта</label>
            <span>Для сайта / для кнопок мобильного приложения</span>
            <input id="s2" v-model="status" type="checkbox" class="switch">
            <div class="default_form">
                <div class="group">
                    <label>Курсы валют ЦБ РФ</label>
                    <input id="link_rss" type="text" :value="$config.site_info.url + $city.index +'/currency' + (status ? '?noMenu=true' : '')" readonly @click="$copyText($event.target.value)">
                </div>
                <div class="group">
                    <label>ФССП</label>
                    <input id="link_rss" type="text" :value="$config.site_info.url + $city.index +'/fssp' + (status ? '?noMenu=true' : '')" readonly @click="$copyText($event.target.value)">
                </div>
                <div class="group">
                    <label>Проверка штрафов ГИБДД</label>
                    <input id="link_rss" type="text" :value="$config.site_info.url + $city.index +'/gibdd' + (status ? '?noMenu=true' : '')" readonly @click="$copyText($event.target.value)">
                </div>
                <div class="group">
                    <label>Проверка автомобиля</label>
                    <input id="link_rss" type="text" :value="$config.site_info.url + $city.index +'/gibdd/checkdtp' + (status ? '?noMenu=true' : '')" readonly @click="$copyText($event.target.value)">
                </div>
            </div>
        </div>
        <div class="default_box info_form_box">
            <label class="default_label">Трекинг ссылки для Яндекс Директ</label>
            <div class="default_form">
                <div class="group">
                    <label>Android</label>
                    <input id="link_rss" type="text" value="https://redirect.appmetrica.yandex.com/serve/243637061872087187?google_aid_sha1={GOOGLE_AID_LC_SH1}&android_id_sha1={ANDROID_ID_LC_SH1}&device_type={device_type}&source_type={source_type}&source={source}&google_aid={GOOGLEAID}&click_id={LOGID}&search_term={keyword}&region_name={region_name}&phrase_id={phrase_id}&android_id={ANDROIDID}&position_type={position_type}&campaign_id={campaign_id}" readonly @click="$copyText($event.target.value)">
                </div>
                <div class="group">
                    <label>IOS</label>
                    <input id="link_rss" type="text" value="https://redirect.appmetrica.yandex.com/serve/315401581266787279?click_id={LOGID}&ios_ifa_sha1={IDFA_LC_SH1}&search_term={keyword}&device_type={device_type}&region_name={region_name}&source_type={source_type}&phrase_id={phrase_id}&ios_ifa={IOSIFA}&source={source}&position_type={position_type}&campaign_id={campaign_id}" readonly @click="$copyText($event.target.value)">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Etalon",
    components: {},
    data() {
        return {
            preloader: false,
            status: false
        }
    },
    mounted() {

    },
    methods: {}
}
</script>
<style scoped>
.default_form label {
    font-size: 18px;
}

.group {
    position: relative;
    padding-top: 35px;
}

input[type=checkbox] {
    margin-left: 10px;
}
</style>