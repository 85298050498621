<template>
    <div>
        <div v-if="preloader">
            <div v-if="list && list.length">
                <Message v-for="(item,key) in list" :key="item.id" :item="item" :index="key" class="message_box" category="ribbon" />
                <div v-if="button_preloader">
                    <div v-if="list && last_page && page < last_page" class="news_button_block">
                        <button class="btn_primary" @click="api_next">
                            Показать ещё
                        </button>
                    </div>
                </div>
                <Preloader v-else />
                <b-modal id="users_modal" v-bind="$attrs" centered :hide-header="true" ok-title="Закрыть" :ok-only="true">
                    <div v-if="modal_preloader">
                        <div v-if="user">
                            <ModerationUser :user="user" @change="ban_user" @del="delete_ban_user" />
                        </div>
                    </div>
                    <Preloader v-else />
                </b-modal>
            </div>
            <div v-else class="default_box bg_white">
                Комментариев не найдено
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import Message from "./components/message";
import ModerationUser from "./components/moderationUser";

export default {
    name: "ModerationRibbon",
    components: {
        Message,
        ModerationUser
    },
    data() {
        return {
            preloader: true,
            button_preloader: true,
            modal_preloader: true,
            last_page: null,
            user: null,
            page: 1,
            images: [],
            index: null,
            status: {
                0: {
                    title: "Новый",
                    background: "#3c81df",
                    color: "#fff"
                },
                1: {
                    title: "Обновлён",
                    background: "#ffc107",
                    color: "#2c3e50"
                },
                2: {
                    title: "Скрыт",
                    background: "#999",
                    color: "#fff"
                },
                10: {
                    title: "Одобрен",
                    background: "#47d78a",
                    color: "#fff"
                },
            },
            list: null
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/communication";
            this.$http.get(url, {
                params: {
                    per_page: 25,
                    sort_by_desc: true

                }
            }).then((response) => {
                this.list = response.data.data;
                this.last_page = response.data.last_page;
            }).finally(() => {
                this.preloader = true;
            });
        },
        api_next() {
            this.button_preloader = false;
            let url = this.$config.api_management + this.$city.index + "/communication";
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page,
                    per_page: 25,
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.data);
            }).finally(() => {
                this.button_preloader = true;
            })

        },
        set_status(id, status, index) {

            let data = {
                status: status
            }

            let url = this.$config.api_management + this.$city.index + "/communication/" + id;
            this.$http.put(url, data).then((response) => {
                this.list[index].status = response.data.status;
                this.$toast.default("Статус комментария успешно изменён");
            }).catch(() => {
                this.$toast.error("Ошибка изменения статуса");
            })

        },
        get_user(id) {
            this.modal_preloader = false;
            this.$bvModal.show("users_modal");
            let url = this.$config.api_management + "user/" + id + "/info";
            this.$http.get(url).then((response) => {
                this.user = response.data;
            }).catch(() => {
                this.user = null;
                this.$bvModal.hide("users_modal");
                this.$toast.error("Ошибка");
            }).finally(() => {
                this.modal_preloader = true;
            })
        },
        ban_user(data) {
            if (confirm("Вы действительно хотите заблокировать этого пользователя?")) {
                let url = this.$config.api_management + "user/ban";
                this.$http.post(url, {
                    id: data.id,
                    ban_time: data.ban_time
                }).then(() => {
                    this.$toast.default("Пользователь успешно заблокирован");
                    this.$bvModal.hide("users_modal");
                }).catch(() => {
                    this.user = null;
                    this.$bvModal.hide("users_modal");
                })

            }
        },
        delete_ban_user(data) {
            if (confirm("Вы действительно хотите разблокировать этого пользователя?")) {
                let url = this.$config.api_management + "user/" + data.id + "/ban";
                this.$http.delete(url).then(() => {
                    this.$toast.default("Пользователь успешно разблокирован");
                    this.get_user(data.id);
                }).catch(() => {
                    this.user = null;
                    this.$bvModal.hide("users_modal");
                })

            }
        },
        del(id, index) {
            let url = this.$config.api_management + this.$city.index + "/communication/" + id;
            return this.$http.delete(url).then(() => {
                this.list.splice(index, 1);
                this.$toast.default("Комментарий успешно удалён");
            })
        }
    }
}
</script>
<style scoped>
.message_box {
    padding: 5px;
    display: flex;
}

.message_box:not(:last-child) {
    margin-bottom: 20px;
}

.moderation_category {
    display: inline-block;
}

.default_box {
    display: inline-block;
}
</style>