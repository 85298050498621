/* eslint-disable no-console */
// RoutController.js
// ========
/* eslint-disable */
import Vue from "vue";
import axios from 'axios';
import config from './config';
import VueRouter from 'vue-router';

const globalData = new Vue({
    data: {
        $user: null,
        $cityList: null,
        $city: null,
    },
    methods: {},
});

Vue.prototype.$scrollToTop = (ms = 100) => {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, ms);
};

Vue.mixin({
    computed: {
        $user: {
            get: function() {
                if (localStorage.getItem('user') && !globalData.$data.$user) {
                    globalData.$data.$user = JSON.parse(localStorage.getItem('user'));
                    axios.defaults.headers['Secure-Key'] = globalData.$data.$user.managerToken;
                }
                return globalData.$data.$user
            },

            set: function(newData) {
                if (!newData) {
                    localStorage.removeItem('user');
                    axios.defaults.headers['Secure-Key'] = null;
                    globalData.$data.$user = null;
                    globalData.$data.$city = null;
                    globalData.$data.$cityList = null;
                } else {
                    localStorage.setItem('user', JSON.stringify(newData));
                    axios.defaults.headers['Secure-Key'] = newData.managerToken;
                    globalData.$data.$user = newData;
                }
            },
        },
        $cityList: {
            get: function() {
                return globalData.$data.$cityList;
            },
            set: function(data) {
                if (data) {
                    globalData.$data.$cityList = data;
                }

            }
        },
        $city: {
            get: function() {
                if (localStorage.getItem('city') && !globalData.$data.$city) {
                    globalData.$data.$city = JSON.parse(localStorage.getItem('city'));
                }
                return globalData.$data.$city;
            },
            set: function(city) {
                if (city) {
                    globalData.$data.$city = city;
                    localStorage.setItem('city', JSON.stringify(city));
                    this.$router.push({ name: 'CityHome', params: { city: city.index } });
                } else {
                    globalData.$data.$city = null;
                }

            }
        },
    }
});

axios.interceptors.response.use(
    function(response) {
        if (response && (response.status >= 200 || response.status < 300)) {
            return response;
        }
        return Promise.reject(response);
    },
    function(error) {
        if (error.response && error.response.status && error.response.status === 401) {
            globalData.$data.$user = null;
            localStorage.removeItem('user');
            location.reload();
        }

        if (error.response) {
            if (error.response.data && error.response.data.errors) {
                for (let [key, elem] of Object.entries(error.response.data.errors)) {
                    elem.forEach(er => {
                        Vue.prototype.$toast.error(er, {});
                    })
                }
            } else if (error.response.data.response && error.response.data.response.message) {
                Vue.prototype.$toast.error(error.response.data.response.message, {});
            }
        }
        return Promise.reject(error.response);

    });

Vue.prototype.$indexNow = (category, id) => {
    let link = config.site_info.url + globalData.$data.$city.index + '/' + category + '/' + id;
    let url = 'https://okaygorod.site/yandexIndexNow/index.php';
    axios.get(url, {
        params: {
            link: link
        }
    }).then((response) => {
        console.log(response);
        Vue.prototype.$toast.success('Страница отправлена на переобход роботом Yandex');
    }) 
};

Vue.prototype.$htmContext = (text) => {
    text = text.replace(/\u00a0/g, ' ');
    text = text.replace(/<br\s*[/]?>\r\n/gi, '<br>');
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    let text1 = text.replace(exp, "<a style='word-break: break-all;' class='link_carryover' target='_blank' rel='nofollow' href='$1'>$1</a>");
    let exp2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    return text1.replace(exp2, '$1<a style="word-break: break-all;" class="link_carryover" target="_blank" rel="nofollow" href="http://$2">$2</a>');
};

Vue.prototype.$phonemask = (text) => {
    let x = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    text = !x[2] ? x[1] : '(' + x[1] + ')' + x[2] + (x[3] ? '-' + x[3] : '');
    return text;
};

Vue.prototype.$mobileWidth = () => {
    return window.innerWidth < 768;

};

Vue.prototype.$averageWidth = () => {
    return window.innerWidth < 992;

};

Vue.prototype.$pcWidth = () => {
    return window.innerWidth > 1545;

};

Vue.prototype.$goBack = () => {
    window.history.back();
};

Vue.prototype.$cutText = (value, limit) => {
    return value.length > limit ?
        value.slice(0, limit - 3) + '...' :
        value;
};


Vue.prototype.$asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        let aWait = (x) => new Promise((resolve) => setTimeout(resolve, x));

        await callback(array[index], index, array);
        await aWait(2000);
    }
};

Vue.prototype.$translit = (word) => {
    var answer = '';
    var converter = {
        'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
        'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
        'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
        'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
        'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
        'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
        'э': 'e',    'ю': 'yu',   'я': 'ya',
 
        'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
        'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
        'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
        'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
        'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
        'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
        'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya',   ' ' : '',    '-': '',
        '_': '',     '(': '',     ')': ''
    };
 
    for (var i = 0; i < word.length; ++i ) {
        if (converter[word[i]] == undefined){
            answer += word[i];
        } else {
            answer += converter[word[i]];
        }
    }
 
    return answer.toLowerCase();
}

Vue.prototype.$assignValue = (val) => {
    if (val === null) {
        return '';
    } else {
        return val;
    }
}

Vue.prototype.$copyText = async (s) => {

    await navigator.clipboard.writeText(s);
    if(s.toString().length <= 100) {
        Vue.prototype.$toast.default(`Текст\n\n"${s}"\n\nуспешно скопирован в буфер обмена`);        
    } else {
        Vue.prototype.$toast.default('Текст успешно скопирован в буфер обмена');
    }
}


Vue.prototype.$onlyPhat = (file) => {
    try {
        new URL(file);
    } catch (_) {
        return file;  
    }

    return decodeURI((new URL(file).pathname));
}

Vue.prototype.$isURL = (str) => {
    try {
        new URL(str);
    } catch (_) {
        return false;  
    }

    return true;
}

Vue.prototype.$generateId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

Vue.prototype.$getRandomArbitrary = (min, max) => {
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand).toLocaleString();
}

Vue.prototype.$checkUser = (chapter, permission) => {
    if(globalData.$data.$user && globalData.$data.$user.roles) {

        if(globalData.$data.$user.roles.super_admin) {
            return true;
        }

        if(globalData.$data.$user.roles.admin && !globalData.$data.$user.roles.management) {
            return true;
        }

        if(globalData.$data.$user.roles.admin 
            && globalData.$data.$user.roles.management 
            && globalData.$data.$user.roles.management.allow 
            && globalData.$data.$user.roles.management.allow.length) {

            if(globalData.$data.$user.roles.management.allow.find(x => x.index === chapter) 
                && globalData.$data.$user.roles.management.allow.find(x => x.index === chapter).permission[permission]) {
                return true;
            }            
        }

        return false;

    }
}