<template>
  <div>
    <div class="default_form">
      <div class="form_inline">
        <div class="group">
          <input
            v-model.trim="keyword"
            type="text"
            placeholder=" "
            @keyup.enter="addKeyword"
          >
          <span class="bar" />
          <label>Ключевое слово</label>
        </div>
        <button
          type="button"
          class="table_button table_icon_edit"
          :disabled="!keyword"
          @click="addKeyword"
        >
          <div class="table_icons icon_add" />
        </button>
      </div>
      <div
        v-if="$parent.$data.form.keywords.length"
        class="keywords_box"
      >
        <span
          v-for="(item,index) in $parent.$data.form.keywords"
          :key="index"
          class="keyword_item"
          :title="`Удалить ключевое слово ''${item.name}''`"
          @click="del(index)"
          v-html="item.name"
        />
        <small>Нажмите на ключевое слово, чтобы удалить его</small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: "Keywords",
    components: {},
    data() {
        return {
            keyword: null
        }
    },
    mounted() {

    },
    methods: {
        addKeyword() {
            if (this.keyword.length) {
                this.$parent.$data.form.keywords.push({
                    name: this.keyword
                })
                this.keyword = null;
            } else {
                return;
            }
        },
        del(index) {
            this.$parent.$data.form.keywords.splice(index, 1);
        }
    }
}
</script>
<style scoped>
.table_button {
    border-radius: 80px;
}

.form_inline {
    align-items: center;
    padding: 0;
}

.group {
    flex: 1;
}

.group:not(:last-child) {
    margin-right: 20px;
}

.keywords_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -10px;
}

.keyword_item {
    padding: 2px 10px;
    border-radius: 4px;
    background: rgba(var(--main-color), 1.0);
    color: rgba(var(--main-text-color), 1.0);
    margin-top: 10px;
    transition: .2s;
    user-select: none;
}

.keyword_item:not(:last-child) {
    margin-right: 5px;
}

.keyword_item:hover {
    cursor: pointer;
    background: #b71c1c;
    transition: .2s;
}
</style>