<template>
  <div>
    <b-modal
      id="createFolder"
      centered
      title="Создать директорию"
      hide-footer
      hide-title
    >
      <div
        v-if="preloader"
        class="default_form"
      >
        <form
          @submit.stop.prevent
          @submit="send"
        >
          <div class="group">
            <input
              v-model="folder"
              type="text"
              placeholder=" "
              pattern="^[a-zA-Z0-9-_]+$"
              title="Разрешенные символы a-z 0-9 _ -"
              required
            >
            <span class="bar" />
            <label>Название директории</label>
            <small>Например "news"</small>
          </div>
          <button
            type="submit"
            class="btn_primary"
          >
            Создать
          </button>
        </form>
      </div>
      <Preloader v-else />
    </b-modal>
  </div>
</template>
<script>
export default {
    name: "CreateFolder",
    components: {},
    data() {
        return {
            preloader: true,
            folder: null
        }
    },
    methods: {
        send() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/media/addPath";
            let data = {
                "tag": this.$parent.$data.directory_name.tag,
                "path": this.$parent.$data.directory_name.path,
                "folders": [
                    this.folder.toLowerCase()
                ]
            };
            this.$http.post(url, data).then(() => {
                this.$parent.api();
                this.$toast.default("Директория успешно создана");
            }).finally(() => {
                this.preloader = true;
            });
        },
    },
}
</script>
<style scoped>
.btn_primary {
    width: 100%;
    padding: 10px 15px;
}
</style>