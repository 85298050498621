<template>
  <b-modal
    id="modal_service"
    title="Добавить услугу"
    scrollable
    centered
    hide-footer
  >
    <form
      v-if="preloader"
      @submit.stop.prevent
    >
      <div class="default_form">
        <div class="group">
          <input
            v-model="custom.name"
            type="text"
            placeholder=" "
            :required="custom.price ? true : false"
          >
          <span class="bar" />
          <label>Название услуги</label>
        </div>
        <div class="group">
          <textarea
            v-model="custom.description"
            cols="30"
            rows="10"
            placeholder="Описание услуги"
          />
          <span class="bar" />
        </div>
        <div class="group">
          <input
            v-model="custom.price"
            type="text"
            placeholder=" "
            :required="custom.name ? true : false"
          >
          <span class="bar" />
          <label>Стоимость услуги</label>
        </div>
        <button
          v-if="$checkUser('advertising', 'edit') && (custom.name || custom.price)"
          class="btn_primary"
          type="submit"
          @click="add_servise"
        >
          Добавить
        </button>
        <button
          v-else
          class="btn_black"
          type="submit"
          @click="$bvModal.hide('modal_service')"
        >
          Закрыть
        </button>
      </div>
    </form>
    <Preloader v-else />
  </b-modal>
</template>
<script>
export default {
    name: "ModalService",
    components: {},
    data() {
        return {
            preloader: true,
            custom: {
                name: null,
                description: null,
                price: null
            },
        }
    },
    mounted() {

    },
    methods: {
        clear_custom() {
            this.custom = {
                name: null,
                description: null,
                price: null
            }
        },
        add_servise() {
            let item = {
                name: this.custom.name,
                description: this.custom.description,
                price: this.custom.price,
            }
            if (item.name && item.price) {
                console.log(this.$parent.$data.form)
                this.$parent.$data.form.custom_fields.push(item);
                this.$bvModal.hide("modal_service");
                this.clear_custom();
            } else {
                return
            }
        },
    }
}
</script>
<style scoped>
#modal_service .btn_primary,
#modal_service .btn_black {
    width: 100%;
}
</style>