<template>
  <div>
    <div class="default_box info_form_box">
      <label class="default_label">Полезные инструменты</label>
      <button
        v-b-toggle.collapse-1
        class="userful_link_item"
        type="button"
      >
        <img
          src="/image/icons/usefulLinks/smiling.svg"
          alt=""
          class="userful_link_img img-fluid"
        >
        <span>Удалить эмодзи из текста</span>
      </button>
      <b-collapse
        id="collapse-1"
        class="mt-2"
      >
        <div class="default_box default_form">
          <div class="group">
            <textarea
              v-model="text"
              cols="30"
              rows="10"
              placeholder="Вставьте текст"
              required
            />
          </div>
        </div>
        <div
          v-if="text.length"
          class="default_box"
        >
          <label class="default_label">Готовый текст</label>
          <div
            class="icons_home icon_copy"
            title="Копировать текст"
            @click="$copyText(removeEmoji(text))"
          />
          <p
            style="text-align: left;"
            v-html="$htmContext(removeEmoji(text))"
          />
        </div>
      </b-collapse>
      <button
        v-b-toggle.collapse-text-length
        class="userful_link_item"
        type="button"
      >
        <img
          src="/image/icons/usefulLinks/font.svg"
          alt=""
          class="userful_link_img img-fluid"
        >
        <span>Длина текста</span>
      </button>
      <b-collapse
        id="collapse-text-length"
        class="mt-2"
      >
        <div class="default_box default_form">
          <div class="group">
            <textarea
              v-model="textLength"
              cols="30"
              rows="10"
              placeholder="Вставьте текст"
              required
            />
          </div>
        </div>
        <div
          v-if="textLength.length"
          class="default_box"
        >
          <p
            style="text-align: left;"
            v-html="'<b>Всего символов:</b> ' + textLength.length"
          />
          <p
            style="text-align: left;"
            v-html="'<b>Всего символов без пробелов:</b> ' + removeSpaces(textLength).length"
          />
          <p
            style="text-align: left;"
            v-html="'<b>Всего символов без Эмодзи:</b> ' + removeEmoji(textLength).length"
          />
          <p
            style="text-align: left;"
            v-html="'<b>Всего слов:</b> ' + countWords(removeEmoji(textLength))"
          />
        </div>
      </b-collapse>
      <button
        v-b-toggle.collapse-text-translit
        class="userful_link_item"
        type="button"
      >
        <img
          src="/image/icons/usefulLinks/font.svg"
          alt=""
          class="userful_link_img img-fluid"
        >
        <span>Транслитерация</span>
      </button>
      <b-collapse
        id="collapse-text-translit"
        class="mt-2"
      >
        <div class="default_box default_form">
          <div class="group">
            <textarea
              v-model="translit"
              cols="30"
              rows="10"
              placeholder="Вставьте текст"
              required
            />
          </div>
        </div>
        <div
          v-if="translit.length"
          class="default_box"
        >
          <label class="default_label">Готовый текст</label>
          <div
            class="icons_home icon_copy"
            title="Копировать текст"
            @click="$copyText(translitText(translit))"
          />
          <p
            style="text-align: left;"
            v-html="translitText(translit)"
          />
        </div>
      </b-collapse>
      <button
        v-b-toggle.collapse-link
        class="userful_link_item"
        type="button"
      >
        <img
          src="/image/icons/usefulLinks/link.svg"
          alt=""
          class="userful_link_img img-fluid"
        >
        <span>Конвертер ссылок сайта для кнопок мобильного приложения</span>
      </button>
      <b-collapse
        id="collapse-link"
        class="mt-2"
      >
        <div class="default_box default_form">
          <div class="group">
            <input
              v-model="link"
              placeholder="https://okaygorod.com/"
              pattern="http://www\.okaygorod\.com\/(.+)|https://www\.okaygorod\.com\/(.+)"
            >
          </div>
        </div>
        <div
          v-if="link"
          class="icons_home icon_copy"
          title="Копировать текст"
          @click="$copyText(link + '?noMenu=true')"
        />
        <p
          v-if="link"
          style="text-align: left;"
          v-html="'<b>Ссылка:</b> ' + link + '?noMenu=true'"
        />
      </b-collapse>
      <button
        v-b-toggle.collapse-color-picker
        class="userful_link_item"
        type="button"
      >
        <img
          src="/image/icons/usefulLinks/color-wheel.svg"
          alt=""
          class="userful_link_img img-fluid"
        >
        <span>Color Picker</span>
      </button>
      <b-collapse
        id="collapse-color-picker"
        class="mt-2"
      >
        <div class="default_box default_form">
          <div class="group">
            <input
              v-model="color"
              type="color"
            >
          </div>
          <div
            v-if="color"
            class="color_desc_box"
          >
            <span
              @click="$copyText(color)"
              v-html="'<b>HEX:</b> ' + color"
            />
            <span
              @click="$copyText(hexToRGB(color))"
              v-html="'<b>RGB:</b> ' + hexToRGB(color)"
            />
            <span
              @click="$copyText(hexToRGBA(color))"
              v-html="'<b>RGBA:</b> ' + hexToRGBA(color)"
            />
            <span
              @click="$copyText(hexToHSL(color))"
              v-html="'<b>HSL:</b> ' + hexToHSL(color)"
            />
            <small>Код цвета можно скопировать нажатием на него</small>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
    name: "UsefulTools",
    components: {},
    data() {
        return {
            timer: 0,
            text: "",
            textLength: "",
            translit: "",
            link: "",
            color: ""
        }
    },
    mounted() {

    },
    methods: {
        removeEmoji(text) {
            var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
            return text.replace(regex, " ");
        },
        removeSpaces(text) {
            return text.replace(/\s+/g, "");
        },
        countWords(s) {
            s = s.replace(/(^\s*)|(\s*$)/gi, "");
            s = s.replace(/[ ]{2,}/gi, " ");
            s = s.replace(/\n /, "\n");
            return s.split(" ").filter(function(str) { return str != ""; }).length;
        },
        translitText(word) {
            var answer = "";
            var converter = {
                "а": "a",
                "б": "b",
                "в": "v",
                "г": "g",
                "д": "d",
                "е": "e",
                "ё": "e",
                "ж": "zh",
                "з": "z",
                "и": "i",
                "й": "y",
                "к": "k",
                "л": "l",
                "м": "m",
                "н": "n",
                "о": "o",
                "п": "p",
                "р": "r",
                "с": "s",
                "т": "t",
                "у": "u",
                "ф": "f",
                "х": "h",
                "ц": "c",
                "ч": "ch",
                "ш": "sh",
                "щ": "sch",
                "ь": "",
                "ы": "y",
                "ъ": "",
                "э": "e",
                "ю": "yu",
                "я": "ya",

                "А": "A",
                "Б": "B",
                "В": "V",
                "Г": "G",
                "Д": "D",
                "Е": "E",
                "Ё": "E",
                "Ж": "Zh",
                "З": "Z",
                "И": "I",
                "Й": "Y",
                "К": "K",
                "Л": "L",
                "М": "M",
                "Н": "N",
                "О": "O",
                "П": "P",
                "Р": "R",
                "С": "S",
                "Т": "T",
                "У": "U",
                "Ф": "F",
                "Х": "H",
                "Ц": "C",
                "Ч": "Ch",
                "Ш": "Sh",
                "Щ": "Sch",
                "Ь": "",
                "Ы": "Y",
                "Ъ": "",
                "Э": "E",
                "Ю": "Yu",
                "Я": "Ya",
            };

            for (var i = 0; i < word.length; ++i) {
                if (converter[word[i]] == undefined) {
                    answer += word[i];
                } else {
                    answer += converter[word[i]];
                }
            }

            return answer.toLowerCase();
        },
        hexToRGB(h) {
            let r = 0,
                g = 0,
                b = 0;

            // 3 digits
            if (h.length == 4) {
                r = "0x" + h[1] + h[1];
                g = "0x" + h[2] + h[2];
                b = "0x" + h[3] + h[3];

                // 6 digits
            } else if (h.length == 7) {
                r = "0x" + h[1] + h[2];
                g = "0x" + h[3] + h[4];
                b = "0x" + h[5] + h[6];
            }

            return "rgb(" + +r + "," + +g + "," + +b + ")";
        },
        hexToRGBA(hex, opacity) {
            return "rgba(" + (hex = hex.replace("#", "")).match(new RegExp("(.{" + hex.length / 3 + "})", "g")).map(function(l) { return parseInt(hex.length % 2 ? l + l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(",") + ")";
        },
        hexToHSL(H) {
            // Convert hex to RGB first
            let r = 0,
                g = 0,
                b = 0;
            if (H.length == 4) {
                r = "0x" + H[1] + H[1];
                g = "0x" + H[2] + H[2];
                b = "0x" + H[3] + H[3];
            } else if (H.length == 7) {
                r = "0x" + H[1] + H[2];
                g = "0x" + H[3] + H[4];
                b = "0x" + H[5] + H[6];
            }
            // Then to HSL
            r /= 255;
            g /= 255;
            b /= 255;
            let cmin = Math.min(r, g, b),
                cmax = Math.max(r, g, b),
                delta = cmax - cmin,
                h = 0,
                s = 0,
                l = 0;

            if (delta == 0)
                {h = 0;}
            else if (cmax == r)
                {h = ((g - b) / delta) % 6;}
            else if (cmax == g)
                {h = (b - r) / delta + 2;}
            else
                {h = (r - g) / delta + 4;}

            h = Math.round(h * 60);

            if (h < 0)
                {h += 360;}

            l = (cmax + cmin) / 2;
            s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
            s = +(s * 100).toFixed(1);
            l = +(l * 100).toFixed(1);

            return "hsl(" + h + "," + s + "%," + l + "%)";
        }
    }
}
</script>
<style scoped>
.userful_link_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
    border-radius: 12px;
    transition: .2s;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
}

.userful_link_item:hover {
    background: rgba(var(--main-color), .1);
    transition: .2s;
}

.userful_link_img {
    max-height: 25px;
    margin-right: 10px;
}

#collapse-1 textarea,
#collapse-text-length textarea {
    border: 1px solid #ccc;
    border-radius: 12px;
}

.icons_home {
    width: 35px;
    height: 35px;
    background-color: #28a745;
    display: block;
    transition: .2s;
}

.icons_home:hover {
    background-color: rgba(var(--main-color-transition), 1);
    transition: .2s;
    cursor: pointer;
}

.icon_copy {
    mask-image: url('/image/icons/usefulLinks/copy.svg');
    margin-bottom: 20px;
}

input[type="color"] {
    margin-top: 20px;
    -webkit-appearance: none;
    border: none;
    padding: 0;
    background: #fff;
    width: 100%;
    height: 100px !important;
    border-radius: 12px;
    border: 1px solid rgba(0,0,0,.1);
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 12px;
}

input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 12px;
}


/* firefox */
input[type=color]::-moz-focus-inner {
    border: none;
    padding: 0;
    border-radius: 12px;
}

input[type=color]::-moz-color-swatch {
    border: none;
    border-radius: 12px;
}

.color_desc_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.color_desc_box span {
    padding: 5px 10px;
    margin-bottom: 10px;
    background: #eee;
    border-radius: 12px;
    transition: .2s;
}

.color_desc_box span:hover {
    transition: .2s;
    background: rgba(var(--main-color),1);
    color: #fff;
    cursor: pointer;
}
</style>