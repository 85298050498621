const config = {
    site_info: {
        name: "Окей Город",
        logo: "/image/logo.png",
        url: "https://okaygorod.com/",
        links: {
            organisation: "organisation",
            news: "news",
            shares: "shares",
            poster: "poster",
        }
    },
    myApi: "https://api.koms.online/api/v1/",
    api_url: "https://api.okaygorod.com/api/v1/",
    api_management: "https://api.okaygorod.com/api/v1/management/",
    api_route: "https://api.openrouteservice.org/v2/",
    api_filemanager: "https://media.okaygorod.com",
    API_TIME_ZONE: "Europe/Paris",
    api_okgorod: "https://api.okgorod.ru/api/v1/",
    api_okgorod_token: "eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY3MzUxOTMyMSwiaWF0IjoxNjczNTE5MzIxfQ.6o7aC60bea9JL_hoG6o4XPvI851zPMSXx-BT7l8vKvE",

    MediumEditor: {
        options: {
            keyboardCommands: false,
            targetBlank: true,
            imageDragging: false,
            image: false,
            extensions: {
                'imageDragging': {},
            },
            placeholder: {
                text: 'Введите текст',
                hideOnClick: true
            },
            paste: {
                cleanPastedHTML: true,
                cleanAttrs: ['style', 'dir'],
                cleanTags: ['label', 'meta'],
                unwrapTags: ['sub', 'sup']
            },
            autoLink: true,
            anchor: {
                placeholderText: 'Вставьте ссылку',
                customClassOption: 'btn',
                customClassOptionText: 'По ссылке можно перейти'
            },
            toolbar: {
                allowMultiParagraphSelection: true,
                buttons: ['bold', 'italic', 'underline', 'strikethrough', 'anchor', 'quote', 'orderedlist', 'unorderedlist', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'removeFormat'],
                relativeContainer: null,
                standardizeSelectionStart: true,
                static: true,
                /* options which only apply when static is true */
                align: 'left',
                sticky: true,
                updateOnEmptySelection: false
            },
        },
    }
};

export default config;