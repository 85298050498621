<template>
    <div>
        <div v-if="preloader">
            <div v-if="item" class="message_item">
                <div class="message_user_avatar_box">
                    <img v-if="item.user && item.user.photo" :src="item.user.photo" alt="" class="img-fluid" onerror="this.src = '/image/icons/default/user.png'" title="Информация о пользователе" @click="get_user(item.user.id)">
                    <img v-else src="/image/icons/default/user.png" alt="" class="img-fluid" title="Информация о пользователе" @click="get_user(item.user.id)">
                </div>
                <div class="message">
                    <div class="message_item_container">
                        <div class="d-flex">
                            <span class="user_name" title="Информация о пользователе" @click="get_user(item.user.id)" v-html="`${item.user.name} ${item.user.f_name}`" />
                        </div>
                        <div v-if="item.news && item.news.data" class="link_name">
                            <span>К новости </span>
                            <a :href="$config.site_info.url + $city.index + '/news/' + item.news_id" target="_blank" v-html="`&nbsp;&quot;${item.news.data.title}&quot;`" />
                        </div>
                        <div v-else-if="item.category_id && item.status !== 2" class="link_name">
                            <span>Объявление </span>
                            <a :href="$config.site_info.url + $city.index + '/ads/' + item.id" target="_blank" v-html="`&nbsp;&quot;.../ads/${item.id}&quot;`" />
                        </div>
                        <div v-else-if="item.organization && item.organization.name" class="link_name">
                            <span>К организации </span>
                            <a :href="$config.site_info.url + $city.index + '/organisation/' + item.organizations_id" target="_blank" v-html="`&nbsp;&quot;${item.organization.name}&quot;`" />
                        </div>
                        <div v-else-if="category === 'ribbon'" class="link_name">
                            <span>Городская лента </span>
                            <a :href="$config.site_info.url + $city.index + '/communication/' + item.id" target="_blank" v-html="`&nbsp;&quot;.../communication/${item.id}&quot;`" />
                        </div>
                        <button v-if="$checkUser('moderation', 'delete')" type="button" class="message_delete_item" @click="del">
                            +
                        </button>
                        <p v-if="item.comment" lang="ru" class="message_text" v-html="$htmContext(item.comment)" />
                        <p v-else-if="item.text" lang="ru" class="message_text" v-html="$htmContext(item.text)" />
                        <img v-if="item.image" :src="item.image" alt="" class="message_image img-fluid">
                        <div v-if="item.communication_id || item.organizations_id || item.news_id || item.ads_id" class="message_link_box">
                            <span v-if="item.communication_id" class="user_name">Общение <a :href="$config.site_info.url + $city.index + '/communication/' + item.communication_id" target="_blank" :title="`Открыть ссылку ${$config.site_info.url + $city.index + '/communication/' + item.communication_id}`" v-html="' .../communication/' + item.communication_id" /></span>
                            <span v-else-if="item.ads_id" class="user_name">Объявление <a :href="$config.site_info.url + $city.index + '/ads/' + item.ads_id" target="_blank" :title="`Открыть ссылку ${$config.site_info.url + $city.index + '/ads/' + item.ads_id}`" v-html="' .../ads/' + item.ads_id" /></span>
                            <span v-else-if="item.news_id" class="user_name">Новости <a :href="$config.site_info.url + $city.index + '/news/' + item.news_id" target="_blank" :title="`Открыть ссылку ${$config.site_info.url + $city.index + '/news/' + item.news_id}`" v-html="' .../news/' + item.news_id" /></span>
                            <span v-else-if="item.organizations_id" class="user_name">Организация <a :href="$config.site_info.url + $city.index + '/organisation/' + item.organizations_id" target="_blank" :title="`Открыть ссылку ${$config.site_info.url + $city.index + '/organisation/' + item.organizations_id}`" v-html="' .../organisation/' + item.organizations_id" /></span>
                        </div>
                        <div class="message_footer">
                            <div :id="`status_popover_${item.id}`" class="message_status_box" :style="`background: ${$parent.$data.status[item.status].background}; color: ${$parent.$data.status[item.status].color}`">
                                <span v-html="$parent.$data.status[item.status].title" />
                            </div>
                            <b-popover v-if="$checkUser('moderation', 'edit')" :target="`status_popover_${item.id}`" no-fade placement="leftbottom" triggers="hover focus" class="popover_item">
                                <button v-for="(status,key) in $parent.$data.status" :key="key" :style="`background: ${status.background}; color: ${status.color}`" type="button" class="status_button" @click="$parent.set_status(item.id, key,index)" v-html="status.title" />
                            </b-popover>
                            <span class="message_date" v-html="$moment(item.created_at).format('DD.MM.YYYY в HH:mm')" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
export default {
    name: "Message",
    components: {},
    props: {
        item: {
            type: Object
        },
        index: {
            type: Number
        },
        category: {
            type: String
        }
    },
    data() {
        return {
            preloader: true,
        }
    },
    mounted() {},
    methods: {
        get_user(id) {
            this.$parent.get_user(id);
        },
        del() {
            if (confirm("Вы уверены, что хотите удалить этот комментарий?\n\nЭто действие нельзя отменить!")) {
                this.preloader = false;

                let data = this.$parent.del(this.item.id, this.index);

                data.catch(() => {
                    this.$toast.default("Ошибка удаления комментария");
                    this.preloader = true;
                });
            }
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .message_user_avatar_box {
        display: none;
    }
}

.message_item {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}

.message {
    flex: 1;
    box-sizing: content-box;
    background: #fff;
    padding: 5px 15px 10px;
    border-radius: 1em;
    position: relative;
    line-height: 1.5em;
    text-align: left;
    z-index: -1;
    min-width: 250px;
}

.message::after,
.message::before {
    content: "";
    position: absolute;
    right: 100%;
    bottom: .5em;
    width: 1.8em;
    height: 1.8em;
    border: .5em solid #eee;
    border-radius: 50%;
    background: #eee;
    z-index: -1;
}

.message::before {
    border-top: none;
    height: .9em;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    background: #fff;
    border-color: #fff;
    margin-right: -.9em;
    z-index: -1;
}

.message_item_container {
    z-index: 2;
    position: relative;
}

.message_delete_item {
    position: absolute;
    top: 5px;
    right: 0px;
    font-size: 32px;
    font-weight: bold;
    color: red;
    transform: rotate(45deg);
    transition: .3s;
    user-select: none;
}

.message_delete_item:hover {
    cursor: pointer;
    transform: rotate(405deg);
    transition: .3s;
}

.message_user_avatar_box {
    position: sticky;
    bottom: 5px;
    align-self: flex-end;
    border-radius: 50%;
    margin-right: 20px;
    height: 50px;
    width: 50px;
}

.message_user_avatar_box img {
    overflow: hidden;
    border-radius: 50%;
    height: 50px;
    width: 50px;

}

.message_image {
    border-radius: 6px;
    max-height: 200px;
    margin-bottom: 20px;
}

.message_user_avatar_box img,
.user_name {
    cursor: pointer;
}

.user_name,
.link_name {
    padding: 5px 20px 5px 0px;
    color: #999;
    font-size: 14px;
    display: flex;
}

.link_name {
    font-size: 12px;
    display: inline-block !important;
    width: 100%;
    padding-bottom: 10px;
}

.link_name span {}

.link_name a {}

.message_text {
    display: block;
    margin: 5px 0;
    word-break: break-word;
}

.message_link_box {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    padding: 0;
}

.message_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message_status_box {
    padding: 0 10px;
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    overflow-x: auto;
}

.message_date {
    color: #999;
    font-size: 12px;
    display: block;
    text-align: right;

}

.popover_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status_button {
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    transition: .2s;
}

.status_button:not(:last-child) {
    margin-bottom: 10px;
}

.status_button:hover {
    cursor: pointer;
    transition: .2s;
    background: rgba(var(--main-color-transition), 1.0) !important;
    color: #fff !important;
}
</style>