<template>
    <div>
        <form v-if="preloader" @submit.stop.prevent @submit="send">
            <b-row class="justify-content-between">
                <b-col xl="6">
                    <div class="default_box default_form">
                        <div class="group">
                            <input v-model="form.name" type="text" placeholder=" " required>
                            <span class="bar" />
                            <label>Название</label>
                        </div>
                        <div class="group">
                            <textarea v-model="form.description" cols="30" rows="10" placeholder="Описание"></textarea>
                            <span v-if="form.description">{{ form.description.length.toLocaleString() }} / {{ maxLength.toLocaleString() }}</span>
                            <span class="bar" />
                        </div>
                    </div>
                </b-col>
                <b-col xl="6">
                    <div class="default_box info_form_box ">
                        <label class="default_label">Опции организации</label>
                        <div class="default_form">
                            <div class="group form_inline">
                                <span class="inline_label">Опубликовано</span>
                                <input id="s2" v-model="form.published"
                                  type="checkbox"
                                  class="switch"
                                  :true-value="1"
                                  :false-value="0"
                                >
                            </div>
                            <div class="group form_inline">
                                <span class="inline_label">Закрепить в поиске</span>
                                <input id="s2" v-model="form.fix_top_search"
                                  type="checkbox"
                                  class="switch"
                                  :true-value="1"
                                  :false-value="0"
                                >
                            </div>
                            <div class="group">
                                <input
                                  v-model="form.url_youtube"
                                  type="text"
                                  placeholder=" "
                                  pattern="https://youtu.be/.*"
                                >
                                <span class="bar" />
                                <label>Ссылка на видео YouTube</label>
                            </div>
                            <div class="group">
                                <input v-model="form.vk_video_url" type="text" placeholder=" " data-info>
                                <span class="bar" />
                                <inputInfoPopover value="vk" />
                                <label>Ссылка на видео ВКонтакте</label>
                            </div>
                        </div>
                        <div class="warn" v-if="old_links && old_links.length">
                            <strong>Внимание</strong>
                            <small>У организации есть ссылки добавленные через старую панель управления, при сохранении они будут удалены.</small>
                            <span v-for="(link, index) in old_links" :key="index" v-html="link" class="warn_old_link"></span>
                            <small>Вы можете перенести их в новую панель управления нажав на кнопку</small>
                            <button class="btn_black" @click="convertOldLinks" type="button">Конвертировать и перенести</button>
                        </div>
                        <button
                          v-if="$checkUser('organisation', 'edit')"
                          class="btn_primary"
                          type="submit"
                        >
                          Сохранить организацию
                        </button>
                    </div>
                </b-col>
                <b-col xl="12">
                    <div class="default_box info_form_box">
                        <label
                          class="default_label"
                          style="margin-bottom: 30px;"
                        >Категории справочника</label>
                        <Category />
                    </div>
                </b-col>
                <b-col xl="4">
                    <div class="default_box info_form_box">
                        <label class="default_label">Сайты <small
                          v-if="form.sites"
                          v-html="`(${form.sites.length} из 7)`"
                        /></label>
                        <Sites />
                    </div>
                </b-col>
                <b-col xl="4">
                    <div class="default_box info_form_box">
                        <label class="default_label">Телефоны</label>
                        <Phones />
                    </div>
                </b-col>
                <b-col xl="4">
                    <div class="default_box info_form_box">
                        <label class="default_label help_label">
                          Ключевые слова 
                          <VideoHelp 
                            :label="`Посмотреть помощь по ключевым словам`" 
                            video_id="SC9dwojemsY" 
                          />
                        </label>
                        <Keywords />
                    </div>
                </b-col>
                <b-col xl="12">
                    <div class="default_box info_form_box ">
                        <label class="default_label">Адреса</label>
                        <Address />
                    </div>
                </b-col>
                <b-col xl="12">
                    <Filemanager
                        ref="Filemanager"
                        :images.sync="images"
                        leng="20"
                    />
                </b-col>
            </b-row>
        </form>
        <Preloader v-else />
  </div>
</template>
<script>
const VideoHelp = () => import('@/components/components/VideoHelp');
import Filemanager from '@/components/App/Filemanager/Filemanager';
import Address from './components/address';
import Sites from './components/sites';
import Phones from './components/phones';
import Keywords from './components/keywords';
import Category from './components/category';
import inputInfoPopover from "@/components/components/default/inputInfoPopover";
export default {
    name: 'OrganisationEdit',
    components: {
        VideoHelp,
        Filemanager,
        Address,
        Sites,
        Phones,
        Keywords,
        Category,
        inputInfoPopover
    },
    data() {
        return {
            maxLength: 2000,
            preloader: true,
            images: [],
            category_list: null,
            old_links: [],
            form: {
                name: null,
                description: null,
                image: null,
                published: 1,
                fix_top_search: 0,
                url: null,
                url_facebook: null,
                url_instagram: null,
                url_ok: null,
                url_twitter: null,
                url_vk: null,
                url_youtube: null,
                vk_video_url: null,
                categories: [],
                images: [],
                addresses: [{
                    address: null,
                    working_days: null,
                    latitude: null,
                    longitude: null,
                }],
                sites: [{
                    tag: null,
                    url: null
                }],
                telephones: [{
                    number: null,
                    name: null,
                    sort: 0
                }],
                keywords: []
            }
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        async convertOldLinks() {
            await this.old_links.map(elem => {
                let item = {
                    tag: null,
                    url: elem
                }
                this.form.sites.push(item);
            });
            this.old_links = [];

            this.form.sites = this.form.sites.filter(e => e.url);
        },
        onlyPhat(file) {
            return decodeURI((new URL(file).pathname));
        },
        api() {
            this.images = [];
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + '/Organisation/' + this.$route.params.id;
            this.$http.get(url).then((response) => {
                if(response.data.url)
                    this.old_links.push(response.data.url);
                if(response.data.url_facebook)
                    this.old_links.push(response.data.url_facebook);
                if(response.data.url_instagram)
                    this.old_links.push(response.data.url_instagram);
                if(response.data.url_ok)
                    this.old_links.push(response.data.url_ok);
                if(response.data.url_twitter)
                    this.old_links.push(response.data.url_twitter);
                if(response.data.url_vk)
                    this.old_links.push(response.data.url_vk);
                console.log(response.data.addresses);

                this.form = {
                    name: response.data.name,
                    description: response.data.description ? response.data.description : null,
                    published: response.data.published ? response.data.published : null,
                    fix_top_search: response.data.fix_top_search ? response.data.fix_top_search : 0,
                    url_youtube: response.data.url_youtube ? response.data.url_youtube : null,
                    vk_video_url: response.data.vk_video_url ? response.data.vk_video_url : null,
                    categories: this.get_cat(response.data.categories),
                    addresses: response.data.addresses.map(el => {
                        return {...el, 
                            latitude: parseFloat(el.latitude).toFixed(6),
                            longitude: parseFloat(el.longitude).toFixed(6)
                        }
                    }),
                    image: null,
                    sites: response.data.sites,
                    telephones: response.data.telephones,
                    keywords: response.data.keywords,
                    url: null,
                    url_facebook: null,
                    url_instagram: null,
                    url_ok: null,
                    url_twitter: null,
                    url_vk: null,
                }
                console.log(this.form.addresses);
                if (response.data.sites && response.data.sites.length) {
                    this.form.sites = response.data.sites;
                } else {
                    this.form.sites = [{
                        tag: null,
                        url: null
                    }]
                }

                if (response.data.telephones && response.data.telephones.length) {
                    this.form.telephones = response.data.telephones;
                } else {
                    this.form.telephones = [{
                        number: null,
                        name: null,
                        sort: 0
                    }]
                }

                if (response.data.addresses && response.data.addresses.length) {
                    this.form.addresses = response.data.addresses;
                } else {
                    this.form.addresses = [{
                        address: null,
                        working_days: null,
                        latitude: null,
                        longitude: null,
                    }]
                }

                if (response.data.images && response.data.images.length) {
                    this.images = response.data.images.map(elem => {
                        return this.onlyPhat(elem.image);
                    })
                }

                // if (response.data.image && !this.images.length) {
                //     this.images.unshift(this.onlyPhat(response.data.image));
                // }
            }).catch(() => {
                this.$toast.default('Ошибка получения данных об организации');
                this.$router.push({ name: 'OrganisationList' });

            }).finally(() => {
                this.preloader = true;
            });
        },
        get_cat(array) {
            return array.map(item => {
                return item.id
            })
        },
        async send() {
            if (this.images && this.images.length) {
                this.form.images = await this.images.map((elem, index) => {
                    let data = {
                        image: this.$config.api_filemanager + elem,
                        sort_order: index + 1
                    }
                    return data;
                this.form.image = null;
                });
            } else {
                this.form.image = null;
                this.form.images = [];
            }

            if(!this.form.categories.length) return this.$toast.error('Выберите категорию');
            console.log(this.form);
            let form = this.form;
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + '/Organisation/' + this.$route.params.id;

            form.addresses.forEach((item, key) => {
                if (!item.address || !item.latitude || !item.longitude) {
                    form.addresses.splice(key, 1);
                    this.$toast.default('Заполните данные адреса организации');
                    return;
                }
            })

            form.sites.forEach((item, key) => {
                if (!item.url) {
                    form.sites.splice(key, 1)
                }
            })

            form.telephones.forEach((item, key) => {
                if (!item.number) {
                    form.telephones.splice(key, 1)
                }
            })

            form.keywords.forEach((item, key) => {
                if (!item.keyword) {
                    form.keywords.splice(key, 1)
                }
            })

            this.$http.put(url, form).then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    if(response.data && response.data.id) {
                        this.$indexNow(this.$config.site_info.links.organisation, response.data.id);
                    }
                    this.$toast.default('Организация успешно изменена');
                    this.api();

                }
            })
        },
    }
}
</script>
<style scoped>
.btn_primary {
    width: 100%;
    margin-top: 20px;
}

small {
    font-weight: 400;
    color: #999;
}

.category_item {
    background: #eee;
}

.warn {
    background: rgba(var(--main-danger-color), .1);
    padding: 10px;
    border-radius: 12px;
    margin-top: 30px;
}

.warn strong {
    display: block;
}


.warn_old_link {
    display: block;
    margin: 7px 0;
}

.warn button {
    margin-top: 20px;
}
</style>