<template>
  <div>
    <div class="default_box info_form_box">
      <label class="default_label">Поиск в Яндекс</label>
      <div
        v-if="type !== 'site' && type !== 'intext'"
        class="default_form"
      >
        <input
          id="link_rss"
          v-model="input"
          type="text"
          placeholder="Запрос или текст"
        >
      </div>
      <div class="period_box">
        <select v-model="type">
          <option value="site">
            Страниц в индексе
          </option>
          <option value="double">
            Поиск дублей страниц по запросу на других сайтах
          </option>
          <option value="intext">
            Поиск упоминаний сайта Okaygorod.com на других сайтах
          </option>
        </select>
        <select v-model="period">
          <option :value="null">
            За всё время
          </option>
          <option :value="77">
            За сутки
          </option>
          <option :value="1">
            За 2 недели
          </option>
          <option :value="2">
            За месяц
          </option>
        </select>
      </div>
      <a
        :href="`${link}`"
        target="_blank"
        class="yandex_link"
        v-html="`${link}`"
      />
    </div>
  </div>
</template>
<script>
export default {
    name: "YandexTools",
    components: {},
    data() {
        return {
            preloader: false,
            period: null,
            input: null,
            type: "site"

        }
    },
    computed: {
        link: function() {
            let link = "https://yandex.ru/search/?text=";

            if (this.type == "site") {
                link += "site:" + this.$config.site_info.url + this.$city.index
            } else if (this.type == "double") {
                link += this.input + " ~~ site:" + this.$config.site_info.url + this.$city.index
            } else if (this.type == "intext") {
                link += "intext:" + "okaygorod.com ~~ site:" + this.$config.site_info.url
            }

            if (this.period) {
                link += "&within=" + this.period
            }

            return link
        },
    },
    mounted() {

    },
    methods: {
    }
}
</script>
<style scoped>
.period_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.period_box select {
    width: 100%;
    margin-top: 20px;
}

.yandex_link {
    font-size: 12px;
    margin-top: 30px;
}
</style>