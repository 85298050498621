<template>
  <div
    v-if="!$user"
    class="auth_container"
  >
    <div
      v-if="preloader"
      class="auth_box default_form"
    >
      <form
        @submit.stop.prevent
        @submit="api"
      >
        <div class="title">
          Восстановление пароля
        </div>
        <div class="group">
          <input
            v-model="form.email"
            type="email"
            minlength="4"
            required
          >
          <span class="bar" />
          <label>E-mail</label>
        </div>
        <div>
          <button
            class="btn_primary"
            type="submit"
          >
            Сбросить пароль
          </button>

          <router-link
            :to="{name: 'Login'}"
            tag="div"
            class="default_form_footer"
          >
            Войти
          </router-link>
        </div>
      </form>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
export default {
    name: "Etalon",
    components: {
    },
    data() {
        return {
            preloader: true,
            form: {
                email: null,
            }
        }
    },
    mounted() {

    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_url + "User.passwordReset";
            this.$http.post(url, this.form).then(() => {                
                    this.$router.push({ name: "Login" });
                    this.$toast.default("Ссылка на сброс пароля отправлена на Ваш почтовый ящик");                
            }).finally(() => {
                this.preloader = true;
            })
        },
    }
}
</script>

<style scoped>
.title {    
    font-weight: bold;
    text-align: center;
    display: block;
    margin-bottom: 50px;
}

.auth_container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_box {
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    min-width: 300px;
    box-shadow: 0 2px 8px rgb(18 17 36 / 4%);
}

.auth_img_box {
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.auth_logo {
    max-height: 40px;
}

.default_form_footer {
    margin-top: 20px;
    color: #888;
    transition: .2s;
    cursor: pointer;
}

.default_form_footer:hover {
    color: rgba(var(--main-color), 1);
    transition: .2s;
}

.btn_primary {
    width: 100%;
}
</style>
