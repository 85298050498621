<template>
  <div>
    <div v-if="preloader">
      <div class="header_button_box">
        <div
          v-if="$checkUser('filters', 'create')"
          class="header_button_item"
        >
          <router-link :to="{name: 'FiltersAdd'}">
            <button class="header_button">
              <div class="header_icons icon_add" /><span>Создать фильтр</span>
            </button>
          </router-link>
        </div>
      </div>
      <div
        v-if="list && list.length"
        class="default_box table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-start "
                @click="sort('id')"
              >
                <span
                  class="table_header_link"
                  title="Сортировать по ID"
                >
                  <div>ID</div>
                  <div class="info_icon" />
                </span>
              </th>
              <th
                scope="col"
                class="text-start "
                @click="sort('category_id')"
              >
                <span
                  class="table_header_link"
                  title="Сортировать по разделу"
                >
                  <div>Раздел</div>
                  <div class="info_icon" />
                </span>
              </th>
              <th
                scope="col"
                class="text-start "
                @click="sort('title')"
              >
                <span
                  class="table_header_link"
                  title="Сортировать по названию"
                >
                  <div>Название</div>
                  <div class="info_icon" />
                </span>
              </th>
              <th
                v-if="$checkUser('filters', 'edit')"
                scope="col"
                class="text-end "
                @click="sort('published')"
              >
                <span
                  class="table_header_link flex-end"
                  title="Сортировать по статусу"
                >
                  <div>Опубликовано</div>
                  <div class="info_icon" />
                </span>
              </th>
              <th
                v-if="$checkUser('filters', 'edit') || $checkUser('filters', 'delete')"
                scope="col"
                class="text-end"
              >
                Действия
              </th>
            </tr>
          </thead>
          <transition-group
            name="flip-list"
            tag="tbody"
          >
            <tr
              v-for="(item,index) in list"
              :key="item.id"
            >
              <th
                scope="row"
                class="text-start"
                v-html="item.id"
              />
              <td
                class="text-start"
                v-html="category[item.category_id].name"
              />
              <td
                class="text-start"
                v-html="$cutText(item.title, 80)"
              />
              <td
                v-if="$checkUser('filters', 'edit')"
                class="text-end"
              >
                <input
                  id="s2"
                  type="checkbox"
                  class="switch"
                  :checked="item.published"
                  :disabled="disabled_status"
                  @change="api_status(item, index)"
                >
              </td>
              <td
                v-if="$checkUser('filters', 'edit') || $checkUser('filters', 'delete')"
                class="text-end"
              >
                <div class="table_button_box">
                  <router-link
                    v-if="$checkUser('filters', 'edit')"
                    :to="{name: 'FiltersEdit', params: {id: item.id}}"
                    tag="div"
                    class="table_button_item"
                    :title="`Редактировать фильтр`"
                  >
                    <button class="table_button table_icon_edit">
                      <div class="table_icons icon_pen" />
                    </button>
                  </router-link>
                  <div
                    v-if="$checkUser('filters', 'delete')"
                    class="table_button_item"
                    :title="`Удалить фильтр`"
                  >
                    <button
                      class="table_button table_icon_delete"
                      @click="del(item.id, item.title, index)"
                    >
                      <div class="table_icons icon_trash" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </transition-group>
        </table>
        <div v-if="list && last_page && page < last_page">
          <div
            v-if="button_preloader"
            class="news_button_block"
          >
            <button
              class="btn_primary"
              @click="api_next"
            >
              Показать ещё
            </button>
          </div>
          <Preloader v-else />
        </div>
      </div>
      <div
        v-else
        class="default_box"
      >
        В этом городе нет кнопок
      </div>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
export default {
    name: "FiltersList",
    components: {},
    data() {
        return {
            category: {
                0: {
                    name: "Акции"
                },
                1: {
                    name: "Афиша"
                }
            },
            disabled_status: false,
            preloader: false,
            button_preloader: true,
            list: null,
            page: 1,
            last_page: null,
            sortArray: true
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/filters";
            this.$http.get(url, {
                params: {
                    per_page: 25,
                }
            }).then((response) => {
                this.list = response.data.data;
                this.last_page = response.data.last_page;
                this.sort();
            }).finally(() => {
                this.preloader = true;
            });
        },
        api_next() {
            this.button_preloader = false;
            let url = this.$config.api_management + this.$city.index + "/filters";
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page,
                    per_page: 25,
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.data);
            }).finally(() => {
                this.button_preloader = true;
            })
        },
        api_status(array, index) {
            array.published = !array.published;
            let data = {
                published: array.published
            }
            this.disabled_status = true;
            let url = this.$config.api_management + this.$city.index + "/filters/" + array.id;
            this.$http.put(url, data).then((response) => {
                this.list[index].published = response.data.published;
                this.$toast.default("Статус фильтра успешно изменён");
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        del(id, name, index) {
            if (confirm("Вы уверены, что хотите удалить этот фильтр?\n\n\"" + name + "\"\n\nЭто действие нельзя отменить!")) {
                let url = this.$config.api_management + this.$city.index + "/filters/" + id;
                this.$http.delete(url).then((response) => {
                    if (response && response.status === 204) {
                        this.list.splice(index, 1);
                        this.$toast.default("Фильтр \"" + name + "\", успешно удалён");
                    } else {
                        this.$toast.error("Произошла ошибка при удалении");
                    }
                })
            }
        },
        sort(data = "id") {
            this.list = Object.values(this.list).sort((a, b) => {
                if (this.sortArray) {
                    if (this.$assignValue(a[data]) < this.$assignValue(b[data])) {
                        return -1;
                    }
                    if (this.$assignValue(a[data]) > this.$assignValue(b[data])) {
                        return 1;
                    }
                } else {

                    if (this.$assignValue(b[data]) < this.$assignValue(a[data])) {
                        return -1;
                    }
                    if (this.$assignValue(b[data]) > this.$assignValue(a[data])) {
                        return 1;
                    }

                }
                return 0;
            });

            this.sortArray = !this.sortArray;
        },
    }
}
</script>
<style scoped>
.flip-list-move {
    transition: transform 1s;
}

.btn_primary {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 15px;
}
</style>