<template>
  <b-modal
    :id="`modal_service_edit_${index}`"
    title="Редактировать услугу"
    scrollable
    centered
    hide-footer
  >
    <form
      v-if="index >= 0"
      @submit.stop.prevent
    >
      <div class="default_form">
        <div class="group">
          <input
            v-model="$parent.$data.form.custom_fields[index].name"
            type="text"
            placeholder=" "
            :required="$parent.$data.form.custom_fields[index].price ? true : false"
          >
          <span class="bar" />
          <label>Название услуги</label>
        </div>
        <div class="group">
          <textarea
            v-model="$parent.$data.form.custom_fields[index].description"
            cols="30"
            rows="10"
            placeholder="Описание услуги"
          />
          <span class="bar" />
        </div>
        <div class="group">
          <input
            v-model="$parent.$data.form.custom_fields[index].price"
            type="text"
            placeholder=" "
            :required="$parent.$data.form.custom_fields[index].name ? true : false"
          >
          <span class="bar" />
          <label>Стоимость услуги</label>
        </div>
        <button
          class="btn_primary"
          type="submit"
          @click="save_servise"
        >
          Сохранить
        </button>
      </div>
    </form>
    <Preloader v-else />
  </b-modal>
</template>
<script>
export default {
    name: "ModalService",
    components: {},
    props: {
        index: {
            type: Number
        },
    },
    data() {
        return {
            preloader: true,
        }
    },
    mounted() {
        console.log(this.index)
    },
    methods: {
        save_servise() {
            if(this.$parent.$data.form.custom_fields[this.index].name || this.$parent.$data.form.custom_fields[this.index].price) {
                this.$bvModal.hide(`modal_service_edit_${this.index}`)
            } else {
                return
            }
        }
    }
}
</script>
<style scoped>
#modal_service_edit .btn_primary,
#modal_service_edit .btn_black {
    width: 100%;
}
</style>