<template>
    <div>
        <div v-if="preloader">
            <div class="header_button_box">
                <div class="header_button_item">
                    <router-link v-if="$checkUser('newsCategory', 'view')" :to="{name: 'NewsCategoryList'}">
                        <button class="header_button">
                            <div class="header_icons icon_list" /><span>Разделы новостей</span>
                        </button>
                    </router-link>
                </div>

                <div v-if="$checkUser('news', 'create')" class="header_button_item">
                    <router-link :to="{name: 'NewsCreate'}">
                        <button class="header_button">
                            <div class="header_icons icon_add" /><span>Создать новость</span>
                        </button>
                    </router-link>
                </div>
            </div>
            <SearchPlagin :search-type="`News`" />
            <div v-if="list && list.length" class="default_box table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="text-start">
                                ID
                            </th>
                            <th scope="col" class="text-start">
                                Заголовок
                            </th>
                            <th scope="col" class="text-end">
                                Раздел
                            </th>
                            <th scope="col" class="text-end">
                                Дата
                            </th>
                            <th scope="col" class="text-end">
                                Тип новости
                            </th>
                            <th v-if="$checkUser('news', 'edit')" scope="col" class="text-end">
                                Опубликовано
                            </th>
                            <th v-if="$checkUser('news', 'edit') || $checkUser('news', 'delete')" scope="col" class="text-end">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(news,index) in list" :key="index">
                            <th scope="row" class="text-start">
                                <a :href="'https://okaygorod.com/' + $city.index + '/news/'+news.id" target="_blank" :title="'Открыть ссылку https://okaygorod.com/' + $city.index + '/news/'+news.id" class="link">{{ news.id }}</a>
                            </th>
                            <td :id="'popover-news-'+ news.id" class="text-start">
                                {{ $cutText(news.data.title, 80) }}
                            </td>
                            <td class="text-end">
                                <div v-if="news.category">
                                    <a :href="'https://okaygorod.com/' + $city.index + '/interesting/list/'+ news.category.id" target="_blank" :title="'Открыть ссылку https://okaygorod.com/' + $city.index + '/news/' + news.category.id" class="link">{{ news.category.title }}</a>
                                </div>
                            </td>
                            <td class="text-end">
                                {{ $moment(news.created_at).format('DD.MM.YYYY') }}
                            </td>
                            <td class="text-end">
                                <div v-if="news.type === 2">
                                    Закреплённая
                                </div>
                            </td>
                            <td v-if="$checkUser('news', 'edit')" class="text-end">
                                <input id="s2" type="checkbox" class="switch" :checked="news.published" :disabled="disabled_status" @change="api_status(news, index)">
                            </td>
                            <td v-if="$checkUser('news', 'edit') || $checkUser('news', 'delete')" class="text-end">
                                <div class="table_button_box">
                                    <router-link v-if="$checkUser('news', 'edit')" :to="{name: 'NewsEdit', params: {id: news.id}}" class="table_button_item" :title="`Редактировать новость`">
                                        <button class="table_button table_icon_edit">
                                            <div class="table_icons icon_pen" />
                                        </button>
                                    </router-link>
                                    <div v-if="$checkUser('news', 'delete')" class="table_button_item" :title="`Удалить новость`">
                                        <button class="table_button table_icon_delete" @click="del(news.id, news.data.title, index)">
                                            <div class="table_icons icon_trash" />
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <b-popover :target="'popover-news-'+ news.id" triggers="hover" placement="top">
                                <img v-if="news.images && news.images.length" :src="news.images[0].image" alt="news_image" class="img-fluid">
                                <div class="popover_news_title">
                                    {{ news.data.title }}
                                </div>
                                <div class="borders_main" />
                                <div class="popover_news_description" v-html="$cutText(news.data.text, 300)" />
                            </b-popover>
                        </tr>
                    </tbody>
                </table>
                <div v-if="list && last_page && page < last_page">
                    <div v-if="button_preloader" class="news_button_block">
                        <button class="btn_primary" @click="api_next">
                            Показать ещё
                        </button>
                    </div>
                    <Preloader v-else />
                </div>
            </div>
            <div v-else class="default_box">
                В этом городе нет новостей
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import SearchPlagin from "@/components/components/Search/SearchPlagin";
export default {
    name: "NewsList",
    components: {
        SearchPlagin
    },
    data() {
        return {
            disabled_status: false,
            preloader: false,
            button_preloader: true,
            page: 1,
            list: null,
            last_page: null
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/news";
            this.$http.get(url, {
                params: {
                    per_page: 50,
                }
            }).then((response) => {
                this.list = response.data.data;
                this.last_page = response.data.last_page;
            }).finally(() => {
                this.preloader = true;
            });
        },
        api_next() {
            this.button_preloader = false;
            let url = this.$config.api_management + this.$city.index + "/news";
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page,
                    per_page: 50,
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.data);
            }).finally(() => {
                this.button_preloader = true;
            })
        },
        api_status(array, index) {
            array.published = !array.published;
            let data = {
                published: array.published,
                category_id: array.category_id
            }
            this.disabled_status = true;
            let url = this.$config.api_management + this.$city.index + "/news/" + array.id;
            this.$http.put(url, data).then((response) => {
                this.list[index].published = response.data.published;
                this.$toast.default("Статус новости успешно изменён");
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        del(id, name, index) {
            if (confirm("Вы уверены, что хотите удалить новость?\n\n\"" + name + "\"\n\nЭто действие нельзя отменить!")) {
                let url = this.$config.api_management + this.$city.index + "/news/" + id;
                this.$http.delete(url).then((response) => {
                    if (response && response.status === 204) {
                        this.list.splice(index, 1);
                        this.$toast.default("Новость \"" + name + "\", успешно удалена");
                    } else {
                        this.$toast.error("Произошла ошибка при удалении");
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.btn_primary {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 15px;
}

.header_button_box {
    justify-content: space-between;
}
</style>