<template>
  <div
    v-if="!$user"
    class="auth_container"
  >
    <div
      v-if="preloader"
      class="auth_box default_form"
    >
      <form
        @submit.stop.prevent
        @submit="api"
      >
        <Logo />
        <div class="group">
          <input
            v-model="form.email"
            type="email"
            autocomplete="off"
            minlength="4"
            required
            @focus="readonly = false"
          >
          <span class="bar" />
          <label>Логин</label>
        </div>
        <div class="group">
          <input
            v-model="form.pass"
            type="password"
            autocomplete="off"
            :readonly="readonly"
            minlength="4"
            required
            @focus="readonly = false"
          >
          <span class="bar" />
          <label>Пароль</label>
        </div>
        <div>
          <button
            class="btn_primary"
            type="submit"
          >
            Войти
          </button>

          <router-link
            :to="{name: 'ResetPassword'}"
            tag="div"
            class="default_form_footer"
          >
            Забыли пароль?
          </router-link>
        </div>
      </form>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
import Logo from "@/components/components/Logo";
export default {
    name: "Login",
    components: {
        Logo
    },
    data() {
        return {
            preloader: true,
            readonly: true,
            form: {
                email: null,
                pass: null
            }
        }
    },
    mounted() {
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_url + "User.emailLogin";
            this.$http.post(url, this.form).then((response) => {
                if (response.data.response.item.roles.city_all || response.data.response.item.roles.super_admin || response.data.response.item.roles.city_list.length > 0) {
                    this.$user = response.data.response.item;
                    this.$router.push({ name: "Home" });
                    this.$toast.default("С возвращением, " + this.$user.name);
                } else {
                    this.$toast.error("У Вас нет прав на упраление городами");

                }
            }).finally(() => {
                this.preloader = true;
            })
        },
    }
}
</script>
<style scoped>
.main_logo {
    margin-bottom: 30px;
}

.auth_container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_box {
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    min-width: 300px;
    box-shadow: 0 2px 8px rgb(18 17 36 / 4%);
}

.auth_img_box {
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.auth_logo {
    max-height: 40px;
}

.default_form_footer {
    margin-top: 20px;
    color: #888;
    transition: .2s;
    cursor: pointer;
}

.default_form_footer:hover {
    color: rgba(var(--main-color), 1);
    transition: .2s;
}

.btn_primary {
    width: 100%;
}
</style>