<template>
    <div>
        <div v-if="preloader" class="default_box">
            <input
                v-model.trim="input"
                type="text"
                placeholder="Поиск"
                name="list"
                class="default_input_search"
                list="searchList"
            >
            <!-- <datalist id="searchList" style="width: 500px">
                <option v-for="(item, index) in list" :key="index" :value="item.title"></option>
            </datalist> -->
            <div
                v-if="
                    inputSearch &&
                        inputSearch.length >= 2 &&
                        list &&
                        list.length
                "
                class="table-responsive"
            >
                <label for="">Результаты поиска</label>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="text-start">
                                ID
                            </th>
                            <th scope="col" class="text-start">
                                Заголовок
                            </th>
                            <th scope="col" class="text-end">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in list" :key="index">
                            <th scope="row" class="text-start">
                                <a
                                    :href="
                                        'https://okaygorod.com/' +
                                            $city.index +
                                            '/' +
                                            info[searchType].link +
                                            '/' +
                                            item.id
                                    "
                                    target="_blank"
                                    :title="
                                        'Открыть ссылку https://okaygorod.com/' +
                                            $city.index +
                                            '/' +
                                            info[searchType].link +
                                            '/' +
                                            item.id
                                    "
                                    class="link"
                                    >{{ item.id }}</a>
                            </th>
                            <td class="text-start">
                                {{ $cutText(item.title, 80) }}
                            </td>
                            <td class="text-end">
                                <div class="table_button_box">
                                    <router-link
                                        :to="{
                                            name: info[searchType].route,
                                            params: { id: item.id },
                                        }"
                                        tag="div"
                                        class="table_button_item"
                                        :title="`Редактировать`"
                                    >
                                        <button
                                            class="table_button table_icon_edit"
                                        >
                                            <div class="table_icons icon_pen" />
                                        </button>
                                    </router-link>
                                    <div
                                        class="table_button_item"
                                        :title="`Удалить`"
                                    >
                                        <button
                                            class="table_button table_icon_delete"
                                            @click="
                                                $parent.del(
                                                    item.id,
                                                    item.title,
                                                    index
                                                )
                                            "
                                        >
                                            <div
                                                class="table_icons icon_trash"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
export default {
    name: "SearchPlagin",
    components: {},
    props: {
        searchType: {
            type: String,
        },
    },
    data() {
        return {
            preloader: true,
            timer: 0,
            inputSearch: null,
            info: {
                News: {
                    link: "news",
                    route: "NewsEdit",
                },
                Poster: {
                    link: "poster",
                    route: "PosterEdit",
                },
                Shares: {
                    link: "shares",
                    route: "ShareEdit",
                },
                Organizations: {
                    link: "organization",
                    route: "OrganisationEdit",
                },
            },
            list: null,
        };
    },
    computed: {
        input: {
            get() {
                return this.inputSearch;
            },
            set(val) {
                this.inputSearch = val;

                if (this.timer) {
                    clearTimeout(this.timer);
                }

                if (this.inputSearch && this.inputSearch.length >= 2) {
                    this.timer = setTimeout(() => {
                        this.search_api();
                    }, 1000);
                } else {
                    this.list = null;
                }
            },
        },
    },
    mounted() {},
    methods: {
        search_api() {
            this.preloader = false;
            this.list = null;
            let url =
                this.$config.api_management + this.$city.index + "/search";
            let form = {
                query: this.inputSearch,
                type: this.searchType ? this.searchType : "All",
                limit: 10,
            };
            this.$http
                .post(url, form)
                .then((response) => {
                    if (response && response.data && response.data.length) {
                        this.list = response.data;
                    } else {
                        this.$toast.error(
                            "По Вашему запросу ничего не найдено"
                        );
                    }
                })
                .finally(() => {
                    this.preloader = true;
                });
        },
    },
};
</script>
<style scoped>
label {
    font-weight: bold;
    margin: 20px 0;
    text-align: left;
    display: block;
}

.default_input_search {
    width: 100%;
}
</style>
