<template>
    <div>
        <form v-if="preloader" @submit.stop.prevent @submit="send">
            <b-row class="justify-content-between">
                <b-col xl="6">
                    <div class="default_box default_form">
                        <div class="group">
                            <input v-model="form.name" type="text" placeholder=" ">
                            <span class="bar" />
                            <label>Заголовок</label>
                        </div>
                        <div class="group">
                            <textarea v-model="form.text" cols="30" rows="10" placeholder="Описание"></textarea>
                            <span class="bar" />
                        </div>
                        <div class="group">
                            <input v-model="form.link" type="text" placeholder=" ">
                            <span class="bar" />
                            <label>Ссылка на видео YouTube</label>
                        </div>
                        <div class="group">
                            <input v-model="form.vk_video_url" type="text" placeholder=" " data-info>
                            <span class="bar" />
                            <inputInfoPopover value="vk" />
                            <label>Ссылка на видео ВКонтакте</label>
                        </div>
                        <button v-if="$checkUser('help', 'edit')" class="btn_primary" type="submit">
                            Сохранить
                        </button>
                    </div>
                </b-col>
                <b-col xl="4" />
            </b-row>
        </form>
        <Preloader v-else />
    </div>
</template>
<script>
export default {
    name: "Help",
    components: {},
    data() {
        return {
            preloader: false,
            form: {
                id: 1,
                name: null,
                text: null,
                link: null,
                vk_video_url: null
            },
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/help";
            this.$http.get(url).then((response) => {
                this.form = {
                    id: response.data[0].id,
                    name: response.data[0].name,
                    text: response.data[0].text,
                    link: response.data[0].link,
                    vk_video_url: response.data[0].vk_video_url,
                }
            }).finally(() => {
                this.preloader = true;
            });
        },
        send() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/help/" + this.form.id;
            this.$http.put(url, this.form).then(() => {
                this.api();
            }).finally(() => {
                this.preloader = true;
            });
        },
    }
}
</script>
<style scoped>
</style>