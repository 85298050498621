<template>
    <tr>
        <th>
            <div class="sort_icon" />
        </th>
        <th scope="row" class="text-start">
            <a :href="'https://okaygorod.com/' + $city.index + '/organisation/' + item.id" target="_blank" :title="'Открыть ссылку https://okaygorod.com/' + $city.index + '/organisation/' + item.id" class="link">{{ item.id }}</a>
        </th>
        <td scope="row" class="text-start" v-html="item.name" />
        <td class="text-end">
            <div class="sort_box">
                <the-mask ref="input_sort" mask="###" v-model="$parent.$parent.$data.list[index].pivot.sort" class="sort_input" :readonly="$parent.$parent.$data.disabled_status" />
                <button type="button" :disabled="$parent.$parent.$data.disabled_status" @click="$parent.$parent.sortList(index, $parent.$parent.$data.list[index])">
                    <div class="icon_checkmark" title="Сохранить" />
                </button>
            </div>
        </td>
        <td v-if="$checkUser('organisation', 'edit')" class="text-end">
            <div class="table_button_box">
                <router-link v-if="$checkUser('organisation', 'edit')" :to="{name: 'OrganisationEdit', params: {id: item.id}}" class="table_button_item" :title="`Редактировать организацию`">
                    <button class="table_button table_icon_edit">
                        <div class="table_icons icon_pen" />
                    </button>
                </router-link>
            </div>
        </td>
    </tr>
</template>
<script>
export default {
    name: "Item",
    props: {
        item: {
            type: Object
        },
        index: {
            type: Number
        }
    },
    data() {
        return {
            disabled_status: false
        }
    },
    mounted() {},
    methods: {

    }


}
</script>
<style scoped>
.sort_box {
    border: 1px dashed rgba(var(--main-color), 1.0);
    border-radius: 80px;
    width: 85px;
    display: block;
    position: relative;
    margin-left: auto;
}

.sort_input {
    border: none;
    outline: none;
    border-radius: 80px;
    padding: 0 10px;
    width: 100%;
}

.icon_checkmark {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: rgba(var(--main-success-color), 1);
    display: block;
    mask-image: url('/image/icons/default/checkmark.svg');

}

.icon_checkmark:hover {
    background-color: rgba(var(--main-color-transition), 1.0);
    cursor: pointer;
}

.sort_icon {
    width: 20px;
    height: 20px;
    background-color: rgba(var(--main-color-transition), 1.0);
    display: block;
    transition: .2s;
    mask-image: url('/image/icons/default/sort.svg');
}
</style>