<template>
    <div>
        <form v-if="preloader" @submit.stop.prevent @submit="send">
            <b-row class="justify-content-between">
                <b-col xl="6">
                    <div class="default_box default_form">
                        <div class="group">
                            <input v-model="form.title" type="text" placeholder=" ">
                            <span class="bar" />
                            <label>Заголовок</label>
                        </div>
                        <div class="group">
                            <textarea v-model="form.text" cols="30" rows="10" placeholder="Описание"></textarea>
                            <span class="bar" />
                        </div>
                        <div class="group">
                            <input v-model="form.url" type="text" placeholder=" ">
                            <span class="bar" />
                            <label>Ссылка на видео YouTube</label>
                        </div>
                        <div class="group">
                            <input v-model="form.vk_video_url" type="text" placeholder=" " data-info>
                            <span class="bar" />
                            <inputInfoPopover value="vk" />
                            <label>Ссылка на видео ВКонтакте</label>
                        </div>
                        <button v-if="$checkUser('advertising', 'edit')" class="btn_primary" type="submit">
                            Сохранить
                        </button>
                    </div>
                </b-col>
                <b-col xl="6">
                    <div class="default_box info_form_box">
                        <div class="default_label">
                            <span>Услуги</span>
                            <button v-if="$checkUser('advertising', 'edit')" v-b-modal.modal_service class="btn_black" type="button">
                                <div class="header_icons icon_add" />
                            </button>
                            <Modal />
                        </div>
                        <div v-if="form.custom_fields && form.custom_fields.length">
                            <div v-for="(item,index) in form.custom_fields" :key="index">
                                <div class="services_item">
                                    <div class="services_item_description_box">
                                        <span v-html="'• ' + item.name" />
                                        <span v-html="item.price" />
                                    </div>
                                    <div class="table_button_box">
                                        <div v-if="$checkUser('advertising', 'edit')" v-b-modal="`modal_service_edit_${index}`" class="table_button_item" :title="`Редактировать услугу`">
                                            <button class="table_button table_icon_edit" type="button">
                                                <div class="table_icons icon_pen" />
                                            </button>
                                        </div>
                                        <div v-if="$checkUser('advertising', 'edit')" class="table_button_item" :title="`Удалить новость`" @click="form.custom_fields.splice(index,1)">
                                            <button class="table_button table_icon_delete" type="button">
                                                <div class="table_icons icon_trash" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <ModalEdit :index="index" />
                            </div>
                        </div>
                        <div v-else>
                            Вы ещё не добавили ни одной услуги
                        </div>
                    </div>
                </b-col>
            </b-row>
        </form>
        <Preloader v-else />
    </div>
</template>
<script>
import Modal from "./components/Modal";
import ModalEdit from "./components/ModalEdit";
export default {
    name: "Etalon",
    components: {
        Modal,
        ModalEdit
    },
    data() {
        return {
            preloader: false,
            form: {
                title: null,
                text: null,
                url: null,
                vk_video_url: null,
                custom_fields: []
            },
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/advertising/1";
            this.$http.get(url).then((response) => {
                this.form = {
                    title: response.data.title,
                    text: response.data.text,
                    url: response.data.url,
                    vk_video_url: response.data.vk_video_url,
                    custom_fields: response.data.custom_fields ? response.data.custom_fields : []
                }
            }).finally(() => {
                this.preloader = true;
            });
        },
        send() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/advertising/1";
            this.$http.put(url, this.form).then(async () => {
                await this.api();
                this.$toast.default("Данные успешно обновлены");
            }).finally(() => {
                this.preloader = true;
            });
        },
    }
}
</script>
<style scoped>
.services_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.services_item_description_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 20px;
    position: relative;
    z-index: 1;
}

.services_item_description_box:before {
    content: '';
    position: absolute;
    top: -50%;
    left: 0;
    height: 100%;
    width: 100%;
    border-bottom: 1px dotted rgba(0, 0, 0, .1);
    z-index: -1;
}

.services_item_description_box span {
    background: #fff;
}

.services_item_description_box span:first-child {
    padding: 0 10px 0 0;
}

.services_item_description_box span:last-child {
    padding: 0 0 0 10px;
}

.header_icons {
    margin: 0;
}

.default_label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>