<template>
    <div class="moderation_category_container">
        <span class="category_label">Фильтры</span>
        <div v-for="(item,index) in filters" :key="index" class="category_box">
            <input v-model="$parent.$data.filter" type="checkbox" :value="item.index" @change="$parent.api()">
            <span v-html="item.name" />
        </div>
    </div>
</template>
<script>
export default {
    name: "ModerationCategory",
    components: {},
    data() {
        return {
            preloader: false,
            filters: {
                news: {
                    index: "news",
                    name: "Новости"
                },
                organizations: {
                    index: "organizations",
                    name: "Организации"
                },
                communication: {
                    index: "communication",
                    name: "Городская лента"
                },
                ads: {
                    index: "ads",
                    name: "Объявления"
                },
            }
        }
    },
    mounted() {

    },
    methods: {}
}
</script>
<style scoped>
@media (max-width: 767px) {
    .category_label {
        width: 100%;
        text-align: center;
    }

    .category_box:not(:last-child) {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) {
    .category_label {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px dashed rgba(0, 0, 0, 0.1);
    }

    .category_box:not(:last-child) {
        margin-right: 20px;
    }
}

.moderation_category_container {
    background: #fff;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.moderation_category_box {}

.category_label {
    color: #999;
    font-size: 14px;
}

.category_box {}

.category_box input[type="checkbox"] {
    margin-right: 10px;
}
</style>