<template>
    <div class="fastLink_item_container">
        <router-link v-for="(item, index) in filters" :key="index" :to="{name: item.to, params: { city: $city.index }}" tag="div" class="fastLink_item_box">
                <span class="icons_menu" :class="item.icon"></span>
                <span>{{ item.title }}</span>
        </router-link>
    </div>
</template>
<script>
export default {
    name: "FastLink",
    components: {},
    data() {
        return {
            preloader: false,
            data: {
                news: {
                    title: 'Создать новость',
                    icon: 'icon_news',
                    to: 'NewsCreate',
                    key: 'news'
                },
                poster: {
                    title: 'Создать афишу',
                    icon: 'icon_poster',
                    to: 'PosterAdd',
                    key: 'poster'
                },
                shares: {
                    title: 'Создать акцию',
                    icon: 'icon_discount',
                    to: 'ShareAdd',
                    key: 'shares'
                },
                organisation: {
                    title: 'Создать организацию',
                    icon: 'icon_org',
                    to: 'OrganisationAdd',
                    key: 'organisation'
                },
                celat: {
                    title: 'Создать рекламу',
                    icon: 'icon_megaphone',
                    to: 'CelatAdd',
                    key: 'adsBanners'
                },
            }
        }
    },
    mounted() {

    },
    methods: {},
    computed: {
        filters() {
            return Object.values(this.data).filter(elem => {
                if(this.$checkUser(elem.key, 'create')) return elem;
            })
        }
    }
}
</script>
<style scoped>
.fastLink_item_container {
    display: flex;
        align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.fastLink_item_box {
    background: #fff;
    font-size: 14px;
    padding: 10px;
    border-radius: 6px;
    font-family: 'Roboto' !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.fastLink_item_box:hover {
    cursor: pointer;
    background: rgba(var(--main-color), .03);
    transition: .2s;
}

.fastLink_item_box:hover .icons_menu {
    background-color: rgba(var(--main-color), 1.0);
}

.icons_menu {
    width: 50px;
    height: 50px;
    background-color: #999999;
    display: block;
    margin-bottom: 15px;
}

.icon_news {
    mask-image: url('/image/icons/menu/news.svg');
}

.icon_poster {
    mask-image: url('/image/icons/menu/poster.svg');
}

.icon_org {
    mask-image: url('/image/icons/menu/business.svg');
}

.icon_discount {
    mask-image: url('/image/icons/menu/discount.svg');
}

.icon_megaphone {
    mask-image: url('/image/icons/menu/megaphone.svg');
}

@media (min-width: 1201px) {
    .fastLink_item_container {
        justify-content: flex-start;
    }

    .fastLink_item_box {
        max-width: calc(20% - 10px);
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .fastLink_item_container {
        justify-content: space-around;
    }

    .fastLink_item_box {
        max-width: calc(25% - 10px);
        width: 100%;
    }
}

@media (max-width: 768px) {
    .fastLink_item_container {
        justify-content: space-around;
    }

    .fastLink_item_box {
        max-width: calc(33% - 10px);
        width: 100%;
    }
}

@media (max-width: 576px) {
    .fastLink_item_box {
        max-width: calc(50% - 10px);
        font-size: 12px;
    }
}
</style>