<template>
  <div>
    <div class="header_button_box">
      <div class="header_button_item">
        <button
          v-b-modal.permissions_modal
          class="header_button"
          type="button"
        >
          <div class="header_icons icon_add" /><span>Добавить администратора</span>
        </button>
      </div>
    </div>
    <div
      v-if="preloader"
      class="default_box table-responsive"
    >
      <div
        v-if="userList && userList.length"
        class="table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-start"
              >
                ID
              </th>
              <th
                scope="col"
                class="text-start"
              >
                Имя
              </th>
              <th
                scope="col"
                class="text-end"
              >
                Действия
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,index) in userList"
              :key="index"
            >
              <td
                class="text-start"
                v-html="item.id"
              />
              <td
                class="text-start"
                v-html="`${item.name} ${item.f_name}`"
              />
              <td class="text-end">
                <div class="table_button_box">
                  <div
                    class="table_button_item"
                    :title="`Редактировать права для ${item.name} ${item.f_name}`"
                  >
                    <button
                      class="table_button table_icon_edit"
                      @click="openModal(item)"
                    >
                      <div class="table_icons icon_pen" />
                    </button>
                  </div>
                  <div
                    class="table_button_item"
                    :title="`Удалить права для ${item.name} ${item.f_name}`"
                  >
                    <button
                      class="table_button table_icon_delete"
                      @click="deletePermissions(item.id, index)"
                    >
                      <div class="table_icons icon_trash" />
                    </button>
                  </div>
                </div>                                
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        Вы ещё не назначили ни одного администратора
      </div>
    </div>
    <Preloader v-else />
    <b-modal
      id="permissions_modal"
      v-bind="$attrs"
      centered
      :hide-header="true"
      ok-title="Закрыть"
      :ok-only="true"
      size="lg"
      @hide="user = null"
    >
      <div v-if="modal_preloader">
        <div>
          <SearchUser
            :item="user"
            @api_status="api_user"
          />
        </div>
      </div>
      <Preloader v-else />
    </b-modal>
  </div>
</template>
<script>
import SearchUser from "./components/SearchUser";
export default {
    name: "AdministratorsList",
    components: {
        SearchUser
    },
    data() {
        return {
            preloader: true,
            modal_preloader: true,
            userList: null,
            user: null
        }
    },
    mounted() {
        this.api_user();
    },
    methods: {
        openModal(item) {
            this.user = item;
            if(this.user) {
                this.$bvModal.show("permissions_modal");

            }
        },
        api_user() {
            this.preloader = false;
            let url = this.$config.api_management + "user/manager";
            this.$http.get(url).then((response) => {
                this.userList = response.data.data;
            }).finally(() => {
                this.preloader = true;
            })

        },
        deletePermissions(id, index) {
            if (confirm("Вы уверены, что хотите удалить права для этого пользователя?")) {
                let url = this.$config.api_management + "user/manager/" + id;
                this.$http.delete(url).then((response) => {
                    if (response && response.status === 204) {
                        this.userList.splice(index, 1);
                        this.$toast.default("Права доступа для пользователя успешно удалены");
                    } else {
                        this.$toast.error("Произошла ошибка при удалении");
                    }
                })
            }
        },

    }
}
</script>
<style scoped>

</style>