<template>
    <div>
        <form v-if="preloader" @submit.stop.prevent @submit="send">
            <b-row class="justify-content-between">
                <b-col xl="4">
                    <div class="default_box default_form">
                        <div class="group">
                            <input v-model="form.name" type="text" placeholder=" " required>
                            <span class="bar" />
                            <label>Название рекламного объявления</label>
                        </div>
                        <div class="group text-start">
                            <select v-model="target" class="w-100" @change="clearType">
                                <option v-for="link in typeAds" :key="link.link" :value="link" v-html="link.name" />
                            </select>
                            <small>Выберите цель рекламы и укажите ссылку на страницу в интернете, ID организации, афиши или акции</small>
                        </div>
                        <div class="group">
                            <input v-model="form[target.link]" :type="target.type" placeholder=" " required :pattern="target.link == 'url' ? 'https?://.*' : null">
                            <span class="bar" />
                            <label v-html="target.name" />
                            <small v-if="target.link == 'url'">Допустимы только ссылки в формате http://.* или https://.*</small>
                        </div>
                        <div class="group form_inline">
                            <span class="inline_label">Опубликовано</span>
                            <input id="s2" v-model="form.active" type="checkbox" class="switch">
                        </div>
                        <button v-if="$checkUser('adsBanners', 'create')" class="btn_primary" type="submit">
                            Создать объявление
                        </button>
                    </div>
                </b-col>
                <b-col xl="4">
                    <div class="default_box default_form">
                        <div class="group">
                            <input v-model="form.rent_date" type="date" placeholder=" ">
                            <span class="bar" />
                            <label>Дата окончания</label>
                        </div>
                        <div class="date_end_box">
                            <button type="button" class="date_end_item" @click="form.rent_date = $moment().add(1, 'week').format('YYYY-MM-DD')">
                                1 неделя
                            </button>
                            <button type="button" class="date_end_item" @click="form.rent_date = $moment().add(2, 'week').format('YYYY-MM-DD')">
                                2 недели
                            </button>
                            <button type="button" class="date_end_item" @click="form.rent_date = $moment().add(3, 'week').format('YYYY-MM-DD')">
                                3 недели
                            </button>
                            <button type="button" class="date_end_item" @click="form.rent_date = $moment().add(1, 'month').format('YYYY-MM-DD')">
                                1 месяц
                            </button>
                            <button type="button" class="date_end_item" @click="form.rent_date = $moment().add(3, 'month').format('YYYY-MM-DD')">
                                3 месяца
                            </button>
                            <button type="button" class="date_end_item" @click="form.rent_date = $moment().add(6, 'month').format('YYYY-MM-DD')">
                                6 месяцев
                            </button>
                            <button type="button" class="date_end_item" @click="form.rent_date = $moment().add(1, 'year').format('YYYY-MM-DD')">
                                1 год
                            </button>
                        </div>
                    </div>
                </b-col>
                <b-col xl="4">
                    <div v-if="form.horizontal || form.square" class="default_box default_form">
                        <div class="img_review_label_box">
                            <span class="img_preview_label">Изображения</span>
                        </div>
                        <div class="img_preview_box">
                            <div v-if="form.horizontal" class="img_preview_item horis">
                                <img :src="form.horizontal" alt="" class="img_preview" title="Нажмите, чтобы удалить изображение" @click="form.horizontal = null">
                            </div>
                            <div v-if="form.square" class="img_preview_item sq">
                                <img :src="form.square" alt="" class="img_preview" title="Нажмите, чтобы удалить изображение" @click="form.square = null">
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col xl="12">
                    <Filemanager ref="Filemanager" :images.sync="images" leng="2" :celat="true" />
                </b-col>
            </b-row>
        </form>
    </div>
</template>
<script>
import Filemanager from "@/components/App/Filemanager/Filemanager";
export default {
    name: "CelatAdd",
    components: {
        Filemanager
    },
    data() {
        return {
            typeAds: {
                url: {
                    name: "Ссылка на сайт",
                    link: "url",
                    type: "text"
                },
                // poster: {
                //     name: "ID афиши",
                //     link: "poster_id",
                //     type: "number"
                // },
                organisation: {
                    name: "ID организации",
                    link: "org_id",
                    type: "number"
                },
                // shares: {
                //     name: "ID акции",
                //     link: "shares_id",
                //     type: "number"
                // },
            },
            preloader: true,
            target: {
                name: "Ссылка на сайт",
                link: "url",
                type: "text"
            },
            images: [],
            date_end: null,
            form: {
                name: "",
                url: null,
                horizontal: null,
                square: null,
                rent_date: null,
                active: true
            }
        }
    },
    methods: {
        clearType() {
            this.target.link == "poster_id" ? this.form.poster_id : delete this.form.poster_id;
            this.target.link == "org_id" ? this.form.org_id : delete this.form.org_id;
            this.target.link == "shares_id" ? this.form.shares_id : delete this.form.shares_id;
            this.target.link == "url" ? this.form.url : delete this.form.url;
        },
        send() {
            if (!this.form.horizontal || !this.form.square) {
                this.$toast.error("Добавьте оба изображения для продолжения");
                return
            }
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/celat";
            this.$http.post(url, this.form).then(() => {
                this.$toast.default("Объявление успешно создано");
                this.$router.push({ name: "CelatList" });
            }).finally(() => {
                this.preloader = true;
            });

        },
    },
}
</script>
<style scoped>
.btn_primary {
    width: 100%;
}

.date_end_box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.date_end_item {
    background: #28a745;
    color: #fff;
    border-radius: 12px;
    padding: 10px 15px;
    margin-bottom: 10px;
    transition: .2s;
}

.date_end_item:hover {
    cursor: pointer;
    transition: .2s;
    background: rgba(var(--main-color-transition), 1);
}

.date_end_item:not(:last-child) {
    margin-right: 10px;
}

.img_preview_item {
    position: relative;
    z-index: 1;
}

.img_preview_item.horis:after {
    content: '700x400px';
    position: absolute;
    font-size: 12px;
    background: rgba(0, 0, 0, .7);
    color: #fff;
    border-radius: 12px;
    padding: 5px 10px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.img_preview_item.sq:after {
    content: '400x400px';
    position: absolute;
    font-size: 12px;
    background: rgba(0, 0, 0, .7);
    color: #fff;
    border-radius: 12px;
    padding: 5px 10px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.img_preview_item img {
    transition: .2s;
}

.img_preview_item img:hover {
    cursor: pointer;
    transition: .2s;
    background: #b71c1c;
}
</style>