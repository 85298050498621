<template>
    <div>
        <form v-if="preloader" @submit.stop.prevent @submit="send">
            <b-row class="justify-content-between">
                <b-col xl="6">
                    <div class="default_box default_form">
                        <div class="group">
                            <input v-model="form.name" type="text" placeholder=" " required>
                            <span class="bar" />
                            <label>Название</label>
                        </div>
                        <div class="group">
                            <textarea v-model="form.description" cols="30" rows="10" placeholder="Описание"></textarea>
                            <span v-if="form.description">{{ form.description.length.toLocaleString() }} / {{ maxLength.toLocaleString() }}</span>
                            <span class="bar" />
                        </div>
                    </div>
                </b-col>
                <b-col xl="6">
                    <div class="default_box info_form_box ">
                        <label class="default_label">Опции организации</label>
                        <div class="default_form">
                            <div class="group form_inline">
                                <span class="inline_label">Опубликовано</span>
                                <input id="s2" v-model="form.published" type="checkbox" class="switch" :true-value="1" :false-value="0">
                            </div>
                            <div class="group form_inline">
                                <span class="inline_label">Закрепить в поиске</span>
                                <input id="s2" v-model="form.fix_top_search"
                                  type="checkbox"
                                  class="switch"
                                  :true-value="1"
                                  :false-value="0"
                                >
                            </div>
                            <div class="group">
                                <input v-model="form.url_youtube" type="text" placeholder=" " pattern="https://youtu.be/.*">
                                <span class="bar" />
                                <label>Ссылка на видео YouTube</label>
                            </div>
                            <div class="group">
                                <input v-model="form.vk_video_url" type="text" placeholder=" " data-info>
                                <span class="bar" />
                                <inputInfoPopover value="vk" />
                                <label>Ссылка на видео ВКонтакте</label>
                            </div>
                        </div>
                        <button v-if="$checkUser('organisation', 'create')" class="btn_primary" type="submit">
                            Создать организацию
                        </button>
                    </div>
                </b-col>
                <b-col xl="12">
                    <div class="default_box info_form_box">
                        <label class="default_label" style="margin-bottom: 30px;">Категории справочника</label>
                        <Category />
                    </div>
                </b-col>
                <b-col xl="4">
                    <div class="default_box info_form_box">
                        <label class="default_label">Сайты <small v-html="`(${form.sites.length} из 7)`" /></label>
                        <Sites />
                    </div>
                </b-col>
                <b-col xl="4">
                    <div class="default_box info_form_box">
                        <label class="default_label">Телефоны</label>
                        <Phones />
                    </div>
                </b-col>
                <b-col xl="4">
                    <div class="default_box info_form_box">
                        <label class="default_label help_label">
                            Ключевые слова
                            <VideoHelp :label="`Посмотреть помощь по ключевым словам`" video_id="SC9dwojemsY" />
                        </label>
                        <Keywords />
                    </div>
                </b-col>
                <b-col xl="12">
                    <div class="default_box info_form_box ">
                        <label class="default_label">Адреса</label>
                        <Address />
                    </div>
                </b-col>
                <b-col xl="12">
                    <Filemanager ref="Filemanager" :images.sync="images" leng="20" />
                </b-col>
            </b-row>
        </form>
        <Preloader v-else />
    </div>
</template>
<script>
const VideoHelp = () => import('@/components/components/VideoHelp');
import Filemanager from '@/components/App/Filemanager/Filemanager';
import Address from './components/address';
import Sites from './components/sites';
import Phones from './components/phones';
import Keywords from './components/keywords';
import Category from './components/category';
import inputInfoPopover from "@/components/components/default/inputInfoPopover";
export default {
    name: 'OrganisationAdd',
    components: {
        VideoHelp,
        Filemanager,
        Address,
        Sites,
        Phones,
        Keywords,
        Category,
        inputInfoPopover
    },
    data() {
        return {
            maxLength: 2000,
            preloader: true,
            images: [],
            category_list: null,
            prompt: null,
            form: {
                name: null,
                description: null,
                image: null,
                published: 1,
                fix_top_search: 0,
                url: null,
                url_youtube: null,
                vk_video_url: null,
                categories: [],
                images: [],
                addresses: [{
                    address: null,
                    working_days: null,
                    latitude: null,
                    longitude: null,
                }],
                sites: [{
                    tag: null,
                    url: null
                }],
                telephones: [{
                    number: null,
                    name: null,
                    sort: 0
                }],
                keywords: []
            }
        }
    },
    mounted() {},
    methods: {
        async send() {
            if (this.images && this.images.length) {
                this.form.images = await this.images.map((elem, key) => {
                    let data = {
                        image: this.$config.api_filemanager + elem,
                        sort_order: key + 1
                    }
                    return data;

                })
            }

            if (!this.form.categories.length) return this.$toast.error('Выберите категорию');

            let form = this.form;
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + '/Organisation';

            form.addresses.forEach((item, key) => {
                if (!item.address || !item.latitude || !item.longitude) {
                    form.addresses.splice(key, 1);
                    this.$toast.default('Заполните данные адреса организации');
                    return;
                }
            })

            form.sites.forEach((item, key) => {
                if (!item.url) {
                    form.sites.splice(key, 1)
                }
            })

            form.telephones.forEach((item, key) => {
                if (!item.number) {
                    form.telephones.splice(key, 1)
                }
            })

            form.keywords.forEach((item, key) => {
                if (!item.keyword) {
                    form.keywords.splice(key, 1)
                }
            })

            this.$http.post(url, form).then((response) => {
                if (response.status === 201) {
                    if (response.data && response.data.id) {
                        this.$indexNow(this.$config.site_info.links.organisation, response.data.id);
                    }
                    this.$toast.default('Организация успешно создана');
                    this.$router.push({ name: 'OrganisationList' });

                }
            }).finally(() => {
                this.preloader = true;
            });
        },
        checkAddress(query) {
            let data = {
                query: query,
                from_bound: {
                    "value": "city"
                },
                to_bound: {
                    "value": "house"
                },
                count: 20,
                restrict_value: true,
            }
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
            this.$http.post(url, JSON.stringify(data), {
                transformRequest: (data, headers) => {
                    delete headers['Secure-Key'];
                    return data;
                },
                headers: {
                    'Authorization': 'Token ebcd28ae295485fc9870d76999122e7cb08e4b24',
                    'X-Secret': 'c015abca37b86288404cabed574866ad68e95745',
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                this.prompt = response.data.suggestions;
                console.log(response);
            });
        }
    }
}
</script>
<style scoped>
.btn_primary {
    width: 100%;
    margin-top: 20px;
}

small {
    font-weight: 400;
    color: #999;
}

.category_item {
    background: #eee;
}
</style>