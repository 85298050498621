<template>
  <div v-if="user">
    <b-popover
      :target="'popover-user-'+ user.id"
      triggers="hover"
      placement="bottom"
    >
      <div class="user_popover_box">
        <div class="user_popover_image_box">
          <div
            v-if="user.photo_250"
            class="user_popover_image"
            alt="user_logo"
            :style="'background-image: url('+ $config.api_filemanager + user.photo_250 +')'"
          />
          <div
            v-else
            class="user_popover_default_image "
            alt="user_logo"
          />
        </div>
        <div class="user_popover_name_box">
          <div class="user_popover_name">
            {{ user.name }} {{ user.f_name }}
          </div>
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            E-mail:
          </div>
          <div
            v-if="user.email_confirm == 1"
            class="user_popover_alert_true"
          >
            {{ user.email }}
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            {{ user.email }}
          </div>
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            Телефон:
          </div>
          <div
            v-if="user.tel"
            class="user_popover_descriprion"
          >
            {{ user.tel }}
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Не указан
          </div>
        </div>
        <div class="user_popover_block_title">
          Социальные сети
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            ВКонтакте ID:
          </div>
          <div
            v-if="user.vk_id"
            class="user_popover_descriprion"
          >
            {{ user.vk_id }}
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Не указан
          </div>
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            Одноклассники ID:
          </div>
          <div
            v-if="user.ok_id"
            class="user_popover_descriprion"
          >
            {{ user.ok_id }}
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Не указан
          </div>
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            Facebook ID:
          </div>
          <div
            v-if="user.fb_id"
            class="user_popover_descriprion"
          >
            {{ user.fb_id }}
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Не указан
          </div>
        </div>
        <div class="user_popover_block_title">
          Доп. информация
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            Дата регистрации:
          </div>
          <div
            class="user_popover_descriprion"
            v-html="$moment(user.created_at).locale('ru').format('LL')"
          />
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            Посл. обновление:
          </div>
          <div
            v-if="user.updated_at"
            class="user_popover_descriprion"
            v-html="$moment(user.updated_at).locale('ru').format('LL')"
          />
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Не обновлялся
          </div>
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            Обн-ие токена:
          </div>
          <div
            v-if="user.token_update_date"
            class="user_popover_descriprion"
            v-html="$moment(user.token_update_date).locale('ru').format('LL')"
          />
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Не обновлялся
          </div>
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            IP регистрации:
          </div>
          <div
            v-if="user.reg_ip"
            class="user_popover_descriprion"
          >
            {{ user.reg_ip }}
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Не известен
          </div>
        </div>
        <div class="user_popover_item">
          <div class="user_popover_title">
            Бан:
          </div>
          <div
            v-if="user.ban"
            class="user_popover_alert_danger"
          >
            {{ user.ban }}
          </div>
          <div
            v-else
            class="user_popover_alert_true"
          >
            Не забанен
          </div>
        </div>
        <div
          v-if="user.manager_token"
          class="user_popover_block_title"
        >
          Manager token
        </div>
        <div
          v-if="user.manager_token && user.manager_token.token"
          class="user_popover_item"
        >
          <div class="user_popover_title">
            Токен:
          </div>
          <input
            type="text"
            :value="user.manager_token.token"
          >
        </div>
        <div
          v-if="user.roles"
          class="user_popover_block_title"
        >
          Роли
        </div>
        <div
          v-if="user.roles"
          class="user_popover_item"
        >
          <div class="user_popover_title">
            Супер админ:
          </div>
          <div
            v-if="user.roles.super_admin"
            class="user_popover_alert_true"
          >
            Да
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Нет
          </div>
        </div>
        <div
          v-if="user.roles"
          class="user_popover_item"
        >
          <div class="user_popover_title">
            Админ:
          </div>
          <div
            v-if="user.roles.admin"
            class="user_popover_alert_true"
          >
            Да
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Нет
          </div>
        </div>
        <div
          v-if="user.roles"
          class="user_popover_item"
        >
          <div class="user_popover_title">
            Менеджер:
          </div>
          <div
            v-if="user.roles.management && user.roles.management.length"
            class="user_popover_alert_true"
          >
            Да
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Нет
          </div>
        </div>
        <div
          v-if="user.roles"
          class="user_popover_block_title"
        >
          Управление городами
        </div>
        <div
          v-if="user.roles"
          class="user_popover_item"
        >
          <div class="user_popover_title">
            Все города:
          </div>
          <div
            v-if="user.roles.city_all && user.roles.city_all !== 0"
            class="user_popover_alert_true"
          >
            Да
          </div>
          <div
            v-else
            class="user_popover_alert_danger"
          >
            Нет
          </div>
        </div>
        <div
          v-if="user.roles && user.roles.city_list"
          class="user_popover_item_city"
        >
          <div class="user_popover_title">
            Список городов:
          </div>
          <div
            v-if="user.roles.city_list"
            class="user_popover_descriprion"
          >
            <div
              v-for="city in user.roles.city_list"
              :key="city"
            >
              - {{ city }}
            </div>
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>
<script>
export default {
    name: "UserInfo",
    components: {},
    props: {
        user: Object,
    },
    methods: {},
}
</script>
<style scoped>
.popover {
    min-width: 300px !important;
}

.user_popover_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.user_popover_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2px 0;
}

.user_popover_item_city {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 2px 0;
}

.user_popover_title {
    margin-right: 20px;
    color: #555555;
}

.user_popover_image_box {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    position: relative;
    margin: 0 auto 20px;
    background: #eeeeee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.user_popover_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.user_popover_default_image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    mask-image: url('/image/icon/user.svg');
    background-color: #ffffff;

}

.user_popover_name_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.user_popover_name_box {
    font-weight: 600;
    font-size: 1.2em;
    padding: 10px 5px;
}

.user_popover_alert_danger {
    color: #cf0404;
}

.user_popover_alert_true {
    color: #16b803;
}

.user_popover_block_title {
    padding: 20px 0 10px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
}
</style>