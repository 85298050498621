<template>
  <div>
    <div class="search_box">
      <input
        v-model="input"
        type="email"
        placeholder="Поиск"
        class="default_input_search"
      >
    </div>
    <div v-if="preloader">
      <div
        v-if="filters && filters.length"
        class="default_box table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-start"
              >
                ID
              </th>
              <th
                scope="col"
                class="text-start"
              >
                Имя
              </th>
              <th
                scope="col"
                class="text-end"
              >
                E-mail
              </th>
              <th
                scope="col"
                class="text-end"
              >
                Действия
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in filters"
              :key="index"
            >
              <th
                scope="row"
                class="text-start"
                v-html="item.id"
              />
              <td
                :id="'popover-user-'+ item.id"
                scope="row"
                class="text-start"
                v-html="item.name"
              >
                <UserInfo :user="item" />
              </td>
              <td
                v-if="item.email"
                scope="row"
                class="text-end"
                v-html="item.email"
              />
              <td
                v-else-if="item.email.vk"
                scope="row"
                class="text-end"
                v-html="item.vk.email"
              />
              <td
                v-else-if="item.email.ok"
                scope="row"
                class="text-end"
                v-html="item.ok.email"
              />
              <td
                v-else-if="item.email.fb"
                scope="row"
                class="text-end"
                v-html="item.fb.email"
              />
              <td
                v-else
                scope="row"
                class="text-end"
              />
              <td
                scope="col"
                class="text-end"
              >
                <div class="table_button_box">
                  <div
                    class="table_button_item"
                    :title="`Изменить права`"
                  >
                    <button
                      class="table_button table_icon_edit"
                      @click="get_user(item.id)"
                    >
                      <div class="table_icons icon_pen" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <b-modal
          id="permissions_modal"
          v-bind="$attrs"
          centered
          :hide-header="true"
          ok-title="Закрыть"
          :ok-only="true"
        >
          <div v-if="modal_preloader">
            <div v-if="user">
              <PermissionEdit
                :user="user"
                @updateRole="updateRole"
              />
            </div>
          </div>
          <Preloader v-else />
        </b-modal>
        <div v-if="!search_list && last_page && last_page > page">
          <div
            v-if="button_preloader"
            class="news_button_block"
          >
            <button
              class="btn_primary"
              @click="api_next"
            >
              Показать ещё
            </button>
          </div>
          <Preloader v-else />
        </div>
      </div>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
import UserInfo from "./UserInfo";
import PermissionEdit from "./components/PermissionEdit";
export default {
    name: "AdminUserList",
    components: {
        UserInfo,
        PermissionEdit
    },
    data() {
        return {
            preloader: false,
            button_preloader: true,
            modal_preloader: true,
            search_list: null,
            timer: 0,
            input_search: {
                email: null
            },
            list: null,
            last_page: null,
            page: 1,
            per_page: 50,
            user: null,
        }
    },
    computed: {
        filters() {
            if (this.search_list && this.search_list.length && this.input_search.email && this.input_search.email.length >= 4 && this.validateEmail(this.input_search.email)) {
                return this.search_list;
            }

            return this.list
        },
        input: {
            get() {
                return this.input_search.email
            },
            set(val) {
                this.input_search.email = val

                if (this.timer) {clearTimeout(this.timer)}

                if (this.input_search.email && this.input_search.email.length >= 4 && this.validateEmail(this.input_search.email)) {
                    this.timer = setTimeout(() => {
                        this.search_user();
                    }, 1000)
                } else {
                    this.search_list = null;
                }
            }
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + "user";
            this.$http.get(url, {
                params: {
                    page: this.page,
                    per_page: this.per_page,
                }
            }).then((response) => {
                this.list = response.data.data;
                this.last_page = response.data.last_page;
            }).finally(() => {
                this.preloader = true;
            })
        },
        api_next() {
            this.button_preloader = null;
            let url = this.$config.api_management + "user";
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page,
                    per_page: this.per_page,
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.data);
                this.$toast.default("Данные успешно получены");

            }).finally(() => {
                this.button_preloader = "finish";

            })
        },
        search_user() {
            this.preloader = false;
            let url = this.$config.api_management + "user/searchBy";
            this.$http.post(url, this.input_search).then((response) => {
                this.search_list = response.data.data;
            }).finally(() => {
                this.preloader = true;
            })

        },
        validateEmail(email) {
            var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        },
        get_user(id) {
            this.modal_preloader = false;
            this.$bvModal.show("permissions_modal");
            let url = this.$config.api_management + "user/" + id;
            this.$http.get(url).then((response) => {
                this.user = response.data;
            }).catch(() => {
                this.user = null;
                this.$bvModal.hide("permissions_modal");
                this.$toast.error("Ошибка");
            }).finally(() => {
                this.modal_preloader = true;
            })
        },
        updateRole(data) {
            this.modal_preloader = false;
            let url = this.$config.api_management + "user/updateRole/" + data.id;
            this.$http.put(url, data.data).then(() => {
                this.api();
                this.$toast.default("Права успешно обновлены");
            }).catch(() => {
                this.user = null;
                this.$bvModal.hide("permissions_modal");
                this.$toast.error("Ошибка");
            }).finally(() => {
                this.modal_preloader = true;
            })
        }
    }
}
</script>
<style scoped>
.btn_primary {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 15px;
}

.search_box {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 12px;
}

.default_input_search {
    width: 100%;
}
</style>