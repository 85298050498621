<template>
  <div>
    <div v-if="preloader">
      <div class="header_button_box">
        <div
          v-if="$checkUser('newsCategory', 'create')"
          class="header_button_item"
        >
          <router-link :to="{name: 'NewsCategoryAdd'}">
            <button class="header_button">
              <div class="header_icons icon_add" /><span>Создать раздел</span>
            </button>
          </router-link>
        </div>
      </div>
      <div
        v-if="list && list.length"
        class="default_box table-responsive"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-start "
                @click="sort('id')"
              >
                <span
                  class="table_header_link"
                  title="Сортировать по ID"
                >
                  <div>ID</div>
                  <div class="info_icon" />
                </span>
              </th>
              <th
                scope="col"
                class="text-start "
                @click="sort('title')"
              >
                <span
                  class="table_header_link"
                  title="Сортировать по названию"
                >
                  <div>Название</div>
                  <div class="info_icon" />
                </span>
              </th>
              <th
                v-if="$checkUser('newsCategory', 'edit')"
                scope="col"
                class="text-start "
                @click="sort('published')"
              >
                <span
                  class="table_header_link flex-end"
                  title="Сортировать по статусу"
                >
                  <div>Опубликовано</div>
                  <div class="info_icon" />
                </span>
              </th>
              <th
                v-if="$checkUser('newsCategory', 'edit') || $checkUser('newsCategory', 'delete')"
                scope="col"
                class="text-end"
              >
                Действия
              </th>
            </tr>
          </thead>
          <transition-group
            name="flip-list"
            tag="tbody"
          >
            <tr
              v-for="(item,index) in list"
              :key="item.id"
            >
              <th
                scope="row"
                class="text-start"
              >
                <a
                  v-if="item.published"
                  :href="'https://okaygorod.com/' + $city.index + '/interesting/list/' + item.id"
                  target="_blank"
                  :title="'Открыть ссылку https://okaygorod.com/' + $city.index + '/interesting/list/' + item.id"
                  class="link"
                >{{ item.id }}</a>
                <span
                  v-else
                  v-html="item.id"
                />
              </th>
              <td class="text-start">
                {{ $cutText(item.title, 80) }}
              </td>
              <td
                v-if="$checkUser('newsCategory', 'edit')"
                class="text-end"
              >
                <input
                  id="s2"
                  type="checkbox"
                  class="switch"
                  :checked="item.published"
                  :disabled="disabled_status"
                  @change="api_status(item, index)"
                >
              </td>
              <td
                v-if="$checkUser('newsCategory', 'edit') || $checkUser('newsCategory', 'delete')"
                class="text-end"
              >
                <div class="table_button_box">
                  <router-link
                    v-if="$checkUser('newsCategory', 'edit')"
                    :to="{name: 'NewsCategoryEdit', params: {id: item.id}}"
                    tag="div"
                    class="table_button_item"
                    :title="`Редактировать раздел`"
                  >
                    <button class="table_button table_icon_edit">
                      <div class="table_icons icon_pen" />
                    </button>
                  </router-link>
                  <div
                    v-if="$checkUser('newsCategory', 'delete')"
                    class="table_button_item"
                    :title="`Удалить раздел`"
                  >
                    <button
                      class="table_button table_icon_delete"
                      @click="del(item.id, item.title, index)"
                    >
                      <div class="table_icons icon_trash" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </transition-group>
        </table>
      </div>
      <div
        v-else
        class="default_box"
      >
        В этом городе нет разделов новостей
      </div>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
export default {
    name: "NewsCategoryList",
    components: {},
    data() {
        return {
            disabled_status: false,
            preloader: false,
            page: 1,
            list: null,
            sortArray: false
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/news/category";
            this.$http.get(url).then((response) => {
                this.list = response.data;
                this.sort();
            }).finally(() => {
                this.preloader = true;
            });
        },
        api_status(array, index) {
            array.published = !array.published;
            let data = {
                published: array.published
            }
            this.disabled_status = true;
            let url = this.$config.api_management + this.$city.index + "/news/category/" + array.id;
            this.$http.put(url, data).then((response) => {
                this.list[index].published = response.data.published;
                this.$toast.default("Статус раздела успешно изменён");
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        del(id, name, index) {
            if (confirm("Вы уверены, что хотите удалить раздел?\n\n\"" + name + "\"\n\nЭто действие нельзя отменить!")) {
                let url = this.$config.api_management + this.$city.index + "/news/category/" + id;
                this.$http.delete(url).then((response) => {
                    if (response && response.status === 204) {
                        this.list.splice(index, 1);
                        this.$toast.default("Раздел \"" + name + "\", успешно удалён");
                    } else {
                        this.$toast.error("Произошла ошибка при удалении");
                    }
                })
            }
        },
        sort(data = "id") {
            this.list = Object.values(this.list).sort((a, b) => {
                if (this.sortArray) {
                    if (this.$assignValue(a[data]) < this.$assignValue(b[data])) {
                        return -1;
                    }
                    if (this.$assignValue(a[data]) > this.$assignValue(b[data])) {
                        return 1;
                    }
                } else {

                    if (this.$assignValue(b[data]) < this.$assignValue(a[data])) {
                        return -1;
                    }
                    if (this.$assignValue(b[data]) > this.$assignValue(a[data])) {
                        return 1;
                    }

                }
                return 0;
            });

            this.sortArray = !this.sortArray;
        },
    }
}
</script>
<style scoped>
.flip-list-move {
    transition: transform 1s;
}
</style>