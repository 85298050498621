<template>
  <div>
    <div v-if="preloader">
      <b-row class="justify-content-between">
        <b-col xl="6">
          <div class="default_box table-responsive">
            <a
              href="https://okaygorod.com/cache/clean?key=uw00ytttywovj7kc5y7vpxit5e51l676hyi20t9zl7icdp1t334bcsdw75rg7l29mindn3it18xg90b0xf7jq1iep4t6des3vk8wekuyj4mjnwqp8u1ddtru1kg8uj9s"
              target="_blank"
              onclick="return confirm('Вы уверены, что хотите очистить кэш на сайте?')"
            >
              <button
                class="header_button"
                type="button"
                :disabled="button_disable"
                @click="timer_method"
              >
                <span>Очистить кеш на сайте</span>
              </button>
            </a>
          </div>
        </b-col>
        <b-col xl="6">
          <div class="default_box default_form">
            <form
              v-show="indexNowPreloader"
              @submit.stop.prevent
              @submit="indexNow"
            >
              <div class="group">
                <input
                  v-model="indexNowLink"
                  type="url"
                  placeholder=" "
                  @keyup.enter="indexNow"
                >
                <span class="bar" />
                <label>Ссылка для немедленной индексации страницы Яндекс роботом</label>
              </div>
              <button
                class="btn_primary"
                type="submit"
              >
                Отправить на переобход
              </button>
            </form>
            <Preloader v-if="!indexNowPreloader" />
          </div>
        </b-col>
      </b-row>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
export default {
    name: "Tools",
    components: {},
    data() {
        return {
            preloader: true,
            timer: 0,
            button_disable: false,
            indexNowLink: null,
            indexNowPreloader: true
        }
    },
    mounted() {

    },
    methods: {
        timer_method() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
                this.button_disable = true;
            }
            this.timer = setTimeout(() => {
                this.button_disable = false;
            }, 60000);
        },
        indexNow() {
            if (this.indexNowLink && this.indexNowLink.includes("okaygorod.com")) {
                this.indexNowPreloader = false;
                let url = "https://okaygorod.site/yandexIndexNow/index.php";
                this.$http.get(url, {
                    params: {
                        link: this.indexNowLink
                    }
                }).then(() => {
                    this.$toast.default("Страница отправлена на переобход роботом Yandex");
                    this.indexNowLink = null;
                }).finally(() => {
                    this.indexNowPreloader = true;
                })
            } else {
                this.$toast.error("Ссылка должна вести на сайт okaygorod.com");
            }
        }
    }
}
</script>
<style scoped>
.header_button {
    float: left;
    display: inline-block;
}
</style>