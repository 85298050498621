<template>
  <div>
    <div class="default_box info_form_box">
      <label class="default_label">Полезные ссылки</label>
      <a
        href="https://vk.com/goroda.mobi"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/vk.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Группа для партнёров ВКонтакте</span>
        </div>
      </a>
      <a
        href="https://metrika.yandex.ru/dashboard?group=day&period=month&id=48705500"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/yandex.png"
            alt=""
            class="userful_link_img"
          >
          <span>Статистика сайта</span>
        </div>
      </a>
      <a
        href="https://appmetrica.yandex.ru/application/list"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/yandex.png"
            alt=""
            class="userful_link_img"
          >
          <span>Статистика мобильных приложений</span>
        </div>
      </a>
      <a
        href="http://www.consultant.ru/document/cons_doc_LAW_58968/"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/law.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Федеральный закон "О рекламе"</span>
        </div>
      </a>
      <button
        v-b-toggle.collapse-rss
        class="userful_link_item"
        type="button"
      >
        <img
          src="/image/icons/usefulLinks/rss.svg"
          alt=""
          class="userful_link_img img-fluid"
        >
        <span>Ссылка на Rss ленту новостей города</span>
      </button>
      <b-collapse
        id="collapse-rss"
        class="mt-2"
      >
        <div class="default_box default_form">
          <div class="group">
            <input
              id="link_rss"
              type="text"
              :value="$config.api_url + $city.index +'/News.rss'"
              readonly
              @click="$copyText($event.target.value)"
            >
          </div>
        </div>
      </b-collapse>
      <button
        v-b-toggle.collapse-rss2
        class="userful_link_item"
        type="button"
      >
        <img
          src="/image/icons/usefulLinks/rss.svg"
          alt=""
          class="userful_link_img img-fluid"
        >
        <span>Ссылка на Rss ленту новостей города для Yandex</span>
      </button>
      <b-collapse
        id="collapse-rss2"
        class="mt-2"
      >
        <div class="default_box default_form">
          <div class="group">
            <input
              id="link_rss_yandex"
              type="text"
              :value="$config.api_url + $city.index +'/News.rssYandex'"
              readonly
              @click="$copyText($event.target.value)"
            >
          </div>
        </div>
      </b-collapse>
      <a
        href="https://www.flaticon.com/"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/flaticon.png"
            alt=""
            class="userful_link_img"
          >
          <span>Иконки (Flaticon.com)</span>
        </div>
      </a>
      <a
        href="https://colorscheme.ru/"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/color-circle.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Подбор цветовых схем</span>
        </div>
      </a>
      <a
        href="https://uigradients.com/#Telegram"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/gradient.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Галерея градиентов</span>
        </div>
      </a>
      <label class="default_label">Инструменты для продвижения</label>
      <a
        href="https://direct.yandex.ru/"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/yandexDirect.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Yandex Direct</span>
        </div>
      </a>
      <a
        href="https://ads.google.com/intl/ru_RU/home"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/googleAdWords.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Google AdWords</span>
        </div>
      </a>
      <label class="default_label">Ссылки на приложения</label>
      <a
        href="https://okaygorod.com/android"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/android.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Android</span>
        </div>
      </a>
      <a
        href="https://okaygorod.com/ios"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/apple.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Appstore</span>
        </div>
      </a>
      <a
        href="https://okaygorod.com/appgallery"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/appGalery.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Appgallery</span>
        </div>
      </a>
      <a
        href="https://okaygorod.com/application"
        target="_blank"
      >
        <div class="userful_link_item">
          <img
            src="/image/icons/usefulLinks/global.svg"
            alt=""
            class="userful_link_img"
          >
          <span>Общая ссылка</span>
        </div>
      </a>
    </div>
  </div>
</template>
<script>
export default {
    name: "UsefulLinks",
    components: {},
    data() {
        return {

        }
    },
    methods: {},
}
</script>
<style scoped>
.userful_link_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
    border-radius: 12px;
    transition: .2s;
    width: 100%;
    color: #3c81df;
    margin-bottom: 5px;
    text-align: left;
}

.info_form_box label:not(:first-child) {
    margin-top: 30px;
}

.userful_link_item:hover {
    background: rgba(var(--main-color), .1);
    transition: .2s;
}

.userful_link_img {
    max-height: 25px;
    margin-right: 10px;
}
</style>