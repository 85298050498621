<template>
  <div>
    <div
      v-if="item && item.embed"
      class="org_body"
    >
      <iframe
        v-if="item.embed"
        id="YouTubePlayer"
        type="text/html"
        :src="item.embed"
        allowfullscreen
        frameborder="0"
      />
    </div>
    <div
      v-else-if="youtube"
      class="org_body"
    >
      <iframe
        v-if="youtube"
        id="YouTubePlayer"
        type="text/html"
        :src="youtube"
        allowfullscreen
        frameborder="0"
      />
    </div>
    <div
      v-else-if="video_id"
      class="org_body"
    >
      <iframe
        v-if="video_id"
        id="YouTubePlayer"
        type="text/html"
        :src="`//www.youtube.com/embed/${video_id}`"
        allowfullscreen
        frameborder="0"
      />
    </div>
  </div>
</template>
<script>
export default {
    name: "YouTube",
    props: {
        item: Object,
        youtube: String,
        video_id: String
    },
}
</script>
<style scoped>
iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.org_body {
    background: #fff;
    border-radius: 12px;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}
</style>