<template>
    <tr>
        <th><div class="sort_icon" /></th>
        <th scope="row" class="text-start" v-html="item.id" />
        <td class="text-start" v-html="$cutText(item.title, 80)" />
        <td v-if="$checkUser('buttons', 'edit')" class="text-end">
            <input id="s2" type="checkbox" class="switch" :checked="item.published" :disabled="$parent.$parent.$data.disabled_status" @change="$parent.$parent.api_status(item, index)">
        </td>
        <td v-if="$checkUser('buttons', 'edit')" class="text-end">
            <div class="sort_box">
                <the-mask ref="input_sort" mask="###" v-model="$parent.$parent.$data.list[index].order" class="sort_input" :readonly="$parent.$parent.$data.disabled_status" />
                <button type="button" :disabled="$parent.$parent.$data.disabled_status" @click="$parent.$parent.sortList(index, $parent.$parent.$data.list[index])">
                    <div class="icon_checkmark" title="Сохранить" />
                </button>
            </div>
        </td>
        <td v-if="$checkUser('buttons', 'edit') || $checkUser('buttons', 'delete')" class="text-end">
            <div class="table_button_box">
                <router-link v-if="$checkUser('buttons', 'edit')" :to="{name: 'ButtonsEdit', params: {id: item.id}}" tag="div" class="table_button_item" :title="`Редактировать кнопку`">
                    <button class="table_button table_icon_edit">
                        <div class="table_icons icon_pen" />
                    </button>
                </router-link>
                <div v-if="$checkUser('buttons', 'delete')" class="table_button_item" :title="`Удалить кнопку`">
                    <button class="table_button table_icon_delete" @click="$parent.$parent.del(item.id, item.title, index)">
                        <div class="table_icons icon_trash" />
                    </button>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
export default {
    name: "ButtonItem",
    components: {},
    props: {
        item: {
            type: Object
        },
        index: {
            type: Number
        }
    },
    data() {
        return {
            preloader: false,
        }
    },
    mounted() {

    },
    methods: {}
}
</script>
<style scoped>
.sort_box {
    border: 1px dashed rgba(var(--main-color), 1.0);
    border-radius: 80px;
    width: 85px;
    display: block;
    position: relative;
    margin-left: auto;
}

.sort_input {
    border: none;
    outline: none;
    border-radius: 80px;
    padding: 0 10px;
    width: 100%;
}

.icon_checkmark {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: rgba(var(--main-success-color), 1);
    display: block;
    mask-image: url('/image/icons/default/checkmark.svg');

}

.icon_checkmark:hover {
    background-color: rgba(var(--main-color-transition), 1.0);
    cursor: pointer;
}

.sort_icon {
    width: 20px;
    height: 20px;
    background-color: rgba(var(--main-color-transition), 1.0);
    display: block;
    transition: .2s;
    mask-image: url('/image/icons/default/sort.svg');
}
</style>