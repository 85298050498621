<template>
  <div>
    <div
      v-if="$parent.$data.form.telephones"
      class="default_form"
    >
      <div
        v-for="(item,index) in $parent.$data.form.telephones"
        :key="index"
        class="form_inline"
      >
        <div class="group">
          <input
            v-model="item.number"
            type="text"
            placeholder=" "
          >
          <span class="bar" />
          <label>Телефон</label>
        </div>
        <div class="group">
          <input
            v-model="item.name"
            type="text"
            placeholder=" "
          >
          <span class="bar" />
          <label>Описание</label>
        </div>
        <button
          v-if="index === 0"
          type="button"
          class="table_button table_icon_edit"
          @click="addPhone"
        >
          <div class="table_icons icon_add" />
        </button>
        <button
          v-else
          type="button"
          class="table_button table_icon_delete"
          @click="$parent.$data.form.telephones.splice(index,1)"
        >
          <div class="table_icons icon_trash" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: "Phones",
    components: {},
    data() {
        return {}
    },
    mounted() {

    },
    methods: {
        addPhone() {
            this.$parent.$data.form.telephones.push({
                number: null,
                name: null,
                sort: 0
            })
        },
    }
}
</script>
<style scoped>
.table_button {
    border-radius: 80px;
}
.form_inline {
    align-items: center;
    padding: 0;
}

.group {
    flex: 1;
}

.group:not(:last-child) {
    margin-right: 20px;
}
</style>