<template>
  <div>
    <div
      v-if="!item"
      class="search_container"
    >
      <label
        for="search_user_input"
        class="default_label"
      >Поиск пользователя</label>
      <input
        id="search_user_input"
        v-model="input"
        type="text"
        placeholder="Укажите E-mail пользователя"
        class="default_input_search"
        list="user_list"
        autocomplete="on"
      >
      <small>Поиск работает автоматически при введении корректоного E-mail (пример: example@example.ru).</small>
      <div v-show="!search_preloader">
        <SearchAnim />
      </div>
      <datalist
        v-if="search_list && search_list.length"
        id="user_list"
        style="display: block;"
      >
        <option
          v-for="user_item in search_list"
          :key="user_item.id"
          :value="user_item.id"
          :disabled="$user.id === user_item.id"
          @click="setUser(user_item)"
        >
          <div
            v-if="$user.id === user_item.id"
            v-html="`ID ${user_item.id} - ${user_item.name} ${user_item.f_name} - (Вы не можете выбрать самого себя)`"
          />
          <div
            v-else
            v-html="`ID ${user_item.id} - ${user_item.name} ${user_item.f_name}`"
          />
        </option>
      </datalist>
    </div>
    <div v-if="user">
      <PermissionForm :user="user" />
    </div>
  </div>
</template>
<script>
import PermissionForm from "./PermissionForm";
import SearchAnim from "./searchAnim";
export default {
    name: "SearchUsers",
    components: {
        PermissionForm,
        SearchAnim
    },
    props: {
        item: {
            type: Object
        }
    },
    data() {
        return {
            preloader: true,
            timer: 0,
            input_search: {
                email: null
            },
            search_preloader: true,
            search_list: null,
            user: this.item ? this.item : null,
            form: {
                id: null,
                management: {
                    allow: {},
                    deny: [{}]
                }
            }

        }
    },
    computed: {
        input: {
            get() {
                return this.input_search.email
            },
            set(val) {
                this.input_search.email = val

                if (this.timer) {clearTimeout(this.timer)}

                if (this.input_search.email && this.input_search.email.length >= 4 && this.validateEmail(this.input_search.email)) {
                    this.timer = setTimeout(() => {
                        this.search_user();
                    }, 1000)
                } else {
                    this.search_list = null;
                }
            }
        }
    },
    mounted() {
    },
    methods: {
        timer_method() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                this.search_user();
            }, 300);
        },
        search_user() {
                this.user = null;
            this.search_preloader = false;
            let url = this.$config.api_management + "user/searchBy";
            this.$http.post(url, this.input_search).then((response) => {
                this.search_list = response.data.data;
            }).finally(() => {
                this.search_preloader = true;
            })

        },
        validateEmail(email) {
            var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        },
        setUser(user) {
            this.user = user;
            this.input = null;

        }
    }
}
</script>
<style scoped>
.search_box {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 12px;
}

.default_input_search {
    width: 100%;
}

dataList {
    border: 2px dashed rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    display: block;
    z-index: 1000;
    border-radius: 12px;
}

dataList option {
    transition: .2s;
    padding: 10px;
}

dataList option:first-child {
    border-radius: 12px 12px 0 0;
}

dataList option:last-child {
    border-radius: 0 0 12px 12px;
}

dataList option:hover {
    cursor: pointer;
    background: #eee;
    transition: .2s;
}

dataList option:not(:last-child) {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.search_container {
    padding-bottom: 20px;
}

small {
    display: block;
    color: #999;
    font-size: 12px;
}
</style>