<template>
  <div>        
    <button
      v-b-modal="`Help`"
      type="button"
      :title="label ? label : 'Открыть подсказку'"
    >
      <div
        class="info_icon"
        :title="label ? label : 'Открыть подсказку'"
      />
    </button>
    <b-modal
      id="Help"
      title="Помощь"
      size="xl"
      @hidden="$emit('clear')"
    >
      <YouTube
        :item="video ? video : null"
        :youtube="youtube ? youtube : null"
        :video_id="video_id ? video_id : null"
      />
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Закрыть
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
const YouTube = () => import('@/components/components/YouTube');
export default {
    name: 'VideoHelp',
    components: {
        YouTube
    },
    props: {
        label: {
            type: String
        },
        video: {
            type: Object
        },
        youtube: {
            type: String
        },
        video_id: {
            type: String
        }
    },
    data() {
        return {
            preloader: false,
        }
    },
    mounted() {

    },
    methods: {
    }
}
</script>

<style scoped>
</style>
