<template>
    <div>
        <div v-if="preloader">
            <div class="header_button_box">
                <div v-if="$checkUser('organisationCategory', 'create')" class="header_button_item">
                    <router-link :to="{name: 'OrganisationCategoryAdd'}">
                        <button class="header_button">
                            <div class="header_icons icon_add" /><span>Создать категорию</span>
                        </button>
                    </router-link>
                </div>
            </div>
            <div v-if="list && list.length" class="category_container">
                <table class="table table-striped">
                    <tr v-for="(item,index) in list" :key="item.id">
                        <Item :item="item" :index="index" />
                    </tr>
                </table>
            </div>
            <div v-else class="default_box">
                Результатов не найдено
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import Item from './components/item';
export default {
    name: 'OrganisationCategoryList',
    components: { Item },
    data() {
        return {
            preloader: true,
            list: null,
            disabled_status: false
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.api(to.params.id);
        next();
    },
    mounted() {
        this.api(this.$route.params.id);
    },
    methods: {
        getUnflatten(items, id = null) {
            return items
                .filter(item =>
                    (item.parent_id === 0 ? item.parent_id = null : item.parent_id) === id
                )
                .map(item => ({ ...item, children: this.getUnflatten(items, item.id) }));
        },
        api(id = 0) {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + '/OrganizationsCategory/parentId/' + id;
            this.$http.get(url).then((response) => {
                this.list = response.data.sort((a, b) => {
                    return a.sort - b.sort;
                });
            }).finally(() => {
                this.preloader = true;
            });
        },
        sortCategory(id, value, index) {
            if (!id) return this.$toast.error('Не выбран ID');
            if (!value) return this.$toast.error('Не указано значение сортировки');

            this.disabled_status = true;
            let data = {
                sort: value
            }

            let url = this.$config.api_management + this.$city.index + '/OrganizationsCategory/' + id;
            this.$http.put(url, data).then((response) => {
                this.list[index].sort = response.data.sort;
                this.list = this.list.sort((a, b) => {
                    return a.sort - b.sort;
                });
                this.$toast.default('Позиция успешно изменена');
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        api_status(array, index) {
            array.active = array.active == false ? true : false;
            let data = {
                active: array.active
            }
            this.disabled_status = true;
            let url = this.$config.api_management + this.$city.index + '/OrganizationsCategory/' + array.id;
            this.$http.put(url, data).then((response) => {
                this.list. [index].active = response.data.active;
                this.$toast.default('Статус категории успешно изменён');
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        del(id, name, index) {
            if (confirm('Вы уверены, что хотите удалить эту категорию?\n\n"' + name + '"\n\nЭто действие нельзя отменить!')) {
                let url = this.$config.api_management + this.$city.index + '/OrganizationsCategory/' + id;
                this.$http.delete(url).then((response) => {
                    if (response && response.status === 200) {
                        this.list.splice(index, 1);
                        this.$toast.default('Категория "' + name + '", успешно удалена');
                    } else {
                        this.$toast.error('Произошла ошибка при удалении');
                    }
                })
            }
        },
    },
}
</script>
<style scoped>
.category_container {
    overflow-x: auto;
}
</style>