import Vue from "vue";

export default (to, from, next) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user.roles || !user.roles.super_admin) {
        next({name: "Home"});
        Vue.prototype.$toast.error("У вас нет доступа");
        return false;
    }
}
