<template>
    <div class="rubricator_item">
        <div class="label">
            <div class="list_item_title_box">
                <span class="parent_category" :style="item.active ? 'background: rgba(var(--main-color), 1.0)' : 'background:#b71c1c'" title="Скопировать ID категории" @click="$copyText(item.id)" v-html="'ID • ' + item.id" />
                <span class="parent_category_title" v-html="item.name" />
            </div>
            <div>
                <div class="d-flex align-items-center">
                    <div>
                        <router-link v-if="item.category_children && item.category_children.length" :to="{name: 'OrganisationCategoryList', params: {id: item.id}}">
                            <div class="open_button">
                                открыть
                            </div>
                        </router-link>
                        <router-link v-else :to="{name: 'OrganisationSortList', params: {id: item.id}}">
                            <div class="open_button">
                                организации
                            </div>
                        </router-link>
                        <!-- <button type="button" class="open_button" v-if="item.category_children && item.category_children.length"  @click="$parent.api(item.id)">
                    открыть
                </button> -->
                    </div>
                    <div v-if="$checkUser('organisationCategory', 'edit')" class="sort_box">
                        <the-mask ref="input_sort" mask="###" :value="item.sort" class="sort_input" :readonly="$parent.$data.disabled_status" />
                        <button type="button" :disabled="$parent.$data.disabled_status" @click="$parent.sortCategory(item.id, $refs.input_sort.$el.value, index)">
                            <div class="icon_checkmark" title="Сохранить" />
                        </button>
                    </div>
                    <div class="rubricator_button_box">
                        <button v-if="item.keywords && item.keywords.length" v-b-toggle="'collapse_tags_' + item.id" class="btn_keywords">
                            Ключевые слова
                        </button>
                    </div>
                    <td v-if="$checkUser('organisationCategory', 'edit')" class="text-end">
                        <input id="s2" type="checkbox" class="switch" :checked="item.active" :disabled="$parent.$data.disabled_status" :true-value="true" :false-value="false" @change="$parent.api_status(item, index)">
                    </td>
                    <div class="table_button_box">
                        <router-link v-if="$checkUser('organisationCategory', 'edit')" :to="{name: 'OrganisationCategoryEdit', params: {id: item.id}}" class="table_button_item">
                            <button class="table_button table_icon_edit">
                                <div class="table_icons icon_pen" />
                            </button>
                        </router-link>
                        <div v-if="$checkUser('organisationCategory', 'delete')" class="table_button_item" :title="`Удалить категорию`">
                            <button class="table_button table_icon_delete" @click="$parent.del(item.id, item.name, index)">
                                <div class="table_icons icon_trash" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-collapse v-if="item.keywords && item.keywords.length" :id="'collapse_tags_' + item.id" animation="myCustomSlide">
            <div class="tags_box">
                <div v-for="(tag,index_tag) in item.keywords" :key="index_tag" class="tags_item" v-html="tag.name" />
            </div>
        </b-collapse>
        <div v-if="item.category && item.category.length">
            <b-collapse :id="'collapse_' + item.id" class="mt-2">
                <Item v-for="(child,index) in item.category" :key="index" :item="child" />
            </b-collapse>
        </div>
    </div>
</template>
<script>
export default {
    name: "Item",
    props: {
        item: {
            type: Object
        },
        index: {
            type: Number
        }
    },
    data() {
        return {
            disabled_status: false
        }
    },
    mounted() {},
    methods: {

    }


}
</script>
<style scoped>
.rubricator_item {
    margin-bottom: 15px;
    background: #eee;
}

.rubricator_button_box {
    display: flex;
    align-items: center;
}

.rubricator_button_box button {
    padding: 0 20px;
    margin-right: 10px;
}

.rubricator_button_box button:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.rubricator_button_box button:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.label {
    background: #fff;
    padding: 10px 15px;
    border-radius: 12px;
    margin-bottom: 15px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tags_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.tags_item {
    background: #6c757d;
    color: #fff;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 12px;
    display: block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.description_item {
    background: #6c757d;
    color: #fff;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 14px;
    display: block;
    margin-bottom: 20px;

}

.table_button_box input {
    margin-right: 20px;
}

.switch {
    margin-right: 10px;
}

.list_item_title_box {
    display: flex;
    align-items: center;
}

.list_item_title_box span {
    white-space: nowrap;

}

.parent_category {
    padding: 3px 5px;
    border-radius: 4px;
    color: rgba(var(--main-text-color), 1.0);
    margin-right: 10px;
    transition: .2s;
}

.parent_category:hover {
    cursor: pointer;
    background: rgba(var(--main-color-transition), 1.0) !important;
    transition: .2s;
}

.sort_box {
    border: 1px dashed rgba(var(--main-color), 1.0);
    border-radius: 80px;
    width: 85px;
    display: block;
    position: relative;
    margin-right: 10px;
}

.sort_input {
    border: none;
    outline: none;
    border-radius: 80px;
    padding: 0 10px;
    width: 100%;
}

.icon_checkmark {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: rgba(var(--main-success-color), 1);
    display: block;
    transition: .2s;
    mask-image: url('/image/icons/default/checkmark.svg');
}

.icon_checkmark:hover {
    background-color: rgba(var(--main-color-transition), 1.0);
    transition: .2s;
    cursor: pointer;
}

.parent_category_title {
    padding-right: 20px;
}

.open_button {
    background-color: rgba(var(--main-success-color), 1);
    border-radius: 6px;
    font-size: 14px;
    padding: 0px 10px;
    color: #fff;
    margin-right: 10px;
}

.open_button:hover {
    background-color: rgba(var(--main-color-transition), 1.0);
    cursor: pointer;
}

.btn_keywords {
    background: #eee;
}
</style>