<template>
    <div>
        <div v-if="preloader">
            <form @submit.stop.prevent @submit="send">
                <b-row class="justify-content-between">
                    <b-col xl="6">
                        <div class="default_box default_form">
                            <div class="group">
                                <input v-model="form.title" type="text" placeholder=" " required>
                                <span class="bar" />
                                <label>Название</label>
                            </div>
                            <div class="group form_inline">
                                <span class="inline_label">Раздел</span>
                                <select id="" v-model="form.category_id" name="" required>
                                    <option v-for="cat in category" :key="cat.id" :value="cat.id" v-html="cat.name" />
                                </select>
                            </div>
                            <div class="group form_inline">
                                <span class="inline_label">Опубликовано</span>
                                <input id="s2" v-model="form.published" type="checkbox" class="switch">
                            </div>
                            <button v-if="$checkUser('filters', 'create')" class="btn_primary" type="submit">
                                Создать фильтр
                            </button>
                        </div>
                    </b-col>
                    <b-col xl="12">
                        <Filemanager ref="Filemanager" :images.sync="images" leng="1" />
                    </b-col>
                </b-row>
            </form>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import Filemanager from "@/components/App/Filemanager/Filemanager";
export default {
    name: "FiltersAdd",
    components: {
        Filemanager
    },
    data() {
        return {
            preloader: true,
            images: [],
            category: {
                0: {
                    name: "Акции",
                    id: 0
                },
                1: {
                    name: "Афиша",
                    id: 1
                }
            },
            form: {
                parent_id: 0,
                title: null,
                published: true,
                image: null,
                category_id: 1
            }
        }
    },
    mounted() {

    },
    methods: {
        send() {
            if (this.images && this.images.length) {
                this.form.image = this.$config.api_filemanager + this.images[0];
            } else {
                this.form.image = null;
            }

            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/filters";
            this.$http.post(url, this.form).then(() => {
                this.$toast.default("Фильтр успешно создан");
                this.$router.push({ name: "FiltersList" });
            }).finally(() => {
                this.preloader = true;
            });

        },
    }
}
</script>
<style scoped>
</style>