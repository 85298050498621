<template>
    <div v-cloak v-if="$checkUser('filemanager', 'create')" id="image_drop_box" class="image_drop_box" @drop.prevent="addFile" @dragover.prevent @dragleave="overClose" @drop="overClose">
        <h2>Перетащити картинки для загрузки</h2>
        <div class="icon_drag_and_drop" />
    </div>
</template>
<script>
export default {
    name: "AddFile",
    components: {},
    props: {
        celat: Boolean
    },
    data() {
        return {}
    },
    methods: {
        async addFile(e) {
            let droppedFiles;
            if (e.dataTransfer && e.dataTransfer.files) {
                droppedFiles = e.dataTransfer.files;
            } else if (e.target && e.target.files) {
                droppedFiles = e.target.files;
            }

            if (!droppedFiles) { return; }

            this.$parent.$data.uploadProgressMax = [...droppedFiles].length;

            let array = await this.sliceIntoChunks([...droppedFiles], 2);

            await this.$asyncForEach(array, async (item) => {

                await this.upload_image(item);

            });

            this.$parent.$data.uploadProgressMax = 0;
            this.$parent.$data.uploadPercentage = 0;
        },
        async upload_image(array) {

            let url = this.$config.api_management + this.$city.index + "/media";
            let formData = new FormData();
            let count = 0;
            formData.set("tag", this.$parent.$data.directory_name.tag);
            formData.set("path", this.$parent.$data.directory_name.path);
            formData.set("max_width", 1080);

            for (var i = 0; i < array.length && i < 5; i++) {
                let file = array[i];

                file = this.renameFile(file);
                console.log(file);
                
                if (file.size < 5 * 1000 * 1000) {
                    formData.set("files[" + count + "]", file);
                    count++;
                } else {
                    this.$toast.error("Размер файла не должен превышать 5 мб");
                }
            }

            this.uploadProgress = null;
            await this.$http.post(url, formData).then((response) => {
                if (response.status === 200) {
                    this.$toast.default("Фотографии успешно загружены");
                    this.$parent.api();
                }
            }).finally(() => {
                this.$parent.$data.uploadPercentage += array.length;
            })
        },
        renameFile(file) {
            return file = new File([file], this.$generateId(), {
                type: file.type,
            });
        },
        sliceIntoChunks(arr, chunkSize) {
            let res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
                let chunk = arr.slice(i, i + chunkSize);
                res.push(chunk);
            }
            return res;
        },
        overClose() {
            document.getElementById("image_drop_box").style.display = "none";
        }
    },
}
</script>
<style scoped>
[v-cloak] {
    display: none;
}

#image_drop_box {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, .8);
    border: 4px dashed #fff;
    border-radius: 12px;
    color: #fff;
}

.icon_drag_and_drop {
    margin-top: 30px;
    width: 100px;
    height: 100px;
    background-color: #fff;
    display: block;
    transition: .2s;
    mask-image: url('/image/icons/default/drag.svg');
    animation: 1.5s linear infinite alternate rot;
}

@keyframes rot {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-15deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
</style>