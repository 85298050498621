<template>
  <div>
    <button
      v-b-modal="`modal_map_${index}`"
      type="button"
      title="Выбрать координаты на карте"
      class="btn_map"
    >
      <span class="map_icon" />
    </button>
    <b-modal
      :id="`modal_map_${index}`"
      title="Выбрать координаты на карте"
      size="xl"
      class="default_form"
      @shown="modalShown"
      @hidden="$emit('clear')"
    >
      <div
        class="default_form"
        style="margin-bottom: 20px; display: none;"
      >
        <div class="group">
          <input
            id="searchInput"
            v-model="searchAddress"
            type="text"
            placeholder="Введите адрес"
            list="stationList"
            style="width: 100%;"
            @keyup="timer_method"
          >
          <dataList
            id="stationList"
            style="width: 100%;"
          >
            <option
              v-for="(item, index) in $parent.$parent.$data.prompt"
              :key="index"
              :value="item.value"
            />
          </dataList>
        </div>
      </div>
      <div id="mapContainer">
        <l-map
          ref="mymap"
          :zoom="zoom"
          :center="center"
          @click="addMarker"
        >
          <l-tile-layer
            :url="url"
            :attribution="attribution"
          />
          <l-marker
            v-if="markerLatLng"
            :lat-lng="markerLatLng"
          />
        </l-map>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Закрыть
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { Icon, latLng } from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
    name: "Map",
    components: {
        LMap,
        LTileLayer,
        LMarker
    },
    props: {
        index: {
            type: Number
        },
        x: {
            type: [Number, String]
        },
        y: {
            type: [Number, String]
        }
    },
    data() {
        return {
            zoom: 13,
            center: latLng(47.41322, -1.219482),
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution: "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors",
            markerLatLng: null,
            searchAddress: null,
            timer: 0
        };
    },
    mounted() {
        if (this.x && this.y) {
            this.center = [this.x, this.y]
            this.markerLatLng = [this.x, this.y];
            this.zoom = 16;
        } else {
            this.center = [this.$city.x, this.$city.y];
        }
        this.searchAddress = this.$city && this.$city.name ? this.$city.name + ", " : "";
    },
    methods: {
        addMarker(event) {
            this.markerLatLng = latLng(event.latlng);
            this.center = latLng(event.latlng);

            this.$parent.$parent.$data.form.addresses[this.index].latitude = event.latlng.lat.toFixed(6);
            this.$parent.$parent.$data.form.addresses[this.index].longitude = event.latlng.lng.toFixed(6);
        },
        addMarkerAddress(event) {
            console.log(event);
            // let latlng = {
            //     lat: item.data.geo_lat,
            //     lng: item.data.geo_lon
            // }
            // console.log(item);
            // console.log(latlng);
            // this.markerLatLng = latLng(latlng);
            // this.center = latLng(latlng);

            // this.$parent.$parent.$data.form.addresses[this.index].latitude = latlng.lat.toFixed(6);
            // this.$parent.$parent.$data.form.addresses[this.index].longitude = latlng.lng.toFixed(6);
        },
        modalShown() {
            setTimeout(() => {
                this.$refs.mymap.mapObject.invalidateSize();
            }, 100);
        },
        timer_method() {
            if (this.searchAddress && this.searchAddress.length >= 5) {
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.$parent.$parent.checkAddress(this.searchAddress);
                }, 1500);

            } else {
                return
            }
        },
    },
}
</script>
<style scoped>
#mapContainer {
    position: relative;
    cursor: default;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.map_icon {
    mask-image: url('/image/icons/organisations/map.svg');
    width: 30px;
    height: 30px;
    background-color: #999;
    display: block;
    transition: .2s;
    margin-right: 15px;
}

.map_icon:hover {
    transition: .2s;
    background-color: rgba(var(--main-color), 1.0);
}

.btn_map {
    margin-top: -7px;
}

dataList {
    width: 100%;
    background: #eee;
}


</style>