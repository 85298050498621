<template>
    <div>
        <div v-if="preloader">
            <div v-if="category" class="default_box org_title">
                    <span>{{ category.name }}</span>                   
            </div>
            <div v-if="list" class="default_box table-responsive">
                <table class="table table-striped" :class="disabled_status ? 'disable__window' : ''">
                    <thead>
                        <tr>
                            <th scope="col" class="text-start ">
                                #
                            </th>
                            <th scope="col" class="text-start ">ID
                            </th>
                            <th scope="col" class="text-start ">Название
                            </th>
                            <th scope="col" class="text-end ">Сортировка
                            </th>
                            <th v-if="$checkUser('organisation', 'edit')" scope="col" class="text-end">Действия
                            </th>
                        </tr>
                    </thead>
                    <draggable v-model="list" tag="tbody" handle=".sort_icon" @change="updateListSortOrder" :disabled="disabled_status">
                        <sortItem v-for="(item,index) in list" :key="item.id" :item="item" :index="index" />
                    </draggable>
                </table>
            </div>
            <div v-else class="default_box">
                В данной категории ещё нет организаций
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import sortItem from './components/sortItem';
export default {
    name: "OrganisationSortList",
    components: {
        sortItem,
        draggable
    },
    data() {
        return {
            preloader: false,
            disabled_status: false,
            category: null,
            list: null
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.api(to.params.id);
        next();
    },
    mounted() {
        this.api(this.$route.params.id);
    },
    methods: {
        api(id) {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + '/OrganizationsCategory/' + id;
            this.$http.get(url, {
                params: {
                    'with[]': 'organizations'
                }
            }).then((response) => {
                this.category = response.data;
                this.list = response.data.organizations;
            }).catch(() => {
                this.$router.push({name: 'OrganisationCategoryList'});
                this.$toast.error("Ошибка! Категория не найдена!");
            }).finally(() => {
                this.preloader = true;
            });
            console.log(this.$route);
        },
        sortList(index, item) {

            if (item.pivot && item.pivot.sort - 1 >= this.list.length) {
                var k = item.pivot.sort - 1 - this.list.length + 1;
                while (k--) {
                    this.list.push(undefined);
                }
            }
            this.list.splice(item.pivot.sort - 1, 0, this.list.splice(index, 1)[0]);


            let newList = [...this.list].map((item, index) => {
                let newSort = index + 1;

                item.hasChanged = item.pivot.sort !== newSort;
                if (item.hasChanged) {
                    item.pivot.sort = newSort;
                }
                return item;
            });
            
            this.sortCat(newList);
            this.list = newList;
        },
        updateListSortOrder({ moved }) {
            let newList = [...this.list].map((item, index) => {
                let newSort = index + 1;

                item.hasChanged = item.pivot.sort !== newSort;
                if (item.hasChanged) {
                    item.pivot.sort = newSort;
                }
                return item;
            });

            this.sortCat(newList);
            this.list = newList;
        },
        sortCat(array) {
            this.disabled_status = true;
            let data = [...this.list].map((item) => {
                return {
                    org_id: item.id,
                    sort: item.pivot.sort
                }
            })
            let url = this.$config.api_management + this.$city.index + "/OrganizationsCategory/" + this.$route.params.id + "/sort";
            this.$http.patch(url, data).then(() => {
                this.$toast.default("Позиция успешно изменена");
            }).finally(() => {
                this.disabled_status = false;
            })
        },
    }
}
</script>
<style scoped>
table {
    transition: .3s;
}

.disable__window {
    opacity: .3;
    transition: .3s;
}

.org_title {
}

.org_title span {
    font-size: 24px;
    text-transform: uppercase;
    padding: 5px 25px;
    border-radius: 12px;
    display: inline-block;
}

@media (max-width: 767px) {
    .org_title span {
        font-size: 14px;
    }
}
</style>