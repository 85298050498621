<template>
  <div>
    <div v-if="preloader">
      <div
        v-if="$parent.$data.category_list"
        id="category_box"
        class="category_box"
      >
        <treeselect
          v-model="$parent.$data.form.parent_id"
          :options="$parent.$data.category_list"
          :always-open="false"
          :clearable="true"
          :no-children-text="``"
          :no-results-text="`Результатов не найдено`"
          :show-count="true"
          :open-direction="`bottom`"
          :placeholder="`Поиск по категориям`"
          :normalizer="normalizer"
        />
      </div>
    </div>
    <Preloader v-else />
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
export default {
    name: "CategoryParent",
    components: {
        Treeselect
    },
    data() {
        return {
            preloader: false,
        }
    },
    mounted() {
        this.category_api();
    },
    methods: {
        normalizer(node) {
            return {
                children: node.children && node.children.length ? node.children : 0
            }
        },
        category_api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/OrganizationsCategory/all";
            this.$http.get(url).then((response) => {
                this.$parent.$data.category_list = this.getUnflatten(response.data).sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }

                    if (a.name > b.name) {
                        return 1;
                    }

                    return 0;
                });
            }).finally(() => {
                this.preloader = true;
            });
        },
        getUnflatten(items, id = null) {
            return items
                .filter(item =>
                    (item.parent_id === 0 ? item.parent_id = null : item.parent_id) === id
                )
                .map(item => ({ ...item, children: this.getUnflatten(items, item.id), label: item.name }));
        },
    }
}
</script>

<style scoped>
</style>
