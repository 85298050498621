<template>
    <div>
        <div v-if="preloader">
            <div class="header_button_box">
                <div v-if="$checkUser('poster', 'create')" class="header_button_item">
                    <router-link :to="{name: 'PosterAdd'}">
                        <button class="header_button">
                            <div class="header_icons icon_add" /><span>Создать афишу</span>
                        </button>
                    </router-link>
                </div>
            </div>
            <SearchPlagin :search-type="`Poster`" />
            <div v-if="list && list.length" class="default_box table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" class="text-start">
                                ID
                            </th>
                            <th scope="col" class="text-start">
                                Заголовок
                            </th>
                            <th scope="col" class="text-start">
                                Фильтр
                            </th>
                            <th v-if="$checkUser('poster', 'edit')" scope="col" class="text-end">
                                Опубликовано
                            </th>
                            <th v-if="$checkUser('poster', 'edit') || $checkUser('poster', 'delete')" scope="col" class="text-end">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in list" :key="index">
                            <th scope="row" class="text-start">
                                <a :href="'https://okaygorod.com/' + $city.index + '/poster/'+item.id" target="_blank" :title="'Открыть ссылку https://okaygorod.com/' + $city.index + '/poster/'+item.id" class="link" v-html="item.id" />
                            </th>
                            <td class="text-start" v-html="$cutText(item.name, 80)" />
                            <td class="text-start" v-html="item.category ? item.category.title : ''" />
                            <td v-if="$checkUser('poster', 'edit')" class="text-end">
                                <input id="s2" type="checkbox" class="switch" :checked="item.published" :disabled="disabled_status" @change="api_status(item, index)">
                            </td>
                            <td v-if="$checkUser('poster', 'edit') || $checkUser('poster', 'delete')" class="text-end">
                                <div class="table_button_box">
                                    <router-link v-if="$checkUser('poster', 'edit')" :to="{name: 'PosterEdit', params: {id: item.id}}"  class="table_button_item" :title="`Редактировать афишу`">
                                        <button class="table_button table_icon_edit">
                                            <div class="table_icons icon_pen" />
                                        </button>
                                    </router-link>
                                    <div v-if="$checkUser('poster', 'delete')" class="table_button_item" :title="`Удалить афишу`">
                                        <button class="table_button table_icon_delete" @click="del(item.id, item.name, index)">
                                            <div class="table_icons icon_trash" />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="list && last_page && page < last_page">
                    <div v-if="button_preloader" class="news_button_block">
                        <button class="btn_primary" @click="api_next">
                            Показать ещё
                        </button>
                    </div>
                    <Preloader v-else />
                </div>
            </div>
            <div v-else class="default_box">
                В этом городе нет афиш
            </div>
        </div>
        <Preloader v-else />
    </div>
</template>
<script>
import SearchPlagin from "@/components/components/Search/SearchPlagin";
export default {
    name: "PosterList",
    components: {
        SearchPlagin
    },
    data() {
        return {
            disabled_status: false,
            button_preloader: true,
            preloader: false,
            list: null,
            page: 1,
            last_page: null,
        }
    },
    mounted() {
        this.api();
    },
    methods: {
        api() {
            this.preloader = false;
            let url = this.$config.api_management + this.$city.index + "/poster";
            this.$http.get(url, {
                params: {
                    per_page: 50,
                }
            }).then((response) => {
                this.list = response.data.data;
                this.last_page = response.data.last_page;
            }).finally(() => {
                this.preloader = true;
            });
        },
        api_next() {
            this.button_preloader = false;
            let url = this.$config.api_management + this.$city.index + "/poster";
            this.page++;
            this.$http.get(url, {
                params: {
                    page: this.page,
                    per_page: 50,
                }
            }).then((response) => {
                this.list = this.list.concat(response.data.data);
            }).finally(() => {
                this.button_preloader = true;
            })
        },
        api_status(array, index) {
            array.published = !array.published;
            let data = {
                published: array.published
            }
            this.disabled_status = true;
            let url = this.$config.api_management + this.$city.index + "/poster/" + array.id;
            this.$http.put(url, data).then((response) => {
                this.list[index].published = response.data.published;
                this.$toast.default("Статус афиши успешно изменён");
            }).finally(() => {
                this.disabled_status = false;
            })
        },
        del(id, name, index) {
            if (confirm("Вы уверены, что хотите удалить эту афишу?\n\n\"" + name + "\"\n\nЭто действие нельзя отменить!")) {
                let url = this.$config.api_management + this.$city.index + "/poster/" + id;
                this.$http.delete(url).then((response) => {
                    if (response && response.status === 204) {
                        this.list.splice(index, 1);
                        this.$toast.default("Афиша \"" + name + "\", успешно удалена");
                    } else {
                        this.$toast.error("Произошла ошибка при удалении");
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.btn_primary {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 15px;
}
</style>